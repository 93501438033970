import { ZodError } from 'zod';
import { formatZodError } from '../format-zoderror';

/**
 * Format errors
 *
 * This is used to reduce boilerplate in error handlers
 */
export function resolveError(error: unknown) {
  if (error instanceof ZodError) {
    return formatZodError(error);
  } else if (error instanceof Error) {
    return error;
  }

  return new Error(`Unknown error. Got ${error}`);
}
