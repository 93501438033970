import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import { COMPLEXITY_ORDER } from 'documents/concussion-complexity';
import React, { useMemo } from 'react';
import { capitaliseFirstLetter } from 'utils/formatters/normalise';

const COLOR_INACTIVE = '#767679';
const COLOR_ACTIVE = '#230056';

const BLOCKS = [
  <path
    key="1"
    d="M134.5 60L134.5 10C134.5 4.47715 138.977 -2.32607e-07 144.5 -5.19542e-07C150.023 -8.06477e-07 154.5 4.47715 154.5 10L154.5 60L134.5 60Z"
    fill="#D72721"
  />,
  <rect
    key="2"
    x="134.5"
    y="124"
    width="60"
    height="20"
    transform="rotate(-90 134.5 124)"
    fill="#E05D18"
  />,
  <rect
    key="3"
    x="134.5"
    y="252"
    width="60"
    height="20"
    transform="rotate(-90 134.5 252)"
    fill="#EFB708"
  />,
  <rect
    key="4"
    x="134.5"
    y="188"
    width="60"
    height="20"
    transform="rotate(-90 134.5 188)"
    fill="#E67C12"
  />,
  <path
    key="5"
    d="M144.5 316C138.977 316 134.5 311.523 134.5 306L134.5 256L154.5 256L154.5 306C154.5 311.523 150.023 316 144.5 316Z"
    fill="#F1F124"
  />
];

/**
 * A diagram to show the concussion complexity degree
 * @param props
 * @returns
 */
export function ConcussionComplexityDiagram(props: {
  value: number;
  min: number;
  max: number;
}) {
  const percentage = (props.max - props.value) / (props.max - props.min);
  const yOffset = percentage * 316;
  const categoryIndex = Math.floor(percentage * 5);
  const titles = [...COMPLEXITY_ORDER]
    .reverse()
    .map(title => capitaliseFirstLetter(title + 'ly'));
  return (
    <svg
      width="400"
      height="316"
      viewBox="0 0 400 316"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="concussion-complexity-diagram"
      // overflow={'visible'}
    >
      <g transform="translate(-20, 0)">
        <g>
          {titles.map((categoryTitle, index) => {
            return (
              <React.Fragment key={`category-${index}`}>
                {BLOCKS[index]}
                <ScaleHeader
                  x={168 + 20}
                  y={35 + 64 * index}
                  selected={categoryIndex === index}
                >
                  {categoryTitle} Complex
                </ScaleHeader>
              </React.Fragment>
            );
          })}
        </g>
        <g transform={`translate(6, ${yOffset})`}>
          <path d="m 129 -6.5 l 47 0" stroke="#242425" strokeWidth="3" />
        </g>
      </g>
    </svg>
  );
}

export function ConcussionComplexityDiagramWrapper(
  props: Pick<PatientInfoState, 'concussion'>
) {
  const complexityIndex = useMemo(
    () => COMPLEXITY_ORDER.indexOf(props.concussion.subtype),
    [props.concussion.subtype]
  );
  return (
    <ConcussionComplexityDiagram
      value={complexityIndex + 1 / 2}
      min={0}
      max={5}
    />
  );
}

function ScaleHeader({
  selected,
  ...props
}: React.SVGTextElementAttributes<SVGTextElement> & { selected?: boolean }) {
  return (
    <text
      fill={selected ? COLOR_ACTIVE : COLOR_INACTIVE}
      {...props}
      style={{
        fontSize: `${selected ? 20 / 16 : 16 / 16}rem`,
        fontWeight: selected ? 'bold' : 'normal',
        ...props.style
      }}
    />
  );
}
