import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import authReducer from 'features/auth/authSlice';
import uiPrefReducer from 'features/navigation/navigationSlice';
import { FAQModalReducer } from 'features/navigation/navigationSlice';
import { RxProgressReducer } from 'features/navigation/rx-progress-slice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

export interface ConnectRouterState {
  lastAction?: 'resetPassword';
}

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    uiPref: uiPrefReducer,
    rxProgressBarPref: RxProgressReducer,
    faqModalPref: FAQModalReducer
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default createRootReducer;
