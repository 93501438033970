import { path } from 'd3';
import { uniqueId } from 'lodash';
import React, { useMemo } from 'react';
import { formatNumber } from 'utils/formatters/normalise';
import { arcCommand } from 'utils/math/d3-helpers';
import { calculateSegmentIndex } from './calculateSegmentIndex';
import { calculateValueAngle } from './calculateValueAngle';
import { Gauge2Props } from './gauge-2';
import { restrictValueToScale } from './restrictValueToScale';

export function Gauge3(
  props: Gauge2Props & {
    width?: number;
    height?: number;
    showLabels: boolean;
    showValue: boolean;
  }
) {
  /**
   * Error check if the component has invalid data
   */
  if (typeof props.value !== 'number')
    throw new Error(
      `Gauge value must be a number. Got '${JSON.stringify(
        props.value
      )}' (type ${typeof props.value})`
    );

  const width = props.width ?? 200;
  const height = props.height ?? 188;
  const startAngles = [1, 83, 167];
  const endAngles = [76, 158, 241.5];
  const segmentIndex = calculateSegmentIndex(props.scale, props.value);

  // Calculate the rotation of the needle
  const rotation = calculateValueAngle(
    restrictValueToScale(props.value, props.scale),
    props.scale[segmentIndex],
    props.scale[segmentIndex + 1],
    startAngles[segmentIndex],
    endAngles[segmentIndex]
  );

  // A unique ID of the diagram
  const instanceId = useMemo(() => uniqueId('gauge3_key_'), []);

  return (
    <svg
      width={width}
      height={height}
      // Add extra padding to the viewbox for a fast fix for scale numbers being cut off
      viewBox={`0 0 ${width * 1.1} ${height}`}
      fill="none"
      version="1.1"
      id="svg15"
      xmlns="http://www.w3.org/2000/svg"
      style={{ overflow: 'visible' }}
    >
      <g transform="translate(-30, 0)">
        <defs id="defs15">
          <linearGradient id="linearGradient13">
            <stop
              style={{ stopColor: '#e88b10', stopOpacity: 1 }}
              offset="0"
              id="stop17"
            />
            <stop
              style={{ stopColor: '#e13c18', stopOpacity: 1 }}
              offset="1"
              id="stop16"
            />
          </linearGradient>
          <linearGradient
            id="paint0_linear_3276_1009"
            x1="194.27699"
            y1="26.984301"
            x2="82.993797"
            y2="42.8517"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F6B816" id="stop12" />
            <stop offset="1" stopColor="#F0E818" id="stop13" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3276_1009"
            x1="69.441399"
            y1="145.40401"
            x2="83.189697"
            y2="49.165501"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00B230" id="stop14" />
            <stop offset="1" stopColor="#CEDB3B" id="stop15" />
          </linearGradient>
          <linearGradient
            href="#linearGradient13"
            id="linearGradient17"
            x1="195.12355"
            y1="50.304199"
            x2="207.61935"
            y2="146.34145"
            gradientUnits="userSpaceOnUse"
            spreadMethod="pad"
          />
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath32">
            <path
              style={{
                fill: '#e4e6eb',
                fillOpacity: 1,
                strokeWidth: 14.3023,
                strokeLinecap: 'round'
              }}
              id="path33"
              d="m 33.311736,-266.95292 a 95.602707,95.602707 0 0 1 79.405224,50.57863 95.602707,95.602707 0 0 1 -2.44803,94.11372 l -81.958614,-49.22055 z"
              transform="rotate(118.25259)"
            />
          </clipPath>
          <SegmentClips rotation={rotation} instanceId={instanceId} />

          <linearGradient
            id="linear_gradient_green_yellow"
            x1="-60"
            y1="0"
            x2="50"
            y2="-40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#01B230" />
            <stop offset="0.35" stopColor="#F0E818" />
          </linearGradient>

          <linearGradient
            id="linear_gradient_yellow_orange_red"
            x1="-30"
            y1="0"
            x2="30"
            y2="50"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F0E818" />
            <stop offset="0.5" stopColor="#F99834" />
            <stop offset="1" stopColor="#D9322C" />
          </linearGradient>
        </defs>
        <g id="g1" style={{ display: 'inline' }}>
          <path
            d="m 201.695,42.8087 c 1.193,-1.1931 3.132,-1.1962 4.285,0.0354 13.28,14.1812 21.811,32.1841 24.354,51.497 2.542,19.3129 -1.038,38.9099 -10.196,56.0459 -0.795,1.488 -2.668,1.986 -4.13,1.143 l -18.172,-10.492 c -1.461,-0.844 -1.955,-2.709 -1.18,-4.208 6.187,-11.961 8.579,-25.553 6.815,-38.9524 -1.764,-13.3992 -7.593,-25.909 -16.664,-35.8612 -1.137,-1.247 -1.143,-3.1769 0.05,-4.37 z"
            fill="#e4e6eb"
            id="path1"
            style={{ display: 'inline' }}
          />
          <path
            d="M 83.3635,34.6225 C 82.322,33.2949 82.5513,31.3699 83.9123,30.3725 99.5832,18.8881 118.479,12.577 137.957,12.368 c 19.479,-0.209 38.506,5.695 54.419,16.8404 1.383,0.968 1.653,2.8877 0.64,4.2373 l -12.594,16.7835 c -1.013,1.3496 -2.924,1.6162 -4.319,0.667 -11.133,-7.5759 -24.341,-11.5808 -37.855,-11.4357 -13.514,0.145 -26.632,4.4324 -37.6,12.2455 -1.3744,0.9789 -3.2911,0.7534 -4.3326,-0.5741 z"
            fill="#e4e6eb"
            id="path2"
            style={{ display: 'inline' }}
          />
          <path
            d="m 60.3247,150.053 c -1.4464,0.813 -3.2818,0.324 -4.0816,-1.129 C 46.8797,131.911 43.2165,112.447 45.8126,93.2652 48.4101,74.0732 57.1355,56.1854 70.7191,42.106 c 1.1487,-1.1906 3.0418,-1.1874 4.2277,-0.0338 l 15.157,14.7436 c 1.2332,1.1996 1.2275,3.1761 0.0524,4.4327 -9.2245,9.8646 -15.1521,22.2552 -16.9481,35.525 -1.7956,13.2665 0.6316,26.7235 6.9137,38.5725 0.8038,1.516 0.2997,3.421 -1.1964,4.261 z"
            fill="#e4e6eb"
            id="path4"
            style={{ display: 'inline' }}
          />
        </g>
        <DialCenter rotation={rotation - 125} />

        {props.showLabels && (
          <>
            {[
              { x: 47.5531, y: 163, value: props.scale[0] },
              { x: 63.469, y: 34, value: props.scale[1] },
              { x: 205.388, y: 34, value: props.scale[2] },
              { x: 228.54, y: 163.024, value: props.scale[3] }
            ].map((s, index) => (
              <text
                key={`scaletext-${index}`}
                x={s.x}
                y={s.y}
                fill={'#767679'}
                fontSize={`${12 / 16}rem`}
              >
                {s.value}
              </text>
            ))}
          </>
        )}
        {props.showValue && (
          <text
            x={122.123}
            y={183.052}
            fill={'#767679'}
            fontSize={`${26 / 16}rem`}
            style={{ textAlign: 'center' }}
          >
            {formatNumber(props.value)}
          </text>
        )}

        <g id="filled-segments" style={{ display: 'inline' }}>
          <g
            id="g17"
            // clipPath="url(#clipPath32)"
          >
            <path
              d="m 60.3247,150.053 c -1.4464,0.813 -3.2818,0.324 -4.0816,-1.129 C 46.8797,131.911 43.2165,112.447 45.8126,93.2652 48.4101,74.0732 57.1355,56.1854 70.7191,42.106 c 1.1487,-1.1906 3.0418,-1.1874 4.2277,-0.0338 l 15.157,14.7436 c 1.2332,1.1996 1.2275,3.1761 0.0524,4.4327 -9.2245,9.8646 -15.1521,22.2552 -16.9481,35.525 -1.7956,13.2665 0.6316,26.7235 6.9137,38.5725 0.8038,1.516 0.2997,3.421 -1.1965,4.261 z"
              fill="url(#paint1_linear_3276_1009)"
              id="path5"
              style={{
                display: 'inline',
                fill: 'url(#paint1_linear_3276_1009)'
              }}
              clipPath={`url(#${instanceId}_segmentclippath_first)`}
            />

            <path
              d="M 83.3635,34.6225 C 82.322,33.2949 82.5513,31.3699 83.9123,30.3725 99.5832,18.8881 118.479,12.577 137.957,12.368 c 19.479,-0.209 38.506,5.695 54.419,16.8404 1.383,0.968 1.653,2.8877 0.64,4.2373 l -12.594,16.7835 c -1.013,1.3496 -2.924,1.6162 -4.319,0.667 -11.133,-7.5759 -24.341,-11.5808 -37.855,-11.4357 -13.514,0.145 -26.632,4.4324 -37.6,12.2455 -1.3744,0.9789 -3.2911,0.7534 -4.3326,-0.5741 z"
              fill="url(#paint0_linear_3276_1009)"
              id="path3"
              style={{ fill: 'url(#paint0_linear_3276_1009)' }}
              clipPath={`url(#${instanceId}_segmentclippath_second)`}
            />

            <path
              d="m 201.695,42.8087 c 1.193,-1.1931 3.132,-1.1962 4.285,0.0354 13.28,14.1812 21.811,32.1841 24.354,51.497 2.542,19.3129 -1.038,38.9099 -10.196,56.0459 -0.795,1.488 -2.668,1.986 -4.13,1.143 l -18.172,-10.492 c -1.461,-0.844 -1.955,-2.709 -1.18,-4.208 6.187,-11.961 8.579,-25.553 6.815,-38.9524 -1.764,-13.3992 -7.593,-25.909 -16.664,-35.8612 -1.137,-1.247 -1.143,-3.1769 0.05,-4.37 z"
              fill="#e4e6eb"
              id="path13"
              style={{ display: 'inline', fill: 'url(#linearGradient17)' }}
              clipPath={`url(#${instanceId}_segmentclippath_third)`}
            />
          </g>
        </g>
      </g>

      {/* <SegmentClips rotation={rotation} isDebugging instanceId={instanceId} /> */}
    </svg>
  );
}

function DialCenter(props: { rotation: number }) {
  const radius = (141.49 - 131.41) / 2 + 2.1;
  const centerX = 144.5 - 6.5;
  const centerY = 103;

  return (
    <>
      <g
        id="dialcenter"
        transform={`translate(${centerX}, ${centerY}) rotate(${
          props.rotation
        }) translate(${-centerX}, ${-centerY})`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m 143.341,54.7729 c 0.049,-0.2848 0.316,-0.479 0.605,-0.4403 0.289,0.0388 0.497,0.2967 0.471,0.5848 l -4.126,46.4296 c -0.125,1.406 -1.404,2.419 -2.813,2.23 -1.409,-0.189 -2.382,-1.505 -2.139,-2.895 z"
          fill="#767679"
          id="path6"
        />
        <circle
          r={radius}
          cx={centerX}
          cy={centerY}
          fill="#ffffff"
          stroke="#767679"
          strokeWidth="3"
        />
      </g>

      <g
        id="gauge-range-arc"
        transform={`translate(${centerX}, ${centerY - 2})`}
      >
        <InnerDial radius={55} strokeWidth={5} />
      </g>
    </>
  );
}

export function InnerDial(props: { radius: number; strokeWidth: number }) {
  const pathCommand1 = useMemo(() => {
    const p = path();
    arcCommand(p, props.radius, 180 - 35, -90);
    return p.toString();
  }, [props.radius]);

  const pathCommand2 = useMemo(() => {
    const p = path();
    arcCommand(p, props.radius, -91, 35);

    return p.toString();
  }, [props.radius]);
  return (
    <>
      <g transform="scale(0.98, 0.94) translate(0, 2)">
        <path
          d={pathCommand1}
          stroke="url(#linear_gradient_green_yellow)"
          strokeWidth={props.strokeWidth}
        />
        <path
          d={pathCommand2}
          stroke="url(#linear_gradient_yellow_orange_red)"
          strokeWidth={props.strokeWidth}
        />
      </g>
    </>
  );
}

export function SegmentClips(
  props: React.SVGProps<SVGGElement> & {
    rotation: number;
    isDebugging?: boolean;
    instanceId: string;
  }
) {
  const style: React.CSSProperties = {
    display: 'inline',
    fill: '#e4e6eb',
    fillOpacity: 0.5,
    strokeWidth: 14.0836,
    strokeLinecap: 'round'
  };

  const xOffset = 138;
  const yOffset = 105;

  const paths = [
    {
      transform: 'rotate(136.35615)',
      d: 'm -26.2305 -77.5194 a 94.141 94.141 0 0 1 -89.5183 -62.81 a 94.141 94.141 0 0 1 30.2707 -105.0824 a 94.141 94.141 0 0 1 109.214 -5.562 a 94.141 94.141 0 0 1 40.7895 101.4636 l -91.4988 -22.1472 z',
      key: 'first'
    },
    {
      transform: 'rotate(-30.309285)',
      d: 'm 159.8515 169.6345 a 94.141 94.141 0 0 1 -98.2708 84.7327 a 94.141 94.141 0 0 1 -89.5509 -93.9011 l 94.1409 -0.1279 z',
      key: 'second'
    },

    {
      transform: 'rotate(72.69194)',
      d: 'm 235.3955 -99.3708 a 94.141 94.141 0 0 1 -72.8007 90.8977 l -21.337 -91.6911 z',
      key: 'third'
    }
  ];

  return (
    <>
      {paths.map(_path => {
        const id = `${props.instanceId}_segmentclippath_${_path.key}`;
        const component = (
          <path
            {..._path}
            key={`${id}-development`}
            style={style}
            transform={[
              `translate(${xOffset}, ${yOffset})`,
              `rotate(${props.rotation})`,
              `translate(${-xOffset}, ${-yOffset})`,
              _path.transform
            ]
              .join(' ')
              .trim()}
          />
        );

        /**
         * Don't wrap the component in a clipPath
         * This is used to visualise the clip during development
         */
        if (props.isDebugging) {
          return component;
        }

        return (
          <clipPath key={id} id={id}>
            {component}
          </clipPath>
        );
      })}
    </>
  );
}
