import { PatientRoutes, Routes } from 'routes';

export type Filter = string | ((url: string) => boolean);

export const omitSideMenuRoutes = new Set<string>([
  Routes.PrivacyPolicy,
  Routes.TermsOfService,
  Routes.VerifyEmail,
  Routes.AssessmentIntro,
  Routes.Questionnaire,
  Routes.QuestionnaireFlow
]);

export const omitSideMenuRoutesPatient = [
  PatientRoutes.PatientOnboardingProfile,
  PatientRoutes.PatientHowAreYouTracker,
  PatientRoutes.PatientPromis,
  PatientRoutes.Completion,
  PatientRoutes.CompletionPromis,
  PatientRoutes.PatientPcs,
  PatientRoutes.PatientDhi,
  PatientRoutes.PatientCgs,
  PatientRoutes.PatientOutcomeProgress,
  PatientRoutes.PatientOutcomeMeasures,
  PatientRoutes.ImpactAPI
];

export const siteProgressBarRoutes: Filter[] = [
  (url: string) => url.includes(PatientRoutes.PatientPromis),
  (url: string) => url.includes(PatientRoutes.PatientPcs),
  (url: string) => url.includes(PatientRoutes.PatientDhi),
  (url: string) => url.includes(PatientRoutes.PatientCgs),
  Routes.QuestionnaireFlow
];

export const routesWithWhiteBg: Filter[] = [
  Routes.AssessmentIntro,
  Routes.Questionnaire,
  Routes.QuestionnaireFlow,
  (url: string) => url.includes(PatientRoutes.Completion),
  (url: string) => url.includes(PatientRoutes.PatientOutcomeProgress),
  (url: string) => url.includes(PatientRoutes.PatientPromis),
  (url: string) => url.includes(PatientRoutes.CompletionPromis),
  (url: string) => url.includes(PatientRoutes.PatientPcs),
  (url: string) => url.includes(PatientRoutes.PatientDhi),
  (url: string) => url.includes(PatientRoutes.PatientCgs),
  (url: string) => url.includes(PatientRoutes.PatientOutcomeProgress),
  (url: string) => url.includes(PatientRoutes.PatientOutcomeMeasures),
  (url: string) => url.includes(PatientRoutes.ImpactAPI)
];

export const applyFilter = (url: string, filter: Filter[]) =>
  filter.findIndex(fn =>
    typeof fn === 'function' ? fn(url) : url.includes(fn)
  ) !== -1;
