import {
  Ethnicity,
  Gender,
  Occupation,
  OccupationType,
  StudentLevel,
  EnglishFluency
} from 'documents';

export const genderOptions = [
  { label: 'Male', value: Gender.Male },
  { label: 'Female', value: Gender.Female },
  { label: 'Other', value: Gender.Other }
];

export const concussionDiagnosisOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const ethnicityOptions = [
  { label: 'American Indian or Alaska Native', value: Ethnicity.Indian },
  { label: 'Asian', value: Ethnicity.Asian },
  { label: 'Black or African American', value: Ethnicity.Black },
  { label: 'Hispanic or Latino', value: Ethnicity.Latino },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: Ethnicity.Islander
  },
  { label: 'White', value: Ethnicity.White },
  { label: 'Did not specify', value: Ethnicity.None }
];

export const englishFluencyOptions = [
  {
    label: 'Native or Bilingual Proficency',
    value: EnglishFluency.NativeBilingual
  },
  { label: 'Intermediate Proficency', value: EnglishFluency.Intermediate },
  { label: 'Novice', value: EnglishFluency.Novice }
];

export const occupationOptions = [
  { label: 'Student', value: Occupation.Student },
  { label: 'Working', value: Occupation.Working },
  { label: 'Unemployed', value: Occupation.Unemployed },
  { label: 'Disability', value: Occupation.Disability },
  { label: 'Retired', value: Occupation.Retired }
];

export const studentLevelOptions = [
  { label: 'Elementary', value: StudentLevel.Elementary },
  { label: 'HighSchool', value: StudentLevel.HighSchool },
  { label: 'University', value: StudentLevel.University },
  { label: 'Undergrad', value: StudentLevel.Undergrad },
  { label: 'University Graduate', value: StudentLevel.UniversityGraduate }
];

export const occupationTypeOptions = [
  { label: 'Full Time', value: OccupationType.FullTime },
  { label: 'Part Time', value: OccupationType.PartTime }
];
