import {
  PagedQuestionnaire,
  QUESTIONNAIRE_TITLE_24_HR
} from 'features/patient/assessment/paged-questionnaire';
import {
  DhiSliceReducer,
  initialState,
  submit,
  updateDhiItem,
  validate
} from './dhiSlice';
import { formatDhi } from './PatientDhi';

export function PaginationCombined(props: { storageKey: string }) {
  return (
    <PagedQuestionnaire
      actions={{
        formatData: formatDhi,
        validate,
        updateItem: updateDhiItem,
        submit
      }}
      initialState={initialState}
      reducer={DhiSliceReducer}
      getData={state => state.dhi}
      storageKey={props.storageKey}
      pageTitle={QUESTIONNAIRE_TITLE_24_HR}
    />
  );
}
