import { ArrowRightOutlined } from '@ant-design/icons';
import { Card, Flex, Switch, Typography } from 'antd';
import { GREEN, ORANGE, RED, YELLOW } from 'components/gauge/Gauge';
import { last, map, prop, sortBy } from 'lodash/fp';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import { SymptomCardData, SymptomCardProps } from './SymptomCard';

/**
 * Styles that can only be done in CSS go in here
 */
import clsx from 'clsx';
import { Gauge3 } from 'components/gauge/gauge-3';
import { Text, Title } from 'components/mvp-typography';
import { BrainFunctionVisualisation } from 'features/patient/patient-summary/cards/brainfunction-categories';
import { ErrorBoundary } from 'react-error-boundary';
import { BrainFunctionBarChart } from './graph-brainfunction-barchart';
import './symptom-card-2.scss';
import {
  DynamicOrStaticProperty,
  resolveDynamicOrStaticProperty
} from 'utils/dynamic-or-static-property';

export const SymptomCard2Context = createContext({ showNumbers: false });
export const SymptomCard2ContextProvider = SymptomCard2Context.Provider;
export const SymptomCard2ContextConsumer = SymptomCard2Context.Consumer;

/**
 * An improved symptom card
 */
export function SymptomCard2(
  props: SymptomCardProps & {
    scale: number[];
    brainFunction: BrainFunctionVisualisation;
  }
) {
  const globalSymptomCard2Context = useContext(SymptomCard2Context);
  const [flipped, setFlipped] = useState(false);

  const [showNumbers, setShowNumbers] = useState(false);

  useEffect(() => {
    setShowNumbers(globalSymptomCard2Context.showNumbers);
  }, [globalSymptomCard2Context.showNumbers]);

  const sortedData = useMemo(
    () => sortBy(d => d.date, props.data),
    [props.data]
  );

  const colors = [GREEN, YELLOW, ORANGE, RED];

  return (
    <Card
      className={clsx('symptom-card-2', flipped ? 'flipped' : '')}
      onClick={event => {
        const eventTarget = event.target as HTMLElement | undefined;
        if (
          eventTarget?.className?.includes &&
          eventTarget.className.includes('ant-switch')
        )
          return;
        setFlipped(old => !old);
      }}
    >
      <SymptomCard2ContextProvider value={{ showNumbers: showNumbers }}>
        <Flex justify={'space-between'}>
          <div>
            {/* <Button onClick={() => setFlipped(old => !old)}>{flipped ? <ShrinkOutlined /> : <ArrowsAltOutlined />}</Button> */}
          </div>
          <ShowScoresToggle
            showNumbers={showNumbers}
            setShowNumbers={setShowNumbers}
          />
        </Flex>
        {!props.disabled && props.link && (
          <Link to={props.link}>
            <div>
              <div>
                Explore <ArrowRightOutlined />
              </div>
            </div>
          </Link>
        )}
        <Flex justify={'center'}>
          <Typography.Title level={3}>
            <b>{props.label}</b>
          </Typography.Title>
        </Flex>

        {/* Render the card graphs */}
        <ErrorBoundary
          fallbackRender={({ error }) => (
            <>
              <Title>Rendering error</Title>
              <Text>{error.message}</Text>
            </>
          )}
        >
          {flipped ? (
            <HistoricalStatus
              sortedData={sortedData}
              scale={props.scale}
              colors={colors}
            />
          ) : (
            <CurrentStatus
              scale={props.scale}
              colors={colors}
              sortedData={sortedData}
            />
          )}

          {!props.brainFunction.isRawScore && showNumbers && (
            <Text
              style={{ color: '#767679', paddingTop: '10px' }}
              className="crx-typography small-normal"
            >
              *The score presented is a percentile
            </Text>
          )}
        </ErrorBoundary>
      </SymptomCard2ContextProvider>
    </Card>
  );
}

function HistoricalStatus(props: {
  sortedData: SymptomCardData[];
  scale: number[];
  colors: string[];
}) {
  const ctx = useContext(SymptomCard2Context);
  return (
    <Flex justify={'center'}>
      <BrainFunctionBarChart
        maxAssessments={12}
        data={props.sortedData}
        scale={props.scale}
        colors={props.colors}
        width={350}
        barThickness={15}
        showLabels
        showValues={ctx.showNumbers}
      />
    </Flex>
  );
}

/**
 * The initial state of a symptom card
 *
 * Shows a gauge with the latest value and a bar chart with data from previous months
 */
function CurrentStatus(props: {
  scale: number[];
  colors: string[];
  sortedData: SymptomCardData[];
}) {
  const ctx = useContext(SymptomCard2Context);
  // Get the latest data
  const lastValue: number = useMemo(
    () =>
      // Get the last element
      last(
        // Get the `value` key
        map(
          prop('value'),
          // Sort the data in ascending order by date
          props.sortedData
        )
      ) ?? 0,
    [props.sortedData]
  );
  return (
    <div>
      <Flex justify={'space-around'} align={'center'} gap={'small'}>
        <Gauge3
          value={lastValue}
          scale={props.scale}
          colors={props.colors}
          size={200}
          radius={80}
          segmentThickness={20}
          showLabels={ctx.showNumbers}
          showValue={ctx.showNumbers}
        />

        {/*
         * Show aggregated data for the last 4 months in a bar chart
         */}
        {props.sortedData.length >= 2 && (
          <BrainFunctionBarChart
            maxAssessments={4}
            data={props.sortedData}
            scale={props.scale}
            colors={props.colors}
            barThickness={16}
            width={120}
            showValues={ctx.showNumbers}
          />
        )}
      </Flex>
    </div>
  );
}

export function ShowScoresToggle(props: {
  showNumbers: boolean;
  setShowNumbers: (a: React.SetStateAction<boolean>) => void;
  label?: DynamicOrStaticProperty<string, boolean>;
  customStyles?: {
    flexStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties;
  };
}) {
  const label = resolveDynamicOrStaticProperty(
    props.label ?? `${props.showNumbers ? 'Hide' : 'Show'} scores`,
    props.showNumbers
  );
  return (
    <Flex gap={'small'} align="center" style={props.customStyles?.flexStyle}>
      <Text style={{ ...{ fontSize: 10 }, ...props.customStyles?.textStyle }}>
        {label}
      </Text>
      <Switch
        /**
         * The switch should be on when `showNumbers` is false. Users disable the 'hide' option to see values
         */
        checked={props.showNumbers}
        onChange={(_state, event) => {
          event.preventDefault();
          props.setShowNumbers(s => !s);
        }}
      />
    </Flex>
  );
}
