// raw score t score standard error
/*
Cognitive Function Abilities

[23.3,26.6,28.6,30.2,31.6,32.9,34.0,35.1,36.1,37.0,38.0,38.9,39.8,40.7,41.6,42.5,43.4,44.3,45.3,46.2,47.2,48.2,49.2,50.2,51.3,52.4,53.6,54.9,56.4,58.0,60.0,62.5,67.1]

[4.4,3.5,3.1,2.9,2.8,2.6,2.6,2.5,2.5,2.5,2.5,2.4,2.4,2.4,2.5,2.5,2.5,2.5,2.5,2.5,2.5,2.5,2.6,2.6,2.6,2.6,2.7,2.8,2.9,3.1,3.4,3.9,5.2]


* Score of 50 = mean of General Population Reference Sample
*/

import { tScoreCategories } from 'features/patient/outcome-measures/promis/promisTypes';

export enum tScoreCategory {
  Anxiety = 'Anxiety',
  AbilityToParticipateInSocialRolesAndActivities = 'Abilities to participate in social roles and activities',
  CognitiveFunctionAbilities = 'Cognitive Function Abilities',
  CognitiveFunction = 'Cognitive Function',
  Depression = 'Depression',
  Fatigue = 'Fatigue',
  PainInterferance = 'Pain Interferance',
  PhysicalFunction = 'Physical Function',
  SleepDisturbance = 'Sleep Disturbance'
}

// prettier-ignore
export const tScoreMapping = {
  'Anxiety': 'T-Score (Anx.)',
  'Abilities to participate in social roles and activities': 'T-Score (Soc. R.)',
  'Cognitive Function Abilities': 'T-Score (Cog. Ab.)',
  'Cognitive Function': 'T-Score (Cog. Gen.)',
  'Depression': 'T-Score (Dep.)',
  'Fatigue': 'T-Score (Fat.)',
  'Pain Interferance': 'T-Score (Pain Int.)',
  'Physical Function': 'T-Score (Phys. F.)',
  'Sleep Disturbance': 'T-Score (Sl. Dis.)'
} as const;

// prettier-ignore
export const tScoreMapCategory: Record<typeof tScoreCategories[number], tScoreCategory> = {
  'Anxiety': tScoreCategory.Anxiety,
  'Abilities to participate in social roles and activities': tScoreCategory.AbilityToParticipateInSocialRolesAndActivities,
  'Cognitive Function': tScoreCategory.CognitiveFunction,
  'Cognitive Function Abilities': tScoreCategory.CognitiveFunctionAbilities,
  'Depression': tScoreCategory.Depression,
  'Fatigue': tScoreCategory.Fatigue,
  'Pain Interferance': tScoreCategory.PainInterferance,
  'Physical Function': tScoreCategory.PhysicalFunction,
  'Sleep Disturbance': tScoreCategory.SleepDisturbance
};

// abstract class
class ITScore {
  static rawScore: number[];
  static tScore: number[];
  static standardError: number[];

  constructor() {
    throw new Error('static class cannot be instantiated');
  }

  findTScore(rawScore: number): number {
    throw new Error('findTScore needs to be implemented: ' + rawScore);
  }
}

// prettier-ignore
class Anxiety extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [37.1,43.2,45.9,47.8,49.4,50.8,52.1,53.2,54.3,55.4,56.4,57.4,58.4,59.4,60.4,61.4,62.5,63.5,64.5,65.6,66.6,67.7,68.7,69.8,70.8,71.9,73.0,74.1,75.4,76.7,78.2,80.0,83.1];
  static standardError: number[] = [5.5,3.3,2.8,2.5,2.3,2.2,2.1,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.0,2.1,2.3,2.6,3.4];

  static findTScore(rawScore: number):number {
    const index = Anxiety.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return Anxiety.tScore[index];
  }
}

// prettier-ignore
class Depression extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [37.1,44.7,47.5,49.4,50.9,52.1,53.2,54.1,55.1,55.9,56.8,57.7,58.5,59.4,60.3,61.2,62.1,63.0,63.9,64.9,65.8,66.8,67.7,68.7,69.7,70.7,71.7,72.8,73.9,75.0,76.4,78.2,81.3];
  static standardError: number[] = [5.7,3.3,2.7,2.3,2.0,1.9,1.8,1.8,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.9,2.0,2.4,3.4];

  static findTScore(rawScore: number):number {
    const index = Depression.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return Depression.tScore[index];
  }
}

// prettier-ignore
class PhysicalFunction extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [20.3,23.9,26.0,27.5,28.8,29.8,30.8,31.7,32.5,33.2,34.0,34.7,35.4,36.1,36.7,37.4,38.1,38.8,39.5,40.1,40.8,41.6,42.3,43.1,43.9,44.7,45.7,46.7,47.8,49.2,50.8,53.0,60.1]
  static standardError: number[] = [3.7,2.5,2.2,2.1,2.0,1.9,1.8,1.8,1.7,1.7,1.7,1.7,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.7,1.7,1.7,1.7,1.8,1.8,1.9,2.1,2.3,2.6,3.0,5.9]

  static findTScore(rawScore: number):number {
    const index = PhysicalFunction.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return PhysicalFunction.tScore[index];
  }
}

// prettier-ignore
class PainInterference extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [40.7,47.9,49.9,51.2,52.3,53.2,54.1,55.0,55.8,56.6,58.1,58.1,58.8,59.5,60.2,60.8,61.5,62.1,62.8,63.5,64.1,64.8,65.5,66.2,66.9,67.7,68.4,69.2,70.1,71.0,72.1,73.5,77.0]
  static standardError: number[] = [5.9,2.4,1.8,1.5,1.4,1.4,1.4,1.4,1.4,1.4,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.3,1.4,1.4,1.6,2.0,3.5]

  static findTScore(rawScore: number):number {
    const index = PainInterference.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return PainInterference.tScore[index];
  }
}

// prettier-ignore
class AbilityToParticipateInSocialRolesAndActivities extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [25.9,29.7,31.3,32.6,33.6,34.5,35.3,36.2,36.9,37.7,38.5,39.3,40.2,41.1,42.0,43.0,44.0,45.0,46.0,47.0,48.0,48.9,49.9,50.8,51.7,52.7,53.6,54.6,55.7,56.8,58.2,60.2,65.4];
  static standardError: number[] = [3.9,2.3,1.9,1.7,1.6,1.6,1.5,1.5,1.5,1.5,1.5,1.6,1.6,1.6,1.7,1.7,1.7,1.7,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.6,1.7,2.0,2.5,4.9];

  static findTScore(rawScore: number):number {
    const index = AbilityToParticipateInSocialRolesAndActivities.rawScore.indexOf(
      rawScore
    );
    if (index == -1) return -1;
    return AbilityToParticipateInSocialRolesAndActivities.tScore[index];
  }
}

// prettier-ignore
class Fatigue extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [33.1,38.5,41.0,42.8,44.3,45.6,46.9,48.1,49.2,50.4,51.5,52.5,53.6,54.6,55.6,56.6,57.5,58.5,59.4,60.4,61.3,62.3,63.3,64.3,65.3,66.4,67.5,68.6,69.8,71.0,72.4,74.2,77.8]
  static standardError: number[] = [4.8,2.7,2.2,2.0,1.9,1.8,1.8,1.8,1.8,1.8,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.7,1.8,1.8,2.0,2.4,3.7];

  static findTScore(rawScore: number):number {
    const index = Fatigue.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return Fatigue.tScore[index];
  }
}

// prettier-ignore
class SleepDisturbance extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [28.9,33.1,35.9,38.0,39.8,41.4,42.9,44.2,45.5,46.7,47.9,49.0,50.1,51.2,52.2,53.3,54.3,55.3,56.3,57.3,58.3,59.4,60.4,61.5,62.6,63.7,64.8,66.1,67.5,69.0,70.8,73.0,76.5];
  static standardError: number[] = [4.8,3.7,3.3,3.0,2.9,2.8,2.7,2.7,2.6,2.6,2.6,2.6,2.5,2.5,2.5,2.5,2.5,2.5,2.5,2.5,2.5,2.6,2.5,2.5,2.5,2.6,2.6,2.7,2.8,3.0,3.2,3.2,4.4];

  static findTScore(rawScore: number):number {
    const index = SleepDisturbance.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return SleepDisturbance.tScore[index];
  }
}

// prettier-ignore
class CognitiveFunction extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [22.4,25.7,27.5,28.9,30.1,31.2,32.2,33.1,33.9,34.8,35.6,36.4,37.2,37.9,38.7,39.5,40.3,41.1,41.9,42.7,43.6,44.4,45.3,46.2,47.2,48.2,49.3,50.5,51.8,53.4,55.4,58.1,63.5];
  static standardError: number[] = [4.1,3.1,2.9,2.6,2.5,2.3,2.3,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.2,2.3,2.3,2.3,2.4,2.5,2.7,2.9,3.3,3.9,5.7];

  static findTScore(rawScore: number):number {
    const index = CognitiveFunction.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return CognitiveFunction.tScore[index];
  }
}

// prettier-ignore
class CognitiveFunctionAbilities extends ITScore {
  static rawScore: number[] = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  static tScore: number[] = [23.3,26.6,28.6,30.2,31.6,32.9,34.0,35.1,36.1,37.0,38.0,38.9,39.8,40.7,41.6,42.5,43.4,44.3,45.3,46.2,47.2,48.2,49.2,50.2,51.3,52.4,53.6,54.9,56.4,58.0,60.0,62.5,67.1]
  static standardError: number[] = [4.4,3.5,3.1,2.9,2.8,2.6,2.6,2.5,2.5,2.5,2.5,2.4,2.4,2.4,2.5,2.5,2.5,2.5,2.5,2.5,2.5,2.5,2.6,2.6,2.6,2.6,2.7,2.8,2.9,3.1,3.4,3.9,5.2]

  static findTScore(rawScore: number):number {
    const index = CognitiveFunctionAbilities.rawScore.indexOf(rawScore);
    if (index == -1) return -1;
    return CognitiveFunctionAbilities.tScore[index];
  }
}

// prettier-ignore
export class TScores {
  static tScoreClass: ITScore;

  static findTScore(rawScore: number, tCategory: tScoreCategory, defaultVal: number | null = null):number {
    switch (tCategory) {
      case tScoreCategory.Anxiety:
        this.tScoreClass = Anxiety;
        break;
      case tScoreCategory.AbilityToParticipateInSocialRolesAndActivities:
        this.tScoreClass = AbilityToParticipateInSocialRolesAndActivities;
        break;
      case tScoreCategory.CognitiveFunction:
        this.tScoreClass = CognitiveFunction;
        break;
      case tScoreCategory.CognitiveFunctionAbilities:
        this.tScoreClass = CognitiveFunctionAbilities;
        break;
      case tScoreCategory.Depression:
        this.tScoreClass = Depression;
        break;
      case tScoreCategory.Fatigue:
        this.tScoreClass = Fatigue;
        break;
      case tScoreCategory.PhysicalFunction:
        this.tScoreClass = PhysicalFunction;
        break;
      case tScoreCategory.PainInterferance:
        this.tScoreClass = PainInterference;
        break;
      case tScoreCategory.SleepDisturbance:
        this.tScoreClass = SleepDisturbance;
        break;
      default:
        throw new Error(`Not a valid tScoreCategory: ${tCategory}`);
    }

    const score = this.tScoreClass.findTScore(rawScore);
    if (score === -1 || score === undefined) {
      if (defaultVal === null) {
        throw new Error(`${rawScore} is out of range for tScoreCategory: ${tCategory}`);
      }
      return defaultVal;
    }
    return score;
  }
}
