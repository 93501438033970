import { Col, Row } from 'antd';
import './assessment-step.scss';

interface StepIndexProps {
  index: number;
}
// interface StepProps {
//   duration?: string;
//   title: string;
//   description: string;
//   index: number;
//   endNode: boolean;
// }
const StepIndex = ({ index }: StepIndexProps) => (
  <div className="steps-icon-container">
    <p className="steps-icon-container__index">{index}</p>
  </div>
);

export interface AssessmentStepProps {
  duration?: string;
  title: string;
  description: string;
  index?: number;
  endNode: boolean;
}

export const Step = ({
  duration = undefined,
  title,
  description,
  index = 1,
  endNode = true
}: AssessmentStepProps) => (
  <div>
    <Row className="steps-container" align="top">
      <Col>
        <StepIndex index={index} />
      </Col>

      <Col>
        <h2
          style={{ fontSize: '22px' }}
          className="steps-container__title crx-typography h2"
        >
          {title}
          {duration && (
            <span className="steps-container__duration">({duration})</span>
          )}
        </h2>
        <span className="steps-container__description crx-typography body-normal">
          {description}
        </span>
      </Col>
    </Row>

    {
      /**
       * Draw a connector line to the next step
       */
      endNode === false && (
        <Row className="line-vertical-container">
          <span className="line-vertical"></span>
        </Row>
      )
    }
  </div>
);
