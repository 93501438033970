import { Frequency, SymptomDuration, Treatment } from 'documents';

export const symptomDurationOptions = [
  { label: SymptomDuration.Days, value: SymptomDuration.Days },
  { label: SymptomDuration.Weeks, value: SymptomDuration.Weeks },
  { label: SymptomDuration.Months, value: SymptomDuration.Months },
  { label: SymptomDuration.Years, value: SymptomDuration.Years }
];

export const treatmentOptions = [
  { label: Treatment.Medication, value: Treatment.Medication },
  { label: Treatment.Therapy, value: Treatment.Therapy },
  { label: Treatment.Other, value: Treatment.Other }
];

export const frequencyOptions = [
  { label: Frequency.Daily, value: Frequency.Daily },
  { label: Frequency.Weekly, value: Frequency.Weekly },
  { label: Frequency.Monthly, value: Frequency.Monthly }
];
