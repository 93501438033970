import './Gauge.scss';

import React, { useEffect, useRef } from 'react';

import { roundSymptomValue } from 'utils/business';

export interface GaugeProps {
  /**
   * The value to display
   */
  value: number;
  /**
   * The highest value this gauge supports
   */
  max?: number;
  /**
   * The lowest value this gauge supports
   */
  min?: number;
  size?: number | string;
  colors?: string[];
  textColors?: string[];
  colorMap?: number[];
  /**
   * A flag indicating if the gauge data has expired
   */
  expired?: boolean;
}

import {
  colors as defaultColors,
  colorMap as defaultColorMap
} from 'constants/value-color';

export const RED = '#E13C18',
  ORANGE = '#E67C12',
  YELLOW = '#EFB708',
  GREEN = '#1FB832';
export const defaultTextColors = [RED, YELLOW, ORANGE, GREEN];
export const Gauge: React.FC<GaugeProps> = ({
  value = 0,
  max = 10,
  size = 140,
  colors = defaultColors,
  textColors = defaultTextColors,
  colorMap = defaultColorMap,
  expired = false
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const fixedValue = roundSymptomValue(value);
    const color = colors[colorMap[fixedValue]];
    const textColor = textColors[colorMap[fixedValue]];

    const startAngle = 0.75 * Math.PI;
    const diffAngle = (1.5 / max) * fixedValue * Math.PI;
    const endAngle = startAngle + diffAngle;

    const context = canvasRef.current?.getContext('2d');

    if (!canvasRef.current || !context) return;

    const width = canvasRef.current?.width;
    const height = canvasRef.current?.height;

    const radius = 52;
    const dialWidth = 16;
    const outerRadius = radius + dialWidth / 2;

    const centerX = width / 2;
    const centerY = height / 2;

    const magicY = 3;

    context.clearRect(0, 0, width, height);

    if (!expired) {
      // Dial Arc
      context.strokeStyle = color;
      context.lineWidth = dialWidth;
      context.beginPath();
      context.arc(centerX, centerY, radius, startAngle, endAngle);
      context.stroke();

      // Pivot
      const dx = outerRadius * Math.cos(endAngle);
      const dy = outerRadius * Math.sin(endAngle);

      context.lineWidth = 2;
      context.beginPath();
      context.moveTo(centerX, centerY);
      context.lineTo(centerX + dx, centerY + dy);
      context.stroke();

      // Value Circle Shadow
      context.save();
      context.beginPath();
      context.lineWidth = 0;
      context.fillStyle = 'white';
      context.shadowColor = 'rgba(0,0,0,0.06)';
      context.shadowBlur = 20;
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
      context.arc(centerX, centerY, 32, 0, 2 * Math.PI, false);
      context.fill();
      context.restore();
    }

    // Value Text
    context.font = '46px Rogan';
    context.fillStyle = textColor;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.fillText(
      expired ? '-' : fixedValue.toString(),
      centerX,
      centerY + magicY
    );
  }, [canvasRef, colorMap, colors, max, textColors, value, expired]);

  return (
    <div
      className={'gauge ' + (expired ? 'expired' : '')}
      style={{ width: size, height: size }}
    >
      <canvas width={size} height={size} ref={canvasRef} />
    </div>
  );
};
