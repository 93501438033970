import { useState } from 'react';
import { GenericButton } from 'components/generic-button';
import { CountdownTimer } from './countdown-timer';

export function TakeABreakFilter({ onContinue }: { onContinue: () => void }) {
  const [showContinue, setContinue] = useState(false);
  const [breaks, setBreaks] = useState(0);

  const countDownFinish = function () {
    setBreaks(breaks => breaks + 1);
    setContinue(true);
  };
  const restart = function () {
    setContinue(false);
  };
  const continueOn = function () {
    if (onContinue) onContinue();
  };

  return (
    <div
      style={{
        position: 'absolute',
        height: '100dvh',
        width: '100%',
        top: 0,
        left: 0,
        background: 'rgba(36, 36, 37, 1)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {showContinue && (
        <div style={{ color: 'white', zIndex: 1001 }}>
          <h1>Ready to continue?</h1>

          <GenericButton
            minHeight={30}
            minWidth={120}
            label={"Let's do it"}
            rounded={true}
            baseFont={true}
            iconBefore={false}
            icon={null}
            onClick={continueOn}
          />
          <br></br>
          <GenericButton
            minHeight={30}
            minWidth={160}
            label={'Take another break'}
            rounded={true}
            baseFont={true}
            iconBefore={false}
            icon={null}
            onClick={restart}
          />
        </div>
      )}
      {!showContinue && (
        <CountdownTimer
          initialSeconds={15}
          onFinish={() => countDownFinish()}
          breaks={breaks}
        />
      )}
    </div>
  );
}
