import { Button, ButtonProps } from 'antd';

export const GrayOutlinedButton = (props: ButtonProps) => (
  <Button
    {...props}
    style={{
      background: '#F0F1F5',
      color: '#000',
      borderRadius: '100px',
      borderColor: '#242425',
      ...props.style
    }}
  />
);
