export function pluralize(inStr: string) {
  // if (inStr.endsWith('y')) {
  //   return inStr.substring(0, inStr.length - 1) + 'ies'
  // }
  if (inStr.endsWith('s')) {
    return inStr + "'";
  }
  return inStr + "'s";
}

export const getFirstName = (name: string) => name.trim().split(' ')[0];
export const getLastName = (name: string) =>
  name.trim().split(' ').slice(1).join(' ');
