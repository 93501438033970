import { Flex, Space } from 'antd';
import { Subtitle, Text, Title } from 'components/mvp-typography';
import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import PatientHomepageImg from 'img/patient-homepage.svg';
import { getFirstName } from 'utils/string/normalise';

export function PatientHomeAssessmentComplete(props: {
  patientInfo: PatientInfoState;
}) {
  if (props.patientInfo.isLoading) return null;

  const firstName = getFirstName(props.patientInfo.patient.displayName ?? '');
  return (
    <Flex justify={'center'} align={'center'} style={{ height: '100%' }}>
      <Space
        direction="vertical"
        size={60}
        style={{ textAlign: 'center', width: '90%' }}
      >
        <div>
          <Title color={'#230056'}>
            Thank you for using ConcussionRx, {firstName}.<br></br> Your
            assessment is complete!
          </Title>
        </div>
        <Space size={80}>
          <div
            style={{
              background: 'white',
              padding: '1.2rem',
              borderRadius: 5,
              maxWidth: 650,
              color: '#230056'
            }}
          >
            <Text>
              Your Physician/Clinician now has access to your results, which
              they can access using your unique Patient ID.
            </Text>
            <br></br>
            <br></br>
            <Text>
              Make sure to make an appointment with your Physician/Clinician, so
              they can share your results and ConcussionRx recommendations.
            </Text>
          </div>
          <img
            src={PatientHomepageImg}
            style={{
              width: 320,
              height: 225,
              top: 373,
              left: 1124,
              borderRadius: 5
            }}
          />
        </Space>
        <div>
          <Subtitle
            style={{ color: '#230056', paddingBottom: '10px' }}
            className="crx-typography button-big"
          >
            {`Repeat your ConcussionRx assessment every 30 days to check on your progress and track your concussion recovery!`}
          </Subtitle>
          <Subtitle color="#A366FC" className="crx-typography button-big">
            You will also get an email notification as a reminder.
          </Subtitle>
        </div>
      </Space>
    </Flex>
  );
}
