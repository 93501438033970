import './styles/index.scss';
import './styles/theme.less';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';

import LogRocket from 'logrocket';

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('cg87nv/concussionrx');
}

import store, { history } from 'app/store';

import {
  legacyLogicalPropertiesTransformer,
  StyleProvider
} from '@ant-design/cssinjs';

const render = () => {
  const App = require('./app/App').default;

  ReactDOM.render(
    <React.StrictMode>
      <StyleProvider transformers={[legacyLogicalPropertiesTransformer]}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      </StyleProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}
