export interface PromisJson {
  id: string;
  subsections: Record<string, PromisBySection>;
}

export interface PromisBySectionQuestions {
  id: number;
  section: string;
  title: string;
  categories: string[];
}

export interface PromisBySection {
  description: string;
  instructions?: string | undefined;
  title?: string;
  min: number;
  max: number;
  questions: PromisBySectionQuestions[];
}

export const categories = [
  'Total',
  'Physical Function',
  'Anxiety',
  'Depression',
  'Fatigue',
  'Sleep Disturbance',
  'Abilities to participate in social roles and activities',
  'Cognitive Function Abilities',
  'Cognitive Function',
  'Pain Interferance',
  'Pain Intensity'
] as const;

/**
 * All the tScore values in a promis questionnaire
 *
 * different from subsections total categories to be calculated
 */
export const tScoreCategories = [
  'Physical Function',
  'Anxiety',
  'Depression',
  'Fatigue',
  'Sleep Disturbance',
  'Abilities to participate in social roles and activities',
  'Cognitive Function Abilities',
  'Cognitive Function',
  'Pain Interferance'
  // 'Pain Intensity' we don't need for calculations in tscores
] as const;
