import './Tile.scss';

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Card } from 'antd';

export interface TileProps {
  title: string;
  logo: string;
  link: string;
}

export const Tile: FC<TileProps> = ({ title, logo, link }) => (
  <Link to={link} key={title}>
    <Card className="crx-card crx-tile" hoverable>
      {logo !== '' && <img src={logo} alt="logo" />}
      <div className="crx-tile__title">{title}</div>
    </Card>
  </Link>
);
