import { Progress, ProgressProps } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import clsx from 'clsx';
import './rx-progress-bar.scss';

interface RxProgressBarProps extends ProgressProps {
  fulfilled: number;
  required: number;
  showInfo?: boolean;
  // strokeColor?: string;
  trailColor?: string;
  style?: ProgressProps['style'];
}

/**
 * A component connected to the global rxProgressBar store
 * @param props
 * @returns
 */
export function ConnectedRxProgressBar(
  props: { style?: ProgressProps['style'] } & ProgressProps
) {
  // pull values from rootReducer ** global store **
  const rxProgressBarPref = useSelector(
    (state: RootState) => state.rxProgressBarPref
  );
  const { fulfilled, required } = rxProgressBarPref;

  return (
    <RxProgressBarHeader fulfilled={fulfilled} required={required} {...props} />
  );
}

// you can style the width of the bar in the parent component wrapping this as it's sole child
export function RxProgressBarHeader({
  showInfo = false,
  strokeColor = '#A366FC',
  trailColor = 'rgba(163, 102, 252, 0.25)',
  ...props
}: RxProgressBarProps) {
  const getPercent = () =>
    Math.min(100, (props.fulfilled / props.required) * 100);
  return (
    <Progress
      {...props}
      className={clsx('rx-progress-bar', props.className)}
      percent={getPercent()}
      showInfo={showInfo}
      strokeColor={strokeColor}
      trailColor={trailColor}
    />
  );
}
