import { Flex, Form, FormItemProps, Input, Modal, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { DarkButton } from 'components/dark-button';
import { Text } from 'components/mvp-typography';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { ConcussionRXLogo } from 'features/sign-in/v2/concussionRx-logo';
import { styles } from 'features/sign-in/v2/styles';
import { CSSProperties, ReactNode, useState } from 'react';
import { resolveError } from 'utils/formatters/error/resolve-error';

export const BASE_USER_ADD_QUESTIONS: FormQuestion[] = [
  {
    type: 'string',
    name: 'firstName',
    label: 'First name',
    rules: [{ required: true }]
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Last name',
    rules: [{ required: true }]
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    rules: [{ required: true }, { type: 'email' }]
  }
];

interface _FormArgs {
  firstName: string;
  lastName: string;
  email: string;
}

type FormArgs = _FormArgs & Record<string, string>;

/**
 * A typed form item
 *
 * This is a new function instead of an alias because eslint was throwing a parsing error
 */
const FormItem = (props: FormItemProps<FormArgs>) => <Form.Item {...props} />;

export default function UserAddModalWrapper(props: {
  onAddUser: (data: FormArgs) => Promise<void | any> | void | any;

  /**
   * The type of user to create. Must be in the singular form
   */
  type: string;
  containerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  questions: FormQuestion[];
}) {
  const typeToCreate = props.type.toLowerCase();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={props.containerStyle}>
      <DarkButton
        style={props.buttonStyle}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Add {typeToCreate}
      </DarkButton>
      <UserEditableDetailsModal
        {...props}
        onSubmit={props.onAddUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        submitText={`Create ${typeToCreate}`}
        actionProcessingDescription={`Sending invitation. Please do not close or reload this window or tab.`}
        questions={props.questions}
      />
    </div>
  );
}
export type FormQuestion =
  | ({
      type: 'string';
      label: string;
      name: string;
      disabled?: boolean;
    } & Pick<FormItemProps, 'rules'>)
  | ({
      type: 'select';
      label: string;
      name: string;
      disabled?: boolean;
      loading?: boolean;
      options: DefaultOptionType[];
    } & Pick<FormItemProps, 'rules'>);

export function UserEditableDetailsModal(props: {
  onSubmit: (data: FormArgs) => Promise<void | any> | void | any;
  isOpen: boolean;
  setIsOpen: (s: boolean) => void;
  submitText: ReactNode;
  actionProcessingDescription: ReactNode;
  initialValues?: Record<string, string>;
  questions: FormQuestion[];
}) {
  const [error, setError] = useState<Error | null>(null);
  const [isActionProcessing, setIsActionProcessing] = useState(false);

  async function onAddUserSubmitEvent(event: FormArgs) {
    setIsActionProcessing(true);
    try {
      await props.onSubmit(event);
      props.setIsOpen(false);
      setError(null);
    } catch (e) {
      setError(resolveError(e));
    } finally {
      setIsActionProcessing(false);
    }
  }

  return (
    <Modal
      open={props.isOpen}
      onCancel={() => props.setIsOpen(false)}
      closable={!isActionProcessing}
      footer={() => <></>}
    >
      <Flex
        style={{
          ...styles.titleContainer
        }}
        justify="center"
      >
        <div
          style={{
            maxWidth: 300
          }}
        >
          <ConcussionRXLogo />
        </div>
      </Flex>
      <Form
        onFinish={onAddUserSubmitEvent}
        disabled={isActionProcessing}
        layout="vertical"
        initialValues={props.initialValues}
      >
        {props.questions.map(question => {
          if (question.type === 'string') {
            return (
              <FormItem
                key={question.name}
                name={question.name}
                label={question.label}
                rules={question.rules}
              >
                <Input disabled={question.disabled} />
              </FormItem>
            );
          }
          if (question.type === 'select') {
            return (
              <FormItem
                key={question.name}
                name={question.name}
                label={question.label}
                rules={question.rules}
              >
                <Select
                  options={question.options}
                  disabled={question.disabled}
                  loading={question.loading}
                />
              </FormItem>
            );
          }
          throw new Error(`Unknown type '${(question as any).type}'`);
        })}

        <FormItem>
          <div>
            <DarkButton
              htmlType="submit"
              loading={isActionProcessing}
              style={{
                padding: '1rem 2rem',
                height: 'auto'
              }}
            >
              {props.submitText}
            </DarkButton>
            {isActionProcessing && (
              <Text>{props.actionProcessingDescription}</Text>
            )}
          </div>
        </FormItem>
      </Form>

      {error && (
        <ErrorDisplay
          title="Something went wrong"
          description=""
          error={error}
          style={{
            width: '100%'
          }}
        />
      )}
    </Modal>
  );
}
