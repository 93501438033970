import React from 'react';
import { Card } from 'antd';

interface TreatmentCardProps extends React.PropsWithChildren<object> {
  title: string;
  img?: string;
  style?: React.CSSProperties | undefined;
}
export const TreatmentCard = ({
  title,
  children,
  img,
  style
}: TreatmentCardProps) => (
  <Card
    bordered={false}
    title={title}
    style={{ minHeight: '300px', ...style }}
    headStyle={{
      fontFamily: 'Comfortaa',
      color: 'rgba(35, 0, 86, 1)',
      background: 'transparent',
      borderBottom: '0px',
      padding: '40px 40px 0px 40px'
    }}
    bodyStyle={{
      background: 'transparent',
      padding: '32px 40px 40px 40px',
      fontFamily: 'Comfortaa'
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      {children}
      {img && <img src={img} style={{ objectFit: 'cover', width: '100px' }} />}
    </div>
  </Card>
);
