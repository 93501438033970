import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction
} from '@reduxjs/toolkit';
import { Collection, HowAreYou, PatientSubcollection } from 'documents';
import moment, { Moment } from 'moment';
import { handleError, handlePending, PageState, ThunkApi } from 'utils/redux';

import { createRepo, Repo } from 'api/firebase';
import howAreYouJson from 'data/how-are-you.json';

import { SliderListItem } from 'components/slider-list';

const howAreYouData: HowAreYou = howAreYouJson as HowAreYou;

export interface HowAreYouTrackerState extends PageState {
  howAreYou: HowAreYou;
  isSubmitting: boolean;
  lastUpdated?: Moment;
  showModal: boolean;
}

const initialState: HowAreYouTrackerState = {
  hasError: false,
  howAreYou: { ...howAreYouData },
  isLoading: false,
  isSubmitting: false,
  showModal: false,
  isDone: false
};

let repo: Repo<HowAreYou>;

const fetchHowAreYou = createAsyncThunk(
  'howAreYouTracker/fetchHowAreYou',
  async (patientId: string) => {
    repo = createRepo<HowAreYou>(
      `${Collection.Patients}/${patientId}/${PatientSubcollection.HowAreYou}`
    );

    const howAreYou = await repo.find(howAreYouJson.id);

    return howAreYou?.metadata?.updated;
  }
);

const submit = createAsyncThunk<void, void, ThunkApi<HowAreYouTrackerState>>(
  'howAreYouTracker/submit',
  async (_, { getState }) => {
    const { howAreYou } = getState();
    const cleanedQuestions = howAreYou.questions.map(x =>
      x.value == null ? { ...x, value: 0 } : x
    );
    await repo.set({ ...howAreYou, questions: cleanedQuestions });
  }
);

const slice = createSlice({
  name: 'howAreYouTracker',
  initialState,
  reducers: {
    update: (state, { payload }: PayloadAction<SliderListItem>) => {
      if (!state.howAreYou) return;
      console.log('update howAreYouSlice: ', state);

      const question = state.howAreYou.questions.find(
        x => x.title === payload.title
      );
      if (!question) {
        console.error(
          `Question '${payload.title}' not found`,
          payload,
          current(state)
        );
        return;
      }

      question.value = payload.value;
    },
    closeModal: state => {
      state.showModal = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchHowAreYou.pending, handlePending);
    builder.addCase(fetchHowAreYou.rejected, handleError);
    builder.addCase(
      fetchHowAreYou.fulfilled,
      (state, { payload: lastUpdated }) => {
        state.isLoading = false;
        // Type #2 date (see 'Working with Dates in CRx' in the readme)
        state.lastUpdated = lastUpdated
          ? moment(lastUpdated?.toDate())
          : undefined;
      }
    );

    builder.addCase(submit.pending, state => {
      state.isSubmitting = true;
    });
    builder.addCase(submit.rejected, handleError);
    builder.addCase(submit.fulfilled, state => {
      state.isSubmitting = false;
      state.lastUpdated = moment();
      state.howAreYou = { ...howAreYouData };
      state.isDone = true;
      // state.showModal = true;
    });
  }
});

export { fetchHowAreYou, submit, initialState };
export const { closeModal, update } = slice.actions;
export default slice.reducer;
