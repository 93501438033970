export enum Rating {
  None = 'none',
  Normal = 'normal',
  Mild = 'mild',
  Moderate = 'moderate',
  Severe = 'severe'
}

export interface Bound {
  left: number;
  right: number;
}

export interface Dimension {
  left: number;
  leftBound: number;
  rightBound: number;
  lines: {
    [Rating.None]: Bound;
    [Rating.Normal]: Bound;
    [Rating.Mild]: Bound;
    [Rating.Moderate]: Bound;
    [Rating.Severe]: Bound;
  };
}
