import {
  CgsInventory,
  CgsSliceReducer,
  initialState,
  submit,
  updateCgsItem,
  validate
} from './cgsSlice';
import { RadioButtonListItem } from './radio-button-list';

import { splitChunks } from 'utils/splitChunks';
import { FormattedSection } from '../paginationSlice';

import { Inventory } from 'documents';
import {
  PagedQuestionnaire,
  QUESTIONNAIRE_TITLE_24_HR
} from '../../assessment/paged-questionnaire';
import cgsOrder from './cgs-order.json';

export function PaginationCombined(props: { storageKey: string }) {
  return (
    <PagedQuestionnaire
      initialState={initialState}
      reducer={CgsSliceReducer as any}
      getData={state => state.cgs}
      storageKey={props.storageKey}
      actions={{
        formatData: formatCgs,
        validate,
        updateItem: updateCgsItem,
        submit
      }}
      pageTitle={QUESTIONNAIRE_TITLE_24_HR}
    />
  );
}

// purpose is to standardize paginationCombined data intake;
export function formatCgs(data: CgsInventory, pageLimit: number) {
  const ret: FormattedSection[] = [];
  const questions: RadioButtonListItem[] = [];
  Object.keys(data.data).forEach(key =>
    questions.push({ title: key, value: data.data[key] })
  );

  const push: FormattedSection = {
    description: data.description,
    instructions: data.instructions,
    min: data.min,
    max: data.max,
    type: 'button',
    beforeLabel: data.beforeLabel ? data.beforeLabel : 'Not experience',
    afterLabel: data.afterLabel ? data.afterLabel : 'Severe experience',
    colorsForward: data.colorsForward,
    iconsForward: data.iconsForward,
    questions: getItems(data),
    page: splitChunks(questions, pageLimit),
    pageLength: 0
  };
  push.pageLength = push.page.length;
  ret.push(push);
  return ret;
}

function getItems(cgs?: Inventory): RadioButtonListItem[] {
  if (!cgs) return [];

  return cgsOrder.map(x => ({ title: x, value: cgs.data[x] }));
}
