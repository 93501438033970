import './PersonalHistoryForm.scss';

import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Collapse, Form } from 'antd';
import { desktopMinWidth } from 'constants/media-queries';
import { PatientPersonalHistory } from 'documents';
import { handleFormChange } from 'utils/antd';

import {
  ConcussionForm,
  DisorderForm,
  keyDependency,
  LearningForm,
  SubstanceForm
} from './forms';

const { Panel } = Collapse;
const panelProps = { className: 'crx-form-collapse__panel' };

export interface PersonalHistoryFormProps {
  value?: PatientPersonalHistory;
  onChange: (value: PatientPersonalHistory) => void;
}

export function PersonalHistoryForm(props: PersonalHistoryFormProps) {
  const { value, onChange } = props;
  const isDesktop = useMediaQuery(desktopMinWidth);

  const defaultActiveKey = isDesktop ? [1, 2, 3, 4] : [1];

  function onValuesChange(changedValue: any, changedValues: any) {
    onChange(
      handleFormChange(changedValue, changedValues, value, keyDependency)
    );
  }

  return (
    <Form
      name="personal-history"
      initialValues={{ ...value }}
      layout="vertical"
      onValuesChange={onValuesChange}
    >
      <Collapse
        bordered={false}
        accordion={!isDesktop}
        className="crx-form-collapse"
        defaultActiveKey={defaultActiveKey}
        expandIconPosition="right"
        ghost
      >
        <Panel header="Heading" key="1" {...panelProps}>
          <ConcussionForm
            concussionHx={value?.concussionHx}
            headacheHx={value?.headacheHx}
          />
        </Panel>
        <Panel header="Heading" key="2" {...panelProps}>
          <LearningForm
            addAdhdDx={value?.addAdhdDx}
            learningDisability={value?.learningDisability}
            repeatedGrade={value?.repeatedGrade}
          />
        </Panel>
        <Panel header="Heading" key="3" {...panelProps}>
          <DisorderForm />
        </Panel>
        <Panel header="Heading" key="4" {...panelProps}>
          <SubstanceForm />
        </Panel>
      </Collapse>
    </Form>
  );
}
