import './PatientCgs.scss';

import { useEffect } from 'react';

import { RootState } from 'app/rootReducer';

import { useReducer } from 'hooks/useReducer';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from 'antd';
import { Form } from 'components';

import cgsInventories, {
  initialLoad,
  initialState,
  notSelected,
  submit
} from './cgsSlice';

import { OutcomeMeasuresHeader } from 'components/header';
import { setFaqObject } from 'features/navigation/navigationSlice';

import { PaginationCombined } from './cgs-pagination-combined';
import { cleanPagedQuestionnaireStorage } from 'features/patient/assessment/paged-questionnaire';

export function PatientCgs() {
  const user = useSelector((state: RootState) => state.auth.user);
  const faqDispatch = useDispatch();

  const [{ cgs, lastUpdated }, dispatch] = useReducer(
    cgsInventories,
    initialState
  );

  useEffect(() => {
    dispatch(initialLoad(user!.uid));
    faqDispatch(
      setFaqObject({
        min: (cgs as any).min,
        max: (cgs as any).max,
        instructions: (cgs as any).instructions,
        description: (cgs as any).description
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChanged = Object.values(cgs.data).some(value => value > notSelected);
  const cgsLength = Object.keys(cgs.data).length;

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <OutcomeMeasuresHeader
        label={'Exit'}
        title="2nd Questionnaire"
        onExit={() => {
          cleanPagedQuestionnaireStorage('questionnaire-cgs');
        }}
      />
      <Form
        isChanged={isChanged}
        lastUpdated={lastUpdated}
        onSubmit={() => dispatch(submit())}
        showFooter={false}
      >
        {cgsLength > 0 && <PaginationCombined storageKey="questionnaire-cgs" />}
      </Form>
    </Layout>
  );
}
