import { createSlice } from '@reduxjs/toolkit';
import { IHandleError } from 'utils/redux/thunk-action-handlers';

export interface PrintSliceState extends IHandleError {
  statusMessage?: string;
}

export const initialState: PrintSliceState = {
  isLoading: false,
  hasError: false
};

const printSlice = createSlice({
  name: 'printSlice',
  initialState: initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    setProgressionStatus(state, { payload }: { payload: string }) {
      state.isLoading = true;
      state.statusMessage = payload;
    },

    setFinished(state) {
      state.isLoading = false;
      state.statusMessage = undefined;
    }
  }
});

export const printSliceReducer = printSlice.reducer;
export const printSliceActions = printSlice.actions;
