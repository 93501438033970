import './OnboardingProgress.scss';

import { Space } from 'antd';

// export interface OnboardingProgressProps {
//   progress: number;
// }

export function OnboardingProgress() {
  return (
    <Space
      direction="vertical"
      className="onboarding-progress"
      style={{ textAlign: 'center' }}
    >
      <h2 style={{ marginBottom: '5px' }}>Let&apos;s set up your profile</h2>
      <p className="onboarding-progress__description">
        This will help your clinical team to create the right treatment for your
        recovery
      </p>
      {/* <div className="onboarding-progress__content">
        <Progress
          percent={props.progress}
          className="onboarding-progress__bar"
        />
      </div> */}
    </Space>
  );
}
