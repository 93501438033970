import { Flex, Typography } from 'antd';
import { path } from 'd3';
import { ComponentProps, useMemo } from 'react';
import { formatNumber } from 'utils/formatters/normalise';
import { SVGBar } from '../features/patient/dashboard/symptom-card/svg-bar';
const paddingVertical = 12;
const paddingCircle = 5;
const paddingLeft = 5;
const labelYOffset = 40;

export type BarChartData = {
  fill: string;
  value: number;
  label?: string;
  showValue?: boolean;
};

type PositionedBarChartData = {
  x: number;
  barHeight: number;
  data: BarChartData;
};

interface BarChartProps {
  title: string;
  data: BarChartData[];
  barThickness: number;
  width?: number;
  height?: number;
  max: number;
  min: number;
}

export function BarChart(props: BarChartProps) {
  /** Default width and height **/
  const width = props.width ?? 100;
  const height = props.height ?? 200;
  const circleRadius = 9;

  const labelsAreShowing = props.data.some(data => data.label);
  const maxBarHeight = getMaxBarHeight(
    height,
    circleRadius,
    labelsAreShowing ? labelYOffset : 0
  );
  const dataWithPositions = useMemo<PositionedBarChartData[]>(() => {
    return props.data.map((data, i) => {
      const x = (props.barThickness + 14) * i;
      const barHeight = Math.max(
        5,
        Math.floor(
          ((data.value - props.min) / (props.max - props.min)) * maxBarHeight
        )
      );
      return {
        x,
        barHeight: barHeight,
        data
      };
    });
  }, [maxBarHeight, props.data, props.barThickness, props.max, props.min]);

  return (
    <Flex vertical justify={'center'}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        // overflow={'visible'}
      >
        <g
          transform={`translate(${paddingLeft}, ${
            height -
            paddingVertical * 2 -
            paddingCircle -
            (labelsAreShowing ? labelYOffset - 10 : 0)
          })`}
        >
          <BarChartLine
            data={dataWithPositions}
            dx={props.barThickness / 2}
            dy={0}
          />
        </g>
        {dataWithPositions.map((data, i) => {
          return (
            <g
              className="bar-chart-group"
              key={`data-${i}-${data.data.value}`}
              transform={`translate(${data.x + paddingLeft}, ${
                height -
                paddingVertical -
                (labelsAreShowing ? labelYOffset - 10 : 0)
              })`}
            >
              <CircleWithValue
                barHeight={data.barHeight}
                barThickness={props.barThickness}
                data={data.data}
                textProps={{
                  fontSize: 10
                }}
                radius={circleRadius}
              />

              <SVGBar
                x={0}
                y={0}
                width={props.barThickness}
                height={data.barHeight}
                fill={data.data.fill}
              />
              {
                /**
                 * Show the bar label at the bottom
                 */
                data.data.label !== 'undefined' && data.data.label !== '' && (
                  <text
                    fill="#767679"
                    style={{
                      fontSize: `${10 / 16}rem`,
                      fontFamily: 'Comfortaa'
                    }}
                    transform={`translate(${0}, ${labelYOffset}) rotate(-60)`}
                  >
                    {data.data.label}
                  </text>
                )
              }
            </g>
          );
        })}
      </svg>
      <Typography.Text
        style={{
          fontFamily: 'Comfortaa',
          fontSize: `${12 / 16}rem`,
          fontWeight: '500',
          lineHeight: `${17 / 16}rem`,
          letterSpacing: '0em',
          textAlign: 'center',
          color: '#767679'
        }}
      >
        {props.title}
      </Typography.Text>
    </Flex>
  );
}

function getMaxBarHeight(
  height: number,
  circleRadius: number,
  extraOffset: number
) {
  return (
    height -
    (paddingVertical * 2 + paddingCircle + circleRadius * 2 + extraOffset)
  );
}

function CircleWithValue(props: {
  barHeight: number;
  data: BarChartData;
  barThickness: number;
  radius: number;
  textProps?: ComponentProps<'text'>;
}) {
  return (
    <g
      transform={`translate(${props.barThickness / 2}, ${
        -props.barHeight - paddingVertical - paddingCircle
      })`}
    >
      <circle
        r={props.radius}
        stroke={props.data.fill}
        strokeWidth={3}
        {...(props.data.showValue
          ? {
              fill: props.data.fill
            }
          : {
              fill: 'white'
            })}
      />
      <BarChartValue data={props.data} {...props.textProps} fill={'white'} />
    </g>
  );
}

export function BarChartValue(
  props: { data: BarChartData } & ComponentProps<'text'>
) {
  const { data, ...textProps } = props;
  if (!data.showValue) return <></>;

  const value = formatNumber(data.value);

  return (
    <text
      fill="#767679"
      alignmentBaseline="middle"
      style={{ fontFamily: 'Comfortaa' }}
      textAnchor="middle"
      {...textProps}
    >
      {value}
    </text>
  );
}

export function BarChartLine(props: {
  data: PositionedBarChartData[];
  dx: number;
  dy: number;
}) {
  const pathDCommand = useMemo(() => {
    const _path = path();
    props.data.forEach((data, i) => {
      const operation =
        i === 0 ? _path.moveTo.bind(_path) : _path.lineTo.bind(_path);
      operation(data.x + props.dx, -data.barHeight + props.dy);
    });

    return _path.toString();
  }, [props.data, props.dx, props.dy]);

  return <path d={pathDCommand} stroke="#767679" strokeWidth={1} />;
}
