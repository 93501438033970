import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import { PatientRoutePrefix, Routes } from 'routes';

import { Role } from 'features/auth/types';

import { Skeleton } from 'antd';
import { useAppSelector } from 'app/rootReducer';
import { PrivateRoute } from 'components/routes/private-route';
import { AssessmentIntro } from 'features/patient/assessment/assessment-intro';
import { QuestionnaireControlFlow } from 'features/patient/assessment/questionnaire/questionnaire-control-flow';
import { PatientRoutePattern } from './patient-route-helpers';
import { PatientRouteSwitchPatientId } from './route-switch-patient-patientid';

export function PatientRouteSwitch() {
  const { user, isUserDataLoading: userIsLoading } = useAppSelector(
    state => state.auth
  );

  const isPatient = user?.role === Role.Patient;
  if (userIsLoading) return <Skeleton active />;

  return (
    <Switch>
      <PrivateRoute
        exact
        roles={[Role.Patient]}
        path={Routes.AssessmentIntro}
        component={() => <AssessmentIntro />}
        redirectPath={PatientRoutePrefix}
      />
      <PrivateRoute
        exact
        roles={[Role.Patient]}
        path={Routes.QuestionnaireFlow}
        component={() => <QuestionnaireControlFlow />}
        redirectPath={PatientRoutePrefix}
      />

      <PrivateRoute
        roles={[Role.Clinician, Role.ClinicAdmin, Role.Patient]}
        path={PatientRoutePattern}
        component={() => <PatientRouteSwitchPatientId user={user!} />}
        redirectPath={PatientRoutePrefix}
      />

      <Route
        /**
         * Catch all routes that were not matched
         */
        path={[PatientRoutePrefix]}
        render={() => {
          // Redirect to the sign-in page if the user is not logged in
          if (!user) {
            return <Redirect to={'/'} />;
          }

          // If the user is logged in and is a patient then redirect them to the `patient/<patientId>` route
          if (isPatient)
            return (
              <Redirect
                to={generatePath(PatientRoutePattern, { patientId: user.uid })}
              />
            );

          // Otherwise the user is a clinician
          return <Redirect to={Routes.ClinicianHome} />;
        }}
      />
    </Switch>
  );
}
