import { useState } from 'react';
import { Col, Form, Select, Space } from 'antd';
import { ImpactLocation, PatientInjuryKey } from 'documents';
import { oneThirdCol, selectMultipleProps } from 'utils/antd';
import { impactLocationOptions } from '../InjuryForm/select-options';
import { ImpactLocationDiagram } from './impact-location-diagram';
import { ProfileFormProps } from './ProfileForm';

/**
 * The input for impact location.
 * This renders a select dropdown and an impact location diagram.
 *
 * This component was split from the parent for performance.
 */
export function ImpactLocationInput(props: {
  value: ProfileFormProps['value'];
}) {
  const [impactPreview, setImpactPreview] = useState<null | ImpactLocation>(
    null
  );
  return (
    <>
      {/* Impact location input */}
      <Col {...oneThirdCol}>
        <Space style={{ width: '100%' }} direction="vertical">
          <Form.Item
            name={PatientInjuryKey.impactLocation}
            rules={[
              {
                required: true,
                message: 'An impact location is required'
              }
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              options={impactLocationOptions}
              {...selectMultipleProps}
              placeholder="Location of Impact"
              style={{ fontWeight: 'bold' }}
              optionRender={option => {
                return (
                  <Space
                    onMouseEnter={() => {
                      // Set the current option to be the previewed location
                      setImpactPreview(option.value as ImpactLocation);
                    }}
                    onMouseLeave={() => {
                      // Unset the current option
                      setImpactPreview(null);
                    }}
                    style={{
                      // Increase the hover area
                      width: '100%'
                    }}
                  >
                    {option.value}
                  </Space>
                );
              }}
            />
          </Form.Item>
        </Space>
      </Col>

      {/* Impact location diagram */}
      <Col>
        <ImpactLocationDiagram
          locations={[
            // Display the impact locations or nothing if the value doesn't exist
            ...(props.value?.impactLocation ?? []),

            // Add impactPreview if it is not falsy (expected to be null)
            ...(impactPreview ? [impactPreview] : [])
          ]}
        />
      </Col>
    </>
  );
}
