import { Button } from 'antd';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export function ActionButtonLink(
  props: Omit<Parameters<Link>[0], 'component'> & { disabled?: boolean }
) {
  // Remove the disabled prop so that only the button inherits the prop
  const { disabled, ..._props } = props;

  return (
    <Link
      {..._props}
      className={clsx('crx-typography body-bold', props.className)}
      style={{
        display: 'inline-flex',
        ...{
          border: `1px solid #E4E6EB`,
          background: '#F0F1F5',
          borderRadius: 100,
          padding: '10px 16px 10px 16px'
        },
        ...props.style
      }}
      component={linkProps => (
        <Button
          // Allow the button to be disabled
          disabled={disabled}
          {...linkProps}
          onClick={e => {
            e.preventDefault();
            linkProps.navigate();
          }}
        />
      )}
    />
  );
}
