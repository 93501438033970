import { Alert } from 'antd';
import { DarkButton } from 'components/dark-button';
import { Text } from 'components/mvp-typography';
import { PrintableRootPage } from 'components/page/page-2';

export function CreateAccountConfirmation() {
  return (
    <PrintableRootPage title="Account successfully created">
      <Alert
        type="success"
        showIcon
        style={{ paddingTop: '20px' }}
        description={
          <>
            <Text>
              Your account has been created and the passsword has been set!
            </Text>
            <Text style={{ paddingBottom: '20px' }}>
              To start using the app you will need to sign in using your email
              address and password.
            </Text>
            <DarkButton
              onClick={() => {
                // Reload the page to start fetching new tokens
                window.location.reload();
              }}
            >
              Log in to ConcussionRx
            </DarkButton>
          </>
        }
      />
    </PrintableRootPage>
  );
}
