import React, { useMemo } from 'react';
import { path as d3Path } from 'd3';

/**
 * A reusable SVG bar component for charts.
 * The bar grows in the positive x (right) and negative y (up) axis from the (x,y) position
 */
export function SVGBar(
  props: {
    fill: string;
    width: number;
    height: number;
    x: number;
    y: number;
    transform?: string[];
  } & Omit<React.SVGProps<SVGPathElement>, 'transform'>
) {
  const bezierOffset = 4;
  const pathDCommands = useMemo(() => {
    const path = d3Path();

    // Generate the path for a rounded bar
    // _x and _y are used to convert relative coordinates to D3 absolute coordinates
    let _x = 0;
    let _y = 0;
    const width = props.width - bezierOffset * 2;
    const height = props.height - bezierOffset * 2;

    path.moveTo(_x, _y);
    path.bezierCurveTo(
      _x,
      _y + -2.2091,
      _x + 1.7909,
      _y - bezierOffset,
      (_x += bezierOffset),
      (_y += -bezierOffset)
    );

    path.lineTo((_x += width), _y);

    path.bezierCurveTo(
      _x + 2.2091,
      _y,
      _x + bezierOffset,
      _y + 1.7909,
      (_x += bezierOffset),
      (_y += bezierOffset)
    );

    path.lineTo(_x, (_y += height));

    path.bezierCurveTo(
      _x,
      _y + 2.2091,
      _x + -1.7909,
      _y + bezierOffset,
      (_x += -bezierOffset),
      (_y += bezierOffset)
    );

    path.lineTo((_x += -width), _y);

    path.bezierCurveTo(
      _x + -2.2091,
      _y,
      _x + -bezierOffset,
      _y + -1.791,
      (_x += -bezierOffset),
      (_y += -bezierOffset)
    );

    path.lineTo(_x, (_y += -height));

    path.closePath();
    return path.toString();
  }, [props.width, props.height]);

  return (
    <path
      opacity="0.95"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
      // fill={props.fill}
      d={pathDCommands}
      transform={[
        /**
         * The start of the bar should be at (x,y)
         * By default the start of the bar is positioned relative to (0,0)
         * Flip the y-coordinate axis so that the y-axis ranges from [0, -inf)
         */
        `scale(1, -1)`,
        /**
         * Position the element at (0,0)
         * Offset on the Y axis to adjust for the bezier
         */
        `translate(0, ${bezierOffset})`,
        /**
         * Move the bar to the correct location
         * `props.y` is set on the positive y-axis and is converted to the new y-axis with negation
         */
        `translate(${props.x}, ${-props.y})`,

        // Insert extra transformations
        ...(props.transform ?? [])
      ].join(' ')}
    />
  );
}
