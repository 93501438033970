import React, { useEffect, useRef } from 'react';

import { getValueColor } from 'constants/value-color';
import { takeLast } from 'lodash/fp';
import { roundSymptomValue } from 'utils/business';

export enum PointSize {
  Equals,
  Increasing
}

export interface PointsProps {
  values: number[];
  grid?: boolean;
  height?: number;
  poinsSizes?: PointSize;
  title?: string;
}

export function generatePoints(
  nbOfPoints: number,
  width: number,
  height: number
) {
  const pts = [];
  for (let i = 0; i <= nbOfPoints; i++) {
    pts.push({
      x: i * (width / nbOfPoints),
      y: Math.random() * height
    });
  }

  return pts;
}

// const BRAND = "#5278D9";
export const FILL_COLOR = '#C2CBE2';

export const Points: React.FC<PointsProps> = ({
  values,
  height = 0,
  grid = false,
  poinsSizes = PointSize.Equals
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const draw = () => {
      const context = canvasRef.current?.getContext('2d');

      if (!canvasRef.current || !context) return;

      const width = canvasRef.current?.parentElement?.clientWidth ?? 0;

      canvasRef.current.width = width;

      const maxCount = 4;
      const maxValue = 10;
      const radius = 10;
      const margin = 2 * radius;

      const getYForValue = (value: number) =>
        height - margin - ((height - 2 * margin) / maxValue) * value;

      context.clearRect(0, 0, width, height);

      const n = Math.min(values.length, maxCount);
      const regionWidth = width / n;

      const lastValues = takeLast(n, values);

      // Background lines
      if (grid) {
        // Grid lines
        context.lineWidth = 1;
        context.strokeStyle = '#F0F2F5';
        context.beginPath();

        for (let i = 0; i <= maxValue; i++) {
          const y = getYForValue(i);

          context.moveTo(0, y);
          context.lineTo(width, y);
        }

        context.stroke();

        context.strokeStyle = 'rgba(118,118,121,0.5)';
        context.beginPath();

        // Left markers
        for (let i = 0; i <= maxValue; i++) {
          const y = getYForValue(i);

          context.moveTo(0, y);
          context.lineTo(6, y);
        }

        context.stroke();
      }

      lastValues.forEach((value, i) => {
        if (i === 0 && values.length < n) {
          return;
        }

        const fixedValue = roundSymptomValue(value);

        const centerX = regionWidth * i + regionWidth / 2;
        const centerYprev =
          height -
          margin -
          ((height - 2 * margin) / 10) *
            roundSymptomValue(values[values.length - n + i - 1]);
        const centerY = getYForValue(fixedValue);

        // Line between circles
        context.lineWidth = 2;
        context.strokeStyle = '#E4E6EB';
        context.beginPath();
        context.moveTo(centerX - regionWidth, centerYprev);
        context.lineTo(centerX, centerY);
        context.stroke();

        // Shadow from lines
        if (i % 2 !== 0) {
          const grd = context.createLinearGradient(
            regionWidth / 2,
            0,
            regionWidth / 2,
            height
          );
          grd.addColorStop(0, 'rgba(230, 232, 235, 1)');
          grd.addColorStop(1, 'rgba(230, 232, 235, 0)');

          if (centerY < centerYprev) {
            context.moveTo(centerX - regionWidth, centerYprev);
            context.lineTo(centerX - regionWidth, height);
            context.lineTo(centerX, height);
            context.lineTo(centerX, centerY);
            context.lineTo(centerX - regionWidth, centerYprev);
          } else {
            context.moveTo(centerX - regionWidth, centerYprev);
            context.lineTo(centerX, centerY);
            context.lineTo(centerX, height);
            context.lineTo(centerX - regionWidth, height);
            context.lineTo(centerX - regionWidth, centerYprev);
          }

          context.fillStyle = grd;
          context.fill();
        }
      });

      // Circles
      lastValues.forEach((value, i) => {
        const fixedValue = roundSymptomValue(value);
        const textColor = getValueColor(value);

        const centerX = regionWidth * i + regionWidth / 2;
        const centerY = getYForValue(fixedValue);

        const radiusModifier = poinsSizes === PointSize.Increasing ? i * 2 : 0;
        const fontSize =
          poinsSizes === PointSize.Increasing ? [14, 16.8, 19.6, 23.45][i] : 14;

        // Value Circle Shadow
        context.save();
        context.beginPath();
        context.lineWidth = 0;
        context.fillStyle = 'white';
        context.shadowColor = 'rgba(0,0,0,0.06)';
        context.shadowBlur = 10;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
        context.arc(
          centerX,
          centerY,
          radius + radiusModifier,
          0,
          2 * Math.PI,
          false
        );
        context.fill();
        context.restore();

        if (i === n - 1) {
          // Mark Last Circle
          context.save();
          context.beginPath();
          context.lineWidth = 1;
          context.strokeStyle = textColor;
          context.fillStyle = textColor;
          context.arc(
            centerX,
            centerY,
            radius + radiusModifier,
            0,
            2 * Math.PI,
            false
          );
          poinsSizes === PointSize.Increasing
            ? context.fill()
            : context.stroke();
          context.restore();
        }

        // Value Text
        context.font = fontSize.toString() + 'px Rogan';
        context.fillStyle =
          poinsSizes === PointSize.Increasing && i === n - 1
            ? 'white'
            : textColor;
        context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.fillText(fixedValue.toString(), centerX, centerY + 1);
      });

      requestAnimationFrame(draw);
    };

    draw();
  }, [height, grid, poinsSizes, values]);

  return <canvas style={{ width: '100%' }} height={height} ref={canvasRef} />;
};
