import './HistoricalTable.scss';

import React from 'react';

import { longDateFormat } from 'constants/date-time';
import { getValueColor } from 'constants/value-color';
import { takeLast } from 'lodash/fp';
import { Moment } from 'moment';
import { roundSymptomValue } from 'utils/business';

export interface HistoricalTableData {
  value: number;
  date: Moment;
}

export interface HistoricalTableProps {
  data: HistoricalTableData[];
  maxCount?: number;
}

export const HistoricalTable: React.FC<HistoricalTableProps> = ({
  data,
  maxCount = 4
}) => (
  <div className="historical-table">
    {takeLast(maxCount, data)
      .reverse()
      .map((d, i) => (
        <HistoricalTableRow key={i} {...d} />
      ))}
  </div>
);

export interface HistoricalTableRowProps {
  value: number;
  date: moment.Moment;
}

const HistoricalTableRow: React.FC<HistoricalTableRowProps> = ({
  date,
  value
}) => (
  <div className="historical-table__row">
    <span>{date.format(longDateFormat)}</span>
    <span style={{ color: getValueColor(value) }}>
      {roundSymptomValue(value)}
    </span>
  </div>
);
