import { Moment } from 'moment';

import { Document } from './document';
import { ImpactLocation } from './patient-injury';

export enum Gender {
  Male = 'male',
  Female = 'female',
  Other = 'other'
}

export enum Ethnicity {
  Asian = 'asian',
  Black = 'black',
  Latino = 'latino',
  Indian = 'indian',
  Islander = 'islander',
  White = 'white',
  None = 'Did not want to say'
}

export enum Occupation {
  Student = 'student',
  Working = 'working',
  Unemployed = 'unemployed',
  Disability = 'disability',
  Retired = 'retired'
}

export enum OccupationType {
  PartTime = 'partTime',
  FullTime = 'fullTime'
}

export enum StudentLevel {
  Elementary = 'elementary',
  HighSchool = 'highSchool',
  University = 'university',
  Undergrad = 'undergrad',
  UniversityGraduate = 'universityGraduate'
}

export enum PatientProfileKey {
  birthDate = 'birthDate',
  sex = 'sex',
  genderId = 'genderId',
  primaryLanguage = 'primaryLanguage',
  secondaryLanguage = 'secondaryLanguage',
  ethnicity = 'ethnicity',
  occupation = 'occupation',
  occupationType = 'occupationType',
  studentLevel = 'studentLevel',
  occupationProf = 'occupationProf',
  englishFluency = 'englishFluency',
  concussionDiagnosis = 'concussionDiagnosis'
}

export enum EnglishFluency {
  NativeBilingual = 'Native or Bilingual Proficency',
  Intermediate = 'Intermediate Proficency',
  Novice = 'Novice Proficency'
}

export interface PatientProfile extends Document {
  birthDate?: Moment;
  sex?: Gender;
  genderId?: Gender;
  primaryLanguage?: string;
  secondaryLanguage?: string;
  ethnicity?: Ethnicity;
  occupation?: Occupation;
  occupationType?: OccupationType;
  studentLevel?: StudentLevel;
  occupationProf?: string;
  impactLocation?: ImpactLocation[];
}
