import { Skeleton } from 'antd';
import React, { forwardRef, useMemo } from 'react';

import './prescription-pad.scss';

import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import moment from 'moment';
import { getAssessmentRecommendation } from 'utils/get-assessment-recommendation';
import { PrescriptionPadDocument } from './prescription-pad-document';
import { PrescriptionPadData } from './types';

export const getTreatment = (
  patientInfo: PatientInfoState,
  category: keyof PatientInfoState['concussion']['Assessment Recommendations']
) => ({
  category: category.replace('Rx', '').trim(),
  items: getAssessmentRecommendation(patientInfo, category).map(item => ({
    name: item
  }))
});

export function useTreatmentData(patientInfo: PatientInfoState) {
  // console.log("PDFRender is being rendered");
  const data = useMemo(() => {
    if (patientInfo.isLoading || patientInfo.hasError) return null;

    const treatments = [
      getTreatment(patientInfo, 'Physiotherapy Rx'),
      getTreatment(patientInfo, 'Neuropsychology Rx'),
      getTreatment(patientInfo, 'Counseling Rx'),
      getTreatment(patientInfo, 'Occupational Therapy Rx')
    ];

    const baseData = {
      name: patientInfo.patient.displayName,
      age: patientInfo.patient.birthDate
        ? moment().diff(patientInfo.patient.birthDate, 'years').toString()
        : 'BIRTHDATE NOT AVAILABLE',
      date: moment().format('MMMM Do YYYY')
    };
    const arr = treatments.flatMap<PrescriptionPadData>(treatment => {
      /**
       * Split each item to its own page
       * https://trello.com/c/wce0KQgm/
       */
      return treatment.items.map(item => ({
        ...baseData,
        treatments: [
          {
            ...treatment,
            items: [item]
          }
        ]
      }));
    });

    // Add a prescription pad for the extra recommendations field
    if (patientInfo.concussion.extraRecommendations) {
      arr.push({
        ...baseData,
        extraContent: patientInfo.concussion.extraRecommendations,
        treatments: []
      });
    }
    return arr;
  }, [patientInfo]);

  return data;
}

export function PrescriptionPadExportContainer(props: {
  getRef: (ref: React.ReactNode) => void | any;
  patientInfo: PatientInfoState;
}) {
  const data = useTreatmentData(props.patientInfo);

  if (!data) return <Skeleton active />;

  return <PrintableDocument data={data} ref={ref => props.getRef(ref)} />;
}

/**
 * Private component
 *
 * This renders multiple prescription pad documents
 * @param props
 * @param ref
 * @returns
 */
function _PrintableDocument(
  props: { data: PrescriptionPadData[] },
  ref: React.LegacyRef<HTMLDivElement>
) {
  return (
    <div ref={ref}>
      {props.data.map((data, index) => (
        <PrescriptionPadDocument
          key={`treatment-recommendation-pad-${index}`}
          data={data}
        />
      ))}
    </div>
  );
}

const PrintableDocument = forwardRef(_PrintableDocument);
