import moment, { Moment } from 'moment';
import { handleError, handlePending, PageState, ThunkApi } from 'utils/redux';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTimestampedDocId } from 'utils/object';
import { Collection, PatientSubcollection, Inventory } from 'documents';

import { createRepo, Repo } from 'api/firebase';
import { SliderListItem } from 'components/slider-list';

import pcsJson from 'data/pcs.json';
import { PcsCategory, pcsPercentile } from 'documents/pcs';
import { savePendingAssessment } from 'features/patient/assessment/questionnaire/questionnaire-control-flow-slice';

export interface PcsInventory extends Inventory {
  description: string;
  instructions: string;
  min: number;
  max: number;
  beforeLabel: string;
  afterLabel: string;
  colorsForward: boolean;
  iconsForward: boolean;
}

export interface PcsState extends PageState {
  pcs: PcsInventory;
  invalidItems: string[];
  isSubmitting: boolean;
  lastUpdated?: Moment;
  showModal: boolean;
}

export const min = 0;
export const max = 6;
export const NOT_SELECTED = -1;
export const pcsOrder = pcsJson.questions.map(q => q.title);
// 'Please rate these questions from 0 (never) to 4 (always)',
//       'Everyone experiences painful situations at some point in their lives. Such experiences may include headaches, tooth pain, joint or muscle pain. People are often exposed to situations that may cause pain such as illness, injury, dental procedures or surgery. We are interested in the types of thoughts and feelings that you have when you are in pain. Listed below are the thirteen statements describing different thoughts and feelings that may be associated with pain. Using the following scale, please indicate the degree to which you have these thoughts and feelings when you are experiencing pain.',
function createNewPcs(): PcsInventory {
  return {
    date: moment(),
    data: pcsJson.questions.reduce((acc: Record<string, number>, question) => {
      acc[question.title] = NOT_SELECTED;
      return acc;
    }, {}),
    description: pcsJson.description,
    instructions: pcsJson.instructions,
    min: pcsJson.min,
    max: pcsJson.max,
    beforeLabel: pcsJson?.beforeLabel ? pcsJson.beforeLabel : 'Never',
    afterLabel: pcsJson?.afterLabel ? pcsJson.afterLabel : 'Always',
    colorsForward: pcsJson.colorsForward ?? true,
    iconsForward: pcsJson.iconsForward ?? true
  };
}
export const PCS_CATEGORIES = createCategories();
function createCategories(): Record<string, string[]> {
  return pcsJson.questions.reduce((acc: Record<string, string[]>, question) => {
    acc[question.title] = question.categories;
    return acc;
  }, {});
}

const initialState: PcsState = {
  hasError: false,
  pcs: createNewPcs(),
  invalidItems: [],
  isLoading: false,
  isSubmitting: false,
  showModal: false
};

let pcsRepo: Repo<Inventory>;
let _patientId: string;
const initialLoad = createAsyncThunk(
  'pcs/initialLoad',
  async (patientId: string) => {
    pcsRepo = createRepo<Inventory>([
      Collection.Patients,
      patientId,
      PatientSubcollection.InventoriesPcs
    ]);
    _patientId = patientId;

    return await pcsRepo.first({
      orderBy: ['date', 'desc']
    });
  }
);

const submit = createAsyncThunk<boolean, void, ThunkApi<PcsState>>(
  'pcs/submit',
  async (_, { getState, dispatch }) => {
    dispatch(pcsSlice.actions.validate());

    const { invalidItems } = getState();
    if (invalidItems.length) return false;

    dispatch(pcsSlice.actions.setPcsDate());
    dispatch(pcsSlice.actions.calculateCategories());

    const { pcs } = getState();

    await savePendingAssessment(
      _patientId,
      PatientSubcollection.InventoriesPcs,
      pcs
    );
    return true;
  }
);

const pcsSlice = createSlice({
  name: 'pcs',
  initialState,
  reducers: {
    setPcsDate: state => {
      const now = moment();
      state.pcs.id = getTimestampedDocId(now);
      state.pcs.date = now;
    },
    updatePcsItem: (state, { payload }: PayloadAction<SliderListItem>) => {
      const { title, value } = payload;
      state.invalidItems = state.invalidItems.filter(i => i !== title);
      state.pcs.data[title] = value;
      console.log('formatPcs updatePcsItem: ', title, state.pcs.data[title]);
    },
    validate: state => {
      state.invalidItems = Object.entries(state.pcs.data)
        .filter(([, value]) => value === NOT_SELECTED)
        .map(([key]) => key);
    },
    closeModal: state => {
      state.showModal = false;
    },
    calculateCategories: state => {
      for (const category of Object.values(PcsCategory)) {
        state.pcs.data[category] = 0;
      }

      Object.entries(state.pcs.data).forEach(([title, value]) => {
        if (PCS_CATEGORIES[title]) {
          for (const category of PCS_CATEGORIES[title]) {
            state.pcs.data[category] += value;
          }
        }
      });

      // add category for perecentile we don't really need this.
      state.pcs.data['percentile'] = 0;

      // get percentile based on Total score
      state.pcs.data['percentile'] = pcsPercentile(
        state.pcs.data['Total score'],
        PcsCategory.TOTAL
      );
    }
  },
  extraReducers: builder => {
    builder.addCase(initialLoad.pending, handlePending);
    builder.addCase(initialLoad.rejected, handleError);
    builder.addCase(initialLoad.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.lastUpdated = payload ? payload.date : undefined;
    });
    builder.addCase(submit.pending, state => {
      state.isSubmitting = true;
    });
    builder.addCase(submit.rejected, handleError);
    builder.addCase(submit.fulfilled, (state, { payload: isSaved }) => {
      state.isSubmitting = false;
      if (isSaved) {
        state.showModal = isSaved;
        state.lastUpdated = moment().utc(true);
        state.pcs = createNewPcs();
      }
    });
  }
});

export { initialState, initialLoad, submit };
export const { setPcsDate, updatePcsItem, closeModal, validate } =
  pcsSlice.actions;
export default pcsSlice.reducer;
export const PcsSliceReducer = pcsSlice.reducer;
