import { cloneDeep } from 'lodash';

import { Inventory } from 'documents/inventory';
import { FormattedSection } from '../paginationSlice';
import {
  initialState,
  PromisSliceReducer,
  PROMIS_SECTIONS,
  submit,
  updatePromisItem,
  validate
} from './promisSlice';

import { splitChunks } from 'utils/splitChunks';
import {
  QuestionnairePaginationCombinedGeneralProps,
  PagedQuestionnaire
} from '../../assessment/paged-questionnaire';
import { PromisBySectionQuestions } from './promisTypes';

export function PaginationCombinedPromis(
  props: Pick<
    QuestionnairePaginationCombinedGeneralProps<any>,
    'onPageChange' | 'onSectionChange'
  > & { storageKey: string }
) {
  return (
    <PagedQuestionnaire
      reducer={PromisSliceReducer}
      actions={{
        updateItem: updatePromisItem,
        formatData,
        submit,
        validate
      }}
      getData={state => state.promis}
      initialState={initialState}
      pageTitle={section => PROMIS_SECTIONS[section].instructions ?? ''}
      onSubmitExtraNavigationProps={{
        questionnaireName: 'promis'
      }}
      {...props}
    />
  );
}

/**
 * Gets promis question data
 * @param questions
 * @param promis
 * @returns
 */
export function getItemsBySection(
  questions: PromisBySectionQuestions[],
  data: Inventory['data']
): PromisBySectionQuestions[] {
  if (!questions) return [];
  return questions.map(q => ({
    title: q.title,
    value: data[q.title],
    id: q.id,
    section: '',
    categories: ['']
  }));
}

function formatData(inData: Inventory, pageLimit: number): FormattedSection[] {
  const outData = cloneDeep(PROMIS_SECTIONS);
  for (const element of outData) {
    element.questions = getItemsBySection(element.questions, inData.data);

    const anyRef = element as typeof element & {
      page: PromisBySectionQuestions[][];
      pageLength: number;
    };
    anyRef.page = splitChunks(element.questions, pageLimit);
    anyRef.pageLength = anyRef.page.length;
  }

  return outData as any;
}
