import { verifyEmail } from 'api/firebase';
import firebase from 'firebase/compat/app';
import { AppThunk } from 'app/store';
import { AuthSliceActions } from './authSlice';

export const getVerifyEmailRedirectUrl = () => `${window.location.origin}`;
export async function _sendVerification(user: firebase.User | null) {
  if (user === null) {
    console.log('No user exists: ', user);
    throw new Error('Not authorized');
  }

  try {
    await verifyEmail(getVerifyEmailRedirectUrl());
  } catch (error) {
    console.log('Failed to invoke verifyEmail. Using fallback', error);
    // Fallback to the firebase email verification the verifyEmail API does not work
    await user.sendEmailVerification({
      url: getVerifyEmailRedirectUrl()
    });
  }

  console.log('success sending email verification');
}

export const sendVerification =
  (user: firebase.User | null): AppThunk =>
  async dispatch => {
    try {
      await _sendVerification(user);
    } catch (error) {
      console.log(
        'Something went wrong with sending the user an email verification',
        error
      );
      dispatch(
        AuthSliceActions.setError(
          `An error occurred with your email verification. ${error}`
        )
      );
    }
  };
