import promisQuestions from 'data/promis.json';
import moment from 'moment';
import { SymptomCardData } from '../../dashboard/symptom-card';

import { TestRecord } from 'api/impact/types';
import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import { Inventory } from 'documents/inventory';
import {
  tScoreCategory,
  tScoreMapping,
  TScores
} from 'documents/promisTScores';

export const generateSampleData = (scale: number[]): SymptomCardData[] => {
  const min = Math.min(...scale);
  const max = Math.max(...scale);
  return new Array(20).fill(undefined).map((_, index) => ({
    date: moment().subtract(index, 'months'),
    value: min + Math.random() * (max - min)
  }));
};

export interface BrainFunctionVisualisation {
  /**
   * The label of the visualisation
   */
  label: string;
  /**
   * Callback to fetch the data
   */
  getValues: (
    args: Pick<PatientInfoState, 'dhi' | 'promis' | 'impact'>
  ) => SymptomCardData[];
  scale: number[];
  isRawScore?: boolean;
}

interface BrainFunctionCategory {
  /**
   * The category title
   */
  title: string;
  /**
   * The visualisations in the category
   */
  visualisations: BrainFunctionVisualisation[];
}

type PromisSubsections = keyof typeof promisQuestions.subsections;

/**
 * Curried getPromisTotalScore
 * @param subsections
 * @returns
 */
const promisCurry =
  (subsections: (keyof typeof tScoreMapping)[], category: tScoreCategory) =>
  (
    args: Parameters<BrainFunctionVisualisation['getValues']>[0]
  ): SymptomCardData[] =>
    args.promis.map(value => ({
      date: value.date,
      value: subsections
        .map(subsection =>
          value.data[subsection] === null
            ? 0
            : TScores.findTScore(value.data[subsection], category, 0)
        )
        .reduce((acc, cur) => acc + cur, 0)
    }));

/**
 * Helper method to get a specified score from a list of
 * ImPACT `TestRecord`'s transformed into `Inventory`'s
 */
const impactCurry =
  (
    query: keyof Pick<
      TestRecord,
      | 'userMemoryCompositeScoreVerbal'
      | 'userMemoryCompositeScoreVisual'
      | 'userImpulseControlCompositeScore'
      | 'userReactionTimeCompositeScore'
      | 'userVisualMotorCompositeScore'
    >
  ) =>
  (
    args: Parameters<BrainFunctionVisualisation['getValues']>[0]
  ): SymptomCardData[] =>
    args.impact.map(test => ({
      date: test.date,
      value: test.data[query]
    }));

export const brainFunctionCategories: BrainFunctionCategory[] = [
  {
    title: 'Balance and movement',
    visualisations: [
      {
        label: 'Dizziness',
        /**
         * Total score of all the DHI questions
         * There are 25 questions and the scale is 0-2.
         * The total is multiplied by 2 to bring the total to 100
         */
        getValues: ({ dhi }) =>
          dhi.map(val => ({ date: val.date, value: val.data['Total'] })),
        scale: [0, 15, 25, 100],
        isRawScore: true
      },
      {
        label: 'Physical Function',
        /**
         * T-score of the physical function questions in PROMIS - the first 8 questions
         * The physical mobility section includes "physical function" and "health"
         */
        getValues: param => {
          return promisCurry(
            ['Physical Function'],
            tScoreCategory.PhysicalFunction
          )(param);
        },
        scale: [100, 60, 40, 0]
      }
    ]
  },
  {
    title: 'Pain and sleep',
    visualisations: [
      {
        label: 'Pain',
        /**
         * T-score of the pain questions in PROMIS - 7th set of question (9 questions)
         */
        getValues: promisCurry(
          ['Pain Interferance'],
          tScoreCategory.PainInterferance
        ),
        scale: [0, 40, 60, 100]
      },
      {
        label: 'Sleep Disturbance',
        /**
         * T-score of the sleep disturbance questions in PROMIS - 5th set of question (8 questions)
         */
        getValues: promisCurry(
          ['Sleep Disturbance'],
          tScoreCategory.SleepDisturbance
        ),
        scale: [0, 40, 60, 100]
      }
    ]
  },
  {
    title: 'Cognition and memory',
    visualisations: [
      {
        label: 'Verbal memory',
        /**
         * ImPact raw score
         */
        getValues: impactCurry('userMemoryCompositeScoreVerbal'),
        scale: [100, 95, 85, 0],
        isRawScore: true
      },
      {
        label: 'Cognitive Function',
        /**
         * T-score of the cognitive function abilities questions in PROMIS - 9th set of question (8 questions)
         */
        getValues: promisCurry(
          [
            'Cognitive Function Abilities',
            /**
             * Temporarily include the `Cognitive Func. Ab.` key for legacy patients
             *
             * https://trello.com/c/O88YnB44
             */
            'Cognitive Func. Ab.' as any
          ],
          tScoreCategory.CognitiveFunctionAbilities
        ),
        scale: [100, 60, 40, 0]
      },
      {
        label: 'Reaction Time',
        /**
         * ImPact raw score
         */
        getValues: impactCurry('userReactionTimeCompositeScore'),
        scale: [0, 0.6, 0.7, 1],
        isRawScore: true
      },
      {
        label: 'Visual Motor Processing Speed',
        /**
         * ImPact raw score
         */
        getValues: (...args) =>
          /* 2024-06-21 Visual Motor Processing Speed should be rounded to the nearest integer */
          impactCurry('userVisualMotorCompositeScore')(...args).map(val => ({
            ...val,
            value: Math.round(val.value)
          })),
        scale: [100, 42, 37, 0],
        isRawScore: true
      }
    ]
  },
  {
    title: 'Mood and social function',
    visualisations: [
      {
        label: 'Fatigue',
        /**
         * T-score of the fatigue questions in PROMIS - 4th set of question (8 questions)
         */
        getValues: promisCurry(['Fatigue'], tScoreCategory.Fatigue),
        scale: [0, 40, 60, 100]
      },
      {
        label: 'Anxiety',
        /**
         * T-score of the anxiety questions in PROMIS - 2nd set of question (8 questions)
         */
        getValues: promisCurry(['Anxiety'], tScoreCategory.Anxiety),
        scale: [0, 40, 60, 100]
      },
      {
        label: 'Depression',
        /**
         * T-score of the depression questions in PROMIS - 3rd set of question (8 questions)
         */
        getValues: promisCurry(['Depression'], tScoreCategory.Depression),
        scale: [0, 40, 60, 100]
      },
      {
        label: 'Social Involvement',
        /**
         * T-score of the Ability to Participate in Social Roles and Activities questions in PROMIS - 6th set of question (8 questions)
         */
        getValues: promisCurry(
          ['Abilities to participate in social roles and activities'],
          tScoreCategory.AbilityToParticipateInSocialRolesAndActivities
        ),
        scale: [100, 60, 40, 0]
      }
    ]
  }
];

/**
 * Extract the visualisations from the categories list
 */
export const brainFunctionVisualisations = brainFunctionCategories.flatMap(
  category => category.visualisations
);

// eslint-disable-next-line
function getPromisTotalScore(
  promis: PatientInfoState['promis'],
  subsections: PromisSubsections[]
) {
  // Resolve the questions to list
  const questions = subsections.flatMap(getPromisQuestions);
  return getInventoryTotalScore(promis, () => questions);
}

/**
 * Get the question titles of a promis subsection
 * @param subsectionTitle
 * @returns
 */
const getPromisQuestions = (subsectionTitle: PromisSubsections) =>
  // Get the subsection
  promisQuestions.subsections[subsectionTitle].questions
    // Get the question titles
    .map(question => question.title);

/**
 *
 * @param inventoryList
 * @param getQuestions
 * @returns
 */
function getInventoryTotalScore(
  inventoryList: Inventory[],
  getQuestions: (inventory: Inventory) => string[]
) {
  return inventoryList.map(inventory => {
    const questions = getQuestions(inventory);

    /**
     * Remove questions that are not keys in `inventory.data`
     * Assumes the key in `inventory.data` is the question title
     */
    const filteredQuestions = questions.filter(
      question => question in inventory.data
    );

    return {
      date: inventory.date,
      value: filteredQuestions.reduce(
        (acc, question) => acc + inventory.data[question],
        0
      )
    };
  });
}
