import { getFirstName, getLastName } from 'utils/string/normalise';

/**
 * Split a fullname into a firstname and lastname keypair.
 * If a lastname is not available then only the first name will be used
 * @param name A full name
 * @returns
 */
export function getName(name: string | undefined) {
  if (!name) return;
  // Split the name by space
  const namesplit = name.trim();
  if (namesplit.length >= 2) {
    return {
      firstName: getFirstName(namesplit),
      lastName: getLastName(namesplit)
    };
  } else {
    return { firstName: namesplit[0] };
  }
}
