import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Popover,
  Spin,
  Typography
} from 'antd';
import { CreateAccountArgs } from 'features/auth/auth-action-create-account';
import { setUserPassword } from 'features/auth/auth-action-set-user-password';
import { AuthSliceActions } from 'features/auth/authSlice';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gatherAllValidations, validatePassword } from 'utils/validators';
import { ConcussionRXLogo } from './concussionRx-logo';
import { ErrorMessage } from './page-sign-in';
import { styles } from './styles';

import { LoadingOutlined } from '@ant-design/icons';
import {
  createRepo,
  getClinicBasicInfo,
  requestPasswordResetCode
} from 'api/firebase';
import { useAppSelector } from 'app/rootReducer';
import { BaseHeader } from 'components/header';
import { Clinician } from 'documents/clinician';
import { Collection } from 'documents/document';
import { Patient } from 'documents/patient';
import { Role } from 'features/auth/types';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { useDispatch } from 'react-redux';
import { resolveError } from 'utils/formatters/error/resolve-error';

export interface CreateFormTypes
  extends Required<
    Pick<CreateAccountArgs, 'email' | 'password' | 'clinicName' | 'name'>
  > {
  confirm: string;
}

interface CreateAccountState {
  isLoading: boolean;
  showPopoverError: boolean;
  error: Error | null;
  invitationError: Error | null;
  invitation: {
    clinicName: string;
    displayName: string;
    email: string;
  } | null;
}

/**
 * The new user registration form
 */
export function CreateAccountForm() {
  const [consent, setConsent] = useState(false);
  const [password, setPassword] = useState('');

  const [formState, setFormState] = useState<CreateAccountState>({
    isLoading: true,
    showPopoverError: false,
    invitationError: null,
    invitation: null,
    error: null
  });

  const user = useAppSelector(state => state.auth.user);
  const isUserDataLoading = useAppSelector(
    state => state.auth.isUserDataLoading
  );
  const isAuthActionActive = useAppSelector(
    state => state.auth.isAuthActionActive
  );
  const authSliceError = useAppSelector(state => state.auth.error);

  const dispatch = useDispatch();

  useEffect(() => {
    async function setup() {
      if (user === null) {
        return;
      }

      const { displayName, email } = user;
      if (!displayName) {
        throw new Error(
          `User (uid='${user.uid}') does not have a display name`
        );
      }

      if (!email) {
        throw new Error(`User (uid='${user.uid}') does not have an email`);
      }

      const userDataRepo = createRepo<Patient | Clinician>(
        [Role.ClinicAdmin, Role.Clinician].includes(user.role)
          ? Collection.Clinicians
          : Collection.Patients
      );
      const userData = await userDataRepo.find(user.uid);
      if (!userData) {
        throw new Error(
          `No user data for user (id=${user.uid}) found. Please report this error to your clinic`
        );
      }

      const clinics = userData.clinics;
      if (!clinics) {
        throw new Error(
          `User (id=${user.uid}) is not associated with a clinic. Please report this error to your clinic`
        );
      }

      const clinicInfo = await getClinicBasicInfo(clinics[0]);

      setFormState(s => ({
        ...s,
        invitation: {
          clinicName: clinicInfo.data.name,
          displayName: displayName,
          email: email
        },
        isLoading: false
      }));
    }

    setup().catch(error => {
      setFormState(s => ({
        ...s,
        isLoading: false,
        invitationError: resolveError(error)
      }));
    });
  }, [user]);

  async function onFinish(values: CreateFormTypes) {
    if (!formState.invitation) {
      setFormState(state => ({
        ...state,
        isLoading: false,
        invitationError: new Error(
          'Form was submitted before the invitation details were loaded'
        )
      }));
      return;
    }

    const { password } = values;
    dispatch(AuthSliceActions.startLoadingAction());
    const passwordResetCode = await requestPasswordResetCode();
    dispatch(setUserPassword(passwordResetCode.data, password));
  }

  const loading =
    formState.isLoading || isUserDataLoading || isAuthActionActive;
  if (loading) {
    return (
      <Flex justify={'center'} align="center">
        <div style={styles.container}>
          <Form key={'loading'}>
            <Flex justify="center">
              <ConcussionRXLogo />
            </Flex>
            <Flex
              justify={'center'}
              align={'center'}
              style={{ height: '100vh' }}
            >
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: '3rem' }}
                    spin={loading}
                  />
                }
              />
            </Flex>
          </Form>
        </div>
      </Flex>
    );
  }

  if (formState.invitationError || formState.invitation === null) {
    return (
      <Flex justify={'center'} align={'center'} flex={1}>
        <div style={styles.container}>
          <Form
            key="error display"
            onFinish={onFinish}
            initialValues={{
              remember: true
            }}
            className="login-form"
            style={styles.form}
            labelWrap={true}
          >
            <Flex style={styles.titleContainer} justify="center">
              <ConcussionRXLogo />
            </Flex>

            {formState.invitationError ? (
              <ErrorDisplay
                title="Invitation error"
                description="Please contact your clinic to request a new invitation"
                error={formState.invitationError}
              />
            ) : (
              <ErrorDisplay
                title="Invalid state"
                description="No invitation error was detected but the invitation details are not available"
                error={new Error()}
              />
            )}
          </Form>
        </div>
      </Flex>
    );
  }

  return (
    <Flex justify={'center'} align={'center'} flex={1}>
      <div style={styles.container}>
        <Form
          onFinish={onFinish}
          name="create"
          initialValues={{
            remember: true,
            email: formState.invitation?.email,
            name: formState.invitation?.displayName,
            clinicName: formState.invitation.clinicName
          }}
          className="login-form"
          style={styles.form}
          labelWrap={true}
        >
          <Flex style={styles.titleContainer} justify="center">
            <ConcussionRXLogo />
          </Flex>

          {formState.error && (
            <ErrorDisplay
              title="Something went wrong"
              description=""
              error={formState.error}
            />
          )}

          {/* Email input */}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email'
              },
              {
                type: 'email',
                message: 'Please enter a valid email'
              }
            ]}
          >
            <Input disabled placeholder="Email" className="account_form_item" />
          </Form.Item>

          {/* Users name input */}
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter your name'
              }
            ]}
          >
            <Input disabled placeholder="Name" className="account_form_item" />
          </Form.Item>

          {/* Password input and a popover wrapper to display errors */}
          <Popover
            placement={'bottom'}
            open={formState.showPopoverError}
            content={<RenderPasswordValidations password={password} />}
          >
            <Form.Item
              name="password"
              rules={[
                // Ensure the password is set
                {
                  required: true,
                  message: 'Please input your password'
                },
                // If the password is set then validate the password against the password requirements
                () => ({
                  validator(_, value) {
                    // console.log("value: ", value, _, getFieldValue("password"), 'password: ', password, validatePassword(value));
                    setPassword(value);
                    if (!value || validatePassword(value)) {
                      setFormState(s => ({ ...s, showPopoverError: false }));
                    } else {
                      // there are errors
                      if (value) {
                        setFormState(s => ({ ...s, showPopoverError: true }));
                      }
                    }
                    // console.log("validatePassword: ", validatePassword(value), value);
                    if (value && validatePassword(value)) {
                      return Promise.resolve();
                    }

                    return Promise.reject();
                  }
                })
              ]}
              hasFeedback
            >
              <Input.Password
                type="password"
                placeholder="Input password"
                className="account_form_item"
              />
            </Form.Item>
          </Popover>

          {/* Confirm password input */}
          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              // The second password is required
              {
                required: true,
                message: 'Please confirm your password'
              },
              // If the second password is set then validate that it is the same as the original password
              ({ getFieldValue }) => ({
                validator(_, value) {
                  setFormState(s => ({ ...s, showPopoverError: false }));
                  if (
                    !value ||
                    (getFieldValue('password') === value &&
                      getFieldValue('password'))
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The new password you entered do not match')
                  );
                }
              })
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Confirm Password"
              className="account_form_item"
            />
          </Form.Item>

          <Form.Item
            name={'clinicName'}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              disabled
              placeholder="Clinic Name"
              className="account_form_item"
            />
          </Form.Item>

          {/* Accept the TOC and Privacy Policy */}
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator(_, value) {
                  if (value) return Promise.resolve();
                  return Promise.reject(
                    new Error('Please accept the agreement')
                  );
                }
              }
            ]}
          >
            <div className="checkbox-consent-group">
              <Checkbox
                // default antd checkbox style is overridden in scss file
                onChange={() => setConsent(!consent)}
              ></Checkbox>
              <Typography.Text>
                I accept ConcussionRx {TOS} and {Privacy}.
              </Typography.Text>
            </div>
          </Form.Item>

          {/* Navigate to the next registration step */}
          <Form.Item>
            <Flex justify="center">
              <Button
                disabled={formState.error !== null}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={styles.button}
              >
                Create account
              </Button>
            </Flex>
          </Form.Item>
          {authSliceError && <ErrorMessage errorMessage={authSliceError} />}
        </Form>
      </div>
    </Flex>
  );
}

/**
 * Wrapper for the create account form
 */
export function CreateAccount() {
  return (
    <>
      <BaseHeader headerStyle={{ background: 'none' }} />
      <CreateAccountForm />
    </>
  );
}

const TOS = (
  <Link style={{ color: '#230056' }} to="/terms-of-service">
    Terms of Service
  </Link>
);

const Privacy = (
  <Link style={{ color: '#230056' }} to="/privacy-policy">
    Privacy Policy
  </Link>
);

function RenderPasswordValidations(props: { password: string }) {
  const validations = gatherAllValidations(props.password);
  return (
    <div>
      <p>Password must include: </p>
      {validations.map((valid, i) => (
        <p
          className={
            valid.result ? 'correct-validation' : 'incorrect-validation'
          }
          key={i}
        >
          {valid.result ? (
            <CheckOutlined className="validation-icon" />
          ) : (
            <CloseOutlined className="validation-icon" />
          )}{' '}
          {valid.title} {valid.result}
        </p>
      ))}
    </div>
  );
}
