import { calculateSegmentIndex } from 'components/gauge/calculateSegmentIndex';
import { Gauge2Props } from 'components/gauge/gauge-2';
import { restrictValueToScale } from 'components/gauge/restrictValueToScale';
import { useMemo } from 'react';
import { SymptomCardData } from './SymptomCard';
import { BarChart, BarChartData } from 'components/barchart';
import { percentageBetween } from 'utils/math/normalise';

/**
 * Display a bar graph of a brain function metric over a period of months
 * @param props
 * @returns
 */
export function BrainFunctionBarChart(props: {
  maxAssessments: number;
  data: SymptomCardData[];
  scale: Gauge2Props['scale'];
  colors: Gauge2Props['colors'];
  showLabels?: boolean;
  showValues?: boolean;
  width?: number;
  barThickness: number;
}) {
  // Find the max value
  const maxValue = Math.max(...props.scale);
  const minValue = Math.min(...props.scale);

  const data: BarChartData[] = useMemo(() => {
    const dataLastMonths = collectDataInLastNMonths(
      props.maxAssessments,
      props.data
    );

    return dataLastMonths.map(avg => {
      const val = restrictValueToScale(avg.value, props.scale);
      let colorIndex = calculateSegmentIndex(props.scale, val);

      /**
       * Partial implementation of colour interpolation for the last segment
       * This is used until full colour interpolation is added
       */
      if (
        /* At the time of writing there are 3 categories, which means 3+1 scale values */
        props.scale.length === 4 &&
        props.colors.length === 4 &&
        colorIndex === 2
      ) {
        // Is the value in the bottom or top 50% of the last segment?
        const percentage = percentageBetween(
          val,
          props.scale[2],
          props.scale[3]
        );
        if (percentage >= 0.5) {
          colorIndex += 1;
        }
      }

      return {
        fill: props.colors[colorIndex] ?? 'black',
        value: val,
        label: props.showLabels ? avg.date.format('MMM Do') : '',
        showValue: props.showValues
      };
    });
  }, [
    props.data,
    props.maxAssessments,
    props.colors,
    props.scale,
    props.showLabels,
    props.showValues
  ]);

  return (
    <BarChart
      title={`Last ${
        data.length > 1 ? `${data.length} assessments` : 'assessment'
      }`}
      data={data}
      barThickness={props.barThickness}
      width={props.width}
      max={maxValue}
      min={minValue}
    />
  );
}

/**
 * Helper method to collect the last N assessments, excluding the latest assessment
 *
 * @param maxAssessments The maximum number of assessments to query
 * @param data The sorted data to query
 */
function collectDataInLastNMonths(
  maxAssessments: number,
  data: SymptomCardData[]
) {
  const lastNAssessments: SymptomCardData[] = [];
  if (data.length === 0) return [];

  // In the smallest case the second-last element is the last element to be added
  const lastAvailableIndex = data.length;

  // Calculate the real maximum assessments that can be made
  const assessments = Math.min(lastAvailableIndex, maxAssessments);
  const startIndex = lastAvailableIndex - assessments;
  for (let i = 0; i < assessments; i++) {
    lastNAssessments.push(data[startIndex + i]);
  }

  return lastNAssessments;
}
