/**
 * Splits an array of questions into chunks of size `pageLimit`
 * @param inputArray
 * @param chunkSize
 * @returns
 */
export function splitChunks<T>(inputArray: T[], chunkSize: number) {
  const formattedChunks = [];
  let chunkCollector = [];

  for (let i = 0; i < inputArray.length; i++) {
    chunkCollector.push(inputArray[i]);
    // If the length of the collector equals the page limit, or if the current index is at the end of the array
    if (chunkCollector.length == chunkSize || i == inputArray.length - 1) {
      // we reached chunk limit
      formattedChunks.push([...chunkCollector]); // clone just to be safe from reference erasing
      chunkCollector = [];
    }
  }

  return formattedChunks;
}
