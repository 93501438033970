import { Col, Flex, Row, Space, Typography } from 'antd';
import { PatientInfoContext } from 'components/patient-info/hook-context-patientinfo';
import { CriticalAreasCard } from 'features/patient/patient-summary/cards/card-critical-areas';
import { useCreatePatientUrl } from 'hooks/use-create-patient-url';
import { useContext } from 'react';
import { PatientRoutes } from 'routes';
import { ActionButtonLink } from 'components/card/card-action-button-link';
import { StyledConcussionComplexity } from '../styled-concussion-complexity';
import { ConcussionComplexityDiagramWrapper } from 'features/patient/patient-summary/cards/concussion-complexity-diagram';
import { halfCol } from 'utils/antd';
import { getFirstName } from 'utils/string/normalise';

export function ConcussionSummary() {
  const { patientInfo } = useContext(PatientInfoContext);
  const createPatientUrl = useCreatePatientUrl();
  const firstName = getFirstName(patientInfo.patient.displayName);
  return (
    <Space
      direction="vertical"
      style={{ /* Fill the column width */ width: '100%' }}
      size={'large'}
    >
      <Space direction="vertical">
        <Typography.Title level={2} style={{ color: '#230056' }}>
          ConcussionRx Summary
        </Typography.Title>
        <Typography.Text>
          Help {firstName} feel clarity, hope, validation, and control in their
          prognosis with over 99% accurate subtype diagnosis
        </Typography.Text>
      </Space>
      <Row>
        <Col {...halfCol}>
          <Flex align={'center'}>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              Concussion subtype
            </Typography.Title>

            <StyledConcussionComplexity
              style={{ marginLeft: '1rem' }}
              complexity={patientInfo.concussion.subtype}
            />
          </Flex>

          <CriticalAreasCard noTitle />
        </Col>
        <Col {...halfCol}>
          <Flex
            align={'center'}
            justify="center"
            style={{ height: '100%', marginTop: '1rem' }}
          >
            <ConcussionComplexityDiagramWrapper
              concussion={patientInfo.concussion}
            />
          </Flex>
        </Col>
      </Row>
      <Flex align="center">
        <div style={{ flex: 1 }}></div>
      </Flex>
      <ActionButtonLink to={createPatientUrl(PatientRoutes.PatientDashboard)}>
        View details
      </ActionButtonLink>
    </Space>
  );
}
