import { Alert, Layout } from 'antd';

import { QuestionnaireHeader } from 'components/header';

import './Questionnaire.scss';

import { BigDarkButton } from 'components/big-dark-button';
import { Text } from 'components/mvp-typography';
import { QuestionnaireDataProps } from './types';
import { useState } from 'react';
import { ErrorDisplay } from './ErrorDisplay';

export interface QuestionnaireProps {
  data?: QuestionnaireDataProps;
  startQuestionnaire?: () => any;
  assessmentTypeDisplayName?: string;
}

export function QuestionnaireIntro({ data, ...props }: QuestionnaireProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  if (!data) {
    return (
      <Alert type="error">
        <Text>
          This questionnaire has no data prop. Screenshot this page and send a
          report to the ConcussionRX.
        </Text>
      </Alert>
    );
  }

  return (
    <Layout className="layout-container">
      <QuestionnaireHeader title={data.buttonTitle} />
      <div className="questionnaire-content">
        <QuestionnaireImage imgLogo={data.imgLogo} />

        <div className="questionnaire-content-text">
          <h3 className="questionnaire-content-text__subtitle crx-typography h3">
            {data.subtitle}
          </h3>
          <h1 className="questionnaire-content-text__title crx-typography h1">
            {data.title}
          </h1>
          <p className="questionnaire-content-text__description crx-typography body-normal">
            {data.description}
          </p>
          <BigDarkButton
            fontSize={20}
            minHeight={60}
            loading={isLoading}
            disabled={!props.startQuestionnaire}
            onClick={async () => {
              const fn = props.startQuestionnaire ?? (() => !!0);
              setIsLoading(true);
              setError(null);
              try {
                await fn();
              } catch (error) {
                setError(error as Error);
              } finally {
                setIsLoading(false);
              }
            }}
            label={`Start ${
              props.assessmentTypeDisplayName ?? 'Questionnaire'
            } (${data.duration})`}
          />
          {error && (
            <ErrorDisplay
              title="Error when starting the questionnaire"
              description="In the meantime your progress has been saved."
              error={error}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}

export function QuestionnaireImage(props: {
  imgLogo: QuestionnaireDataProps['imgLogo'];
}) {
  return <img src={props.imgLogo} className="questionnaire-content__image" />;
}
