import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Skeleton } from 'antd';
import { getPatientRouteUrl, PatientRoutes, Routes } from 'routes';

import { useAppSelector } from 'app/rootReducer';
import { Role } from 'features/auth/types';

export interface PrivateRouteProps
  extends Omit<RouteProps, 'children' | 'render'> {
  roles: Role[] | '*';
  redirectPath?: string;
  passwordCheck?: boolean;
}

export function PrivateRoute({
  component: Component,
  roles,
  passwordCheck,
  ...rest
}: PrivateRouteProps) {
  const user = useAppSelector(state => state.auth.user);
  const isLoading = useAppSelector(state => state.auth.isUserDataLoading);

  if (!Component) return null;

  if (!user && isLoading) return <Skeleton active={true} />;

  return (
    <Route
      {...rest}
      render={renderProps => {
        /**
         * The user role matches one of the accepted roles
         */
        if (user && (roles === '*' || roles.includes(user.role))) {
          /**
           * If the route is only available when the password is set and the user has their password set
           */
          if (!passwordCheck || (passwordCheck && !user.needsPassword)) {
            return <Component {...renderProps} />;
          }
        }

        /**
         * Immediately redirect users to the redirectPath if possible
         */
        if (rest.redirectPath) {
          return <Redirect to={rest.redirectPath} />;
        }

        if (!user) {
          return (
            <Redirect
              to={{
                pathname: getPatientRouteUrl(PatientRoutes.PatientSignIn),
                state: { from: renderProps.location }
              }}
            />
          );
        }

        const pathname = [Role.Clinician, Role.ClinicAdmin].includes(user.role)
          ? Routes.ClinicianSignIn
          : getPatientRouteUrl(PatientRoutes.PatientSignIn);
        return (
          <Redirect
            to={{
              pathname,
              state: { from: renderProps.location }
            }}
          />
        );
      }}
    />
  );
}
