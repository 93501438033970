import { replace, toPairs } from 'lodash';
export const PatientRoutePrefix = '/patient';
export const ClinicianRoutePrefix = '/clinician';

export enum PatientRoutes {
  PatientSignIn = '/sign-in',
  PatientOnboarding = '/onboarding',
  PatientOnboardingProfile = '/onboarding/profile',
  PatientHome = '/home',
  PatientDemographics = '/demographics',
  PatientHowAreYouTracker = '/how-are-you',
  PatientDemographicsProfile = '/demographics/profile',
  PatientDemographicsInjury = '/demographics/injury',
  PatientDemographicsPersonalHistory = '/demographics/personal-history',
  PatientSymptom = '/symptom/:symptom',
  PatientSsoInfo = '/symptom/:symptom/info',
  PatientCgs = '/outcome-measures/cgs',
  PatientOutcomeMeasures = '/outcome-measures',
  PatientDhi = '/outcome-measures/dhi',
  PatientPcs = '/outcome-measures/pcs',
  PatientPromis = '/outcome-measures/promis',
  PatientOutcomeProgress = '/outcome-measures/progress',
  PatientCreateAccount = '/create_account',
  PatientDashboard = '/dashboard',
  CompletionPromis = '/completion/promis',
  Completion = '/completion/all',
  ImpactAPI = '/impactAPI',
  PatientTreatmentRecommendations = '/treatment-recommendations',
  PatientTreatmentRecommendationsV2 = '/treatment-recommendations-v2',
  PatientTreatmentRecommendationsExportPDF = '/treatment-recommendations/exportPDF'
}

export const getPatientRouteUrl = (route: PatientRoutes) =>
  PatientRoutePrefix + route;

export enum ClinicRoutes {
  ClinicUserManagement = '/clinician/users',
  ClinicInfoEdit = '/clinician/clinic/info/edit'
}

export enum Routes {
  ClinicianSignIn = '/clinician/sign-in',
  ClinicianHome = '/clinician/home',
  ClinicianPatientList = '/clinician/patients',
  ClinicianAssessment = '/clinician/patient/:patientId/assessment',
  ClinicianPatientChart = '/clinician/patient/:patientId/chart',
  ClinicianPatientDashboard = '/clinician/patient/:patientId/dashboard',
  ClinicianPatientEducationalArticles = '/clinician/patient/:patientId/educational-articles',
  ClinicianPatientExercisesLibrary = '/clinician/patient/:patientId/exercises-library',
  ClinicianPatientGoalsLibrary = '/clinician/patient/:patientId/goals-library',
  ClinicianPatientProgress = '/clinician/patient/:patientId/progress',
  ClinicianPatientProtocolsAndProgressions = '/clinician/patient/:patientId/protocols-and-progressions',
  ClinicianPatientSymptoms = '/clinician/patient/:patientId/symptoms',
  ClinicianPatientSymptom = '/clinician/patient/:patientId/symptom/:symptom',
  ClinicianPatientHistory = '/clinician/patient/:patientId/history',
  ClinicianPatientInjury = '/clinician/patient/:patientId/injury',
  ClinicianPatientProfile = '/clinician/patient/:patientId/profile',
  ClinicianRecoveryRx = '/clinician/patient/:patientId/recovery-rx',
  ClinicianTreatmentPlanning = '/clinician/patient/:patientId/treatment-planning',
  ClinicianTreatmentPlanningGoals = '/clinician/patient/:patientId/treatment-planning/goals',

  ClinicianPatientAssessments = '/clinician/patient-assessments',

  ClinicianCreateAccount = '/clinician/create_account',
  VerifyEmail = '/account/verify',
  ForgotPassword = '/password/reset',
  TermsOfService = '/terms-of-service',
  PrivacyPolicy = '/privacy-policy',
  AssessmentIntro = '/patient/assessment/intro',
  Questionnaire = '/patient/questionnaire',
  QuestionnaireFlow = '/patient/questionnaireFlow',
  SignInSuffix = '/sign-in',
  CreateAccountSuffix = '/create-account'
}

export const buildUrl = (route: string, params: Record<string, any>) =>
  toPairs(params).reduce(
    (route, [key, value]) =>
      replace(route, new RegExp(`/:(${key})(/|$)`, 'g'), `/${value}$2`),
    route as string
  );

export interface ClinicianQueryParams {
  patientId: string;
}

export interface SymptomQueryParams {
  symptom: string;
}
