import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collection, Inventory, PatientSubcollection } from 'documents';
import moment, { Moment } from 'moment';
import { getTimestampedDocId } from 'utils/object';
import { handleError, handlePending, PageState, ThunkApi } from 'utils/redux';

import { createRepo, Repo } from 'api/firebase';

import { SliderListItem } from 'components/slider-list';

// import cgsOrder from './cgs-order.json';

import CgsJson from 'data/cgs.json';
import { PcsInventory } from '../pcs/pcsSlice';
import { savePendingAssessment } from 'features/patient/assessment/questionnaire/questionnaire-control-flow-slice';

// eslint-disable-next-line
export interface CgsInventory extends PcsInventory {
  type: string;
}

export interface CgsState extends PageState {
  cgs: CgsInventory;
  invalidItems: string[];
  isSubmitting: boolean;
  lastUpdated?: Moment;
  showModal: boolean;
}

const notSelected = -1;
function createNewCgs(): CgsInventory {
  return {
    date: moment(),
    data: CgsJson.questions.reduce((acc: Record<string, number>, question) => {
      acc[question.title] = notSelected;
      return acc;
    }, {}),
    instructions: CgsJson.instructions,
    description: CgsJson.description,
    min: CgsJson.min,
    max: CgsJson.max,
    id: CgsJson.id,
    type: CgsJson.type,
    beforeLabel: CgsJson.beforeLabel,
    afterLabel: CgsJson.afterLabel,
    colorsForward: CgsJson.colorsForward,
    iconsForward: CgsJson.iconsForward
  };
}

export const initialState: CgsState = {
  cgs: createNewCgs(),
  hasError: false,
  invalidItems: [],
  isLoading: false,
  isSubmitting: false,
  showModal: false
};

let cgsRepo: Repo<Inventory>;
let _patientId: string;
const initialLoad = createAsyncThunk(
  'cgs/initialLoad',
  async (patientId: string) => {
    cgsRepo = createRepo<Inventory>(
      `${Collection.Patients}/${patientId}/${PatientSubcollection.InventoriesCgs}`
    );
    _patientId = patientId;
    return await cgsRepo.first({
      orderBy: ['date', 'desc']
    });
  }
);

const submit = createAsyncThunk<boolean, void, ThunkApi<CgsState>>(
  'cgs/submit',
  async (_, { getState, dispatch }) => {
    dispatch(cgsSlice.actions.validate());

    const { invalidItems } = getState();
    if (invalidItems.length) return false;

    dispatch(cgsSlice.actions.setCgsDate());
    const { cgs } = getState();

    await savePendingAssessment(
      _patientId,
      PatientSubcollection.InventoriesCgs,
      cgs
    );
    return true;
  }
);

const cgsSlice = createSlice({
  name: 'cgs',
  initialState,
  reducers: {
    setCgsDate: state => {
      const now = moment();
      state.cgs.id = getTimestampedDocId(now);
      state.cgs.date = now;
    },
    updateCgsItem: (state, { payload }: PayloadAction<SliderListItem>) => {
      const { title, value } = payload;
      state.invalidItems = state.invalidItems.filter(i => i !== title);

      state.cgs.data[title] = value;
    },
    validate: state => {
      state.invalidItems = Object.entries(state.cgs.data)
        .filter(([, value]) => value === notSelected)
        .map(([key]) => key);
    },
    closeModal: state => {
      state.showModal = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(initialLoad.pending, handlePending);
    builder.addCase(initialLoad.rejected, handleError);
    builder.addCase(initialLoad.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.lastUpdated = payload ? payload.date : undefined;
    });

    builder.addCase(submit.pending, state => {
      state.isSubmitting = true;
    });
    builder.addCase(submit.rejected, handleError);
    builder.addCase(submit.fulfilled, (state, { payload: isSaved }) => {
      state.isSubmitting = false;
      if (isSaved) {
        state.showModal = isSaved;
        state.lastUpdated = moment().utc(true);
        state.cgs = createNewCgs();
      }
    });
  }
});

export { initialLoad, submit, notSelected };

export const { updateCgsItem, validate, closeModal } = cgsSlice.actions;
export const CgsSliceReducer = cgsSlice.reducer;
export default cgsSlice.reducer;
