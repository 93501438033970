import { Document } from './document';

export enum DhiSection {
  Dhi = 'Dhi'
}

export enum DhiCategory {
  PHYSICAL = 'Physical',
  EMOTIONAL = 'Emotional',
  FUNCTIONAL = 'Functional',
  TOTAL = 'Total'
}

export enum DhiAnswers {
  'Never' = 0,
  'Sometimes' = 1,
  'Always' = 2
}

export interface DhiQuestion {
  id: number;
  title: string;
  value: number | undefined;
  section: DhiSection;
  category: DhiCategory;
}

export interface Dhi extends Document {
  questions: DhiQuestion[];
}

export const DhiCategoryMultipler: { [category: string]: number } = {
  Physical: 2,
  Emotional: 2,
  Functional: 2,
  Total: 2
};
