import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';

export const getAssessmentRecommendation = (
  patientInfo: PatientInfoState,
  type: keyof PatientInfoState['concussion']['Assessment Recommendations']
) =>
  (patientInfo.concussion['Assessment Recommendations'][type] ?? [])
    // Clean the assessment recommendation before returning it
    .map(name =>
      name
        .replace('Query', '')
        .replace('dysfunction', 'function')
        .replace('ADLS', 'ADLs')
        .replace('Occulormotor', 'Oculomotor')
        .trim()
    );
