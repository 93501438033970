import {
  setFulfilled,
  setRequired
} from 'features/navigation/rx-progress-slice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * A hook to update the progress bar as a questionnaire is completed
 * @param curVal The current value of the progress bar. This should be in the range [0, `maximum`]
 * @param maximum The maximum value of the progress bar
 */
export function useQuestionnaireProgression(curVal: number, maximum: number) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRequired(maximum));
    dispatch(setFulfilled(curVal));
  }, [curVal, maximum, dispatch]);
}
