import { roundSymptomValue } from 'utils/business';
import { Symptom, SubSymptom } from 'documents';
import { symptomLabels, subSymptomLabels } from '../utils/ui';

export const colors = ['#68D786', '#F5DD00', '#FFA049', '#FF6F6A'];
export const colorLabel = ['Normal', 'Mild', 'Moderate', 'Severe'];
export const colorMap = [0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3];

export const getValueColor = (value: number) =>
  colors[colorMap[roundSymptomValue(value)]];

/**
 * Resolve a color map based on the input type.
 * A color map is an array of numbers with index 0-3 that map to the `colors` array in this file
 *
 * @param type
 * @returns
 */
export const getColorMap = (type: string | undefined) => {
  switch (type) {
    case symptomLabels[Symptom.Thinking]:
      return colorMap;

    case subSymptomLabels[SubSymptom.VMPS]:
      return [0, 0, 1, 1, 2, 2, 3, 3, 3, 3, 3];
    case subSymptomLabels[SubSymptom.VerbalMemory]:
      return [0, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3];
    case subSymptomLabels[SubSymptom.ReactionTime]:
      return [0, 0, 0, 1, 1, 2, 2, 3, 3, 3, 3];

    default:
      return colorMap;
  }
};

export interface Legend {
  color: string;
  label: string;
}

export const getColorLegend = (): Legend[] => {
  // normal mild moderate severe
  const normal = colors[0],
    mild = colors[1],
    moderate = colors[2],
    severe = colors[3];

  const order = [normal, mild, moderate, severe];

  const legend = order.map(
    (color, i): Legend => ({ color: color, label: colorLabel[i] })
  );

  return legend;
};
