import { useEffect, useRef } from 'react';

import { Col, Row, Typography } from 'antd';

import RxCounselCardImg from 'img/rxcounsel-card-img.svg';
import RxNeuroCardImg from 'img/rxneuro-card-img.svg';
import RxPhysioCardImg from 'img/rxphysio-card-img.svg';
import RxTherapyCardImg from 'img/rxtherapy-card-img.svg';

import { PrintableRootPage } from 'components/page/page-2';
import patientInfoSlice, {
  patientInfoInitialLoad,
  initialState
} from 'components/patient-info/patientInfoSlice';
import { PrescriptionPadExportContainer } from 'features/prescription-pad/prescription-pad';
import { useReducer } from 'hooks/useReducer';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { halfCol } from 'utils/antd';
import { ConcussionRxPrintContext } from 'utils/print';
import { ExportAsPDFButton } from '../../../components/export-as-pdf-button';
import { getAssessmentRecommendation } from '../../../utils/get-assessment-recommendation';
import { TreatmentCard } from './treatment-card';
import './TreatmentRecommendations.scss';
import { ClinicianRecommendationsCard } from './clinician-recommendations-card';

export enum TreatmentSpecialists {
  PHYSIO = 'Physiotherapy',
  COUNSEL = 'Counseling',
  NEURO = 'Neuropsychology',
  OCCUPATIONAL = 'Occupational therapy'
}

type Content = { name: string; link: string };

/**
 * Helper method to transform a list of strings into a list of Content objects
 * @param data
 * @returns
 */
const strToContentList = (data: string[]): Content[] =>
  data.map(name => ({ name, link: '' }));

const TREATMENT_CATEGORIES = [
  'Physiotherapy Rx',
  'Neuropsychology Rx',
  'Counseling Rx',
  'Occupational Therapy Rx'
] as const;

export function TreatmentRecommendations() {
  const [patientInfo, patientInfoDispatch] = useReducer(
    patientInfoSlice,
    initialState
  );
  const { patientId } = useParams<{ patientId: string }>();
  const prescriptionPadRef = useRef<any | null>(null);
  useEffect(() => {
    patientInfoDispatch(patientInfoInitialLoad(patientId));
  }, [patientId]);

  const [
    RxPhysioContent,
    RxNeuroContent,
    RxCounselContent,
    RxOccupationalTherapyContent
  ] = TREATMENT_CATEGORIES.map(category =>
    strToContentList(getAssessmentRecommendation(patientInfo, category))
  );

  return (
    <ConcussionRxPrintContext
      content={() => prescriptionPadRef.current}
      documentTitle={`prescription-${moment().toISOString()}`}
      removeAfterPrint
    >
      <PrintableRootPage
        title={`${patientInfo.patient.displayName} Recommended Treatment`}
        extra={[<ExportAsPDFButton key="1" />]}
      >
        <div
          style={{
            borderRadius: '16px',
            backgroundColor: 'white',
            padding: '30px 40px 30px 40px',
            fontFamily: 'Comfortaa'
          }}
        >
          <div style={{ marginBottom: '40px' }}>
            <h3 style={{ marginBottom: '16px' }}>
              ConcussionRx Recommendations
            </h3>
            <p>
              The following reflects evidence-based best practice in concussion
              management and builds on the principles outlined in the most
              recent consensus statements. This tool is intended to help inform
              treatment planning and provide direction to support your patient
              achieving a complete recovery.
            </p>
          </div>

          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ marginBottom: '24px' }}
          >
            {[
              {
                content: RxPhysioContent,
                title: 'Physiotherapy',
                img: RxPhysioCardImg
              },
              {
                content: RxNeuroContent,
                title: 'Neuropsychology',
                img: RxNeuroCardImg
              },
              {
                content: RxCounselContent,
                title: 'Counseling',
                img: RxCounselCardImg
              },
              {
                content: RxOccupationalTherapyContent,
                title: 'Occupational Therapy',
                img: RxTherapyCardImg
              }
            ].map((content, index) => (
              <Col {...halfCol} key={`recommended-treatment-${index}`}>
                <TreatmentCard title={content.title} img={content.img}>
                  <ul style={{ listStyleType: 'none', padding: '0' }}>
                    {content.content.length > 0 &&
                      content.content.map((content, index) => (
                        <li
                          style={{ paddingBottom: '8px' }}
                          key={`rxphysio` + index}
                        >
                          <span className="treatment_content" rel="noreferrer">
                            {content.name}
                          </span>
                        </li>
                      ))}
                  </ul>
                </TreatmentCard>
              </Col>
            ))}
          </Row>
          <Row>
            <Col span={24}>
              <ClinicianRecommendationsCard
                patientInfo={patientInfo}
                patientInfoDispatch={patientInfoDispatch}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ fontFamily: 'Comfortaa' }}>
              <div style={{ margin: '40px 0px' }}>
                <Typography.Title level={2}>What&apos;s next?</Typography.Title>
                <p>
                  Have {patientInfo.patient.displayName} repeat their
                  ConcussionRx evaluation every 30 days to track their progress
                  and refine treatment recommendations as concussion recovery
                  progresses.
                </p>
              </div>

              <ExportAsPDFButton />
            </Col>
          </Row>
        </div>
        <div style={{ display: 'none' }}>
          <PrescriptionPadExportContainer
            getRef={ref => (prescriptionPadRef.current = ref)}
            patientInfo={patientInfo}
          />
        </div>
      </PrintableRootPage>
    </ConcussionRxPrintContext>
  );
}
