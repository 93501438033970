import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'app/rootReducer';
import { Form } from 'components';
import { useReducer } from 'hooks/useReducer';

import {
  initialLoad,
  initialState,
  NOT_SELECTED,
  PromisSliceReducer,
  PROMIS_SECTIONS,
  submit
} from './promisSlice';

import { setFaqObject } from 'features/navigation/navigationSlice';

import { OutcomeMeasuresHeader } from 'components/header';

import { PaginationCombinedPromis } from './promis-pagination-combined';
import { cleanPagedQuestionnaireStorage } from 'features/patient/assessment/paged-questionnaire';

export function PatientPromis() {
  const user = useAppSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const [currentSection, setSection] = useState(0);

  const [{ promis, lastUpdated }, promisDispatch] = useReducer(
    PromisSliceReducer,
    initialState
  );

  useEffect(() => {
    /**
     * Load user information when it is available
     */
    promisDispatch(initialLoad(user!.uid));
  }, [user, promisDispatch]);

  const isChanged = Object.values(promis.data).some(
    value => value > NOT_SELECTED
  );

  dispatch(
    setFaqObject({
      min: PROMIS_SECTIONS[currentSection].min,
      max: PROMIS_SECTIONS[currentSection].max,
      instructions: PROMIS_SECTIONS[currentSection].instructions,
      description: PROMIS_SECTIONS[currentSection].description
    })
  );

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <OutcomeMeasuresHeader
        label={'Exit'}
        title={`1st Questionnaire: ${
          PROMIS_SECTIONS[currentSection].title ?? `Section ${currentSection}`
        }`}
        onExit={() => {
          cleanPagedQuestionnaireStorage('questionnaire-promis');
        }}
      />
      <Form
        isChanged={isChanged}
        lastUpdated={lastUpdated}
        onSubmit={() => promisDispatch(submit())}
        showFooter={false}
      >
        {PROMIS_SECTIONS.length && (
          <PaginationCombinedPromis
            onSectionChange={page => setSection(page)}
            storageKey={'questionnaire-promis'}
          />
        )}
      </Form>
    </Layout>
  );
}
