/**
 * The complexity of a concussion
 */
export enum ConcussionComplexity {
  Minimal = 'minimal',
  Mild = 'mild',
  Moderate = 'moderate',
  High = 'high',
  Extreme = 'extreme'
}

export const API_TO_COMPLEXITY_MAP = {
  0: ConcussionComplexity.High,
  1: ConcussionComplexity.Moderate,
  2: ConcussionComplexity.Minimal,
  3: ConcussionComplexity.Mild,
  4: ConcussionComplexity.Extreme
};

/**
 * An array is used because the order of Objects.values() is not deterministic
 * and the API_TO_COMPLEXITY_MAP is only for mapping enum values
 */
export const COMPLEXITY_ORDER = [
  ConcussionComplexity.Minimal,
  ConcussionComplexity.Mild,
  ConcussionComplexity.Moderate,
  ConcussionComplexity.High,
  ConcussionComplexity.Extreme
];
