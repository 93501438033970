/**
 * Capitalise the first letter of a string
 */
export const capitaliseFirstLetter = (val: string): string =>
  val.charAt(0).toUpperCase() + val.slice(1);

/**
 * Formats a number to a string with up to 2 decimal places.
 * - 2 decimal places if the last digit is not 0
 * - 1 decimal place if the last digit is not 0
 * - 0 decimal places
 */
export const formatNumber = (val: number): string =>
  val.toFixed(2).endsWith('0')
    ? val.toFixed(1).endsWith('0')
      ? // 0 decimal places
        val.toFixed(0)
      : // 1 decimal place
        val.toFixed(1)
    : // 2 decimal places
      val.toFixed(2);
