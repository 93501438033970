import {
  ImmediateSymptom,
  ImpactLocation,
  InjuryMechanism,
  LocDuration
} from 'documents';

export const impactLocationOptions = [
  { label: ImpactLocation.TopRight, value: ImpactLocation.TopRight },
  { label: ImpactLocation.TopLeft, value: ImpactLocation.TopLeft },
  { label: ImpactLocation.SideRight, value: ImpactLocation.SideRight },
  { label: ImpactLocation.SideLeft, value: ImpactLocation.SideLeft },
  { label: ImpactLocation.BackRight, value: ImpactLocation.BackRight },
  { label: ImpactLocation.BackLeft, value: ImpactLocation.BackLeft },
  { label: ImpactLocation.FrontLeft, value: ImpactLocation.FrontLeft },
  { label: ImpactLocation.FrontRight, value: ImpactLocation.FrontRight },
  { label: ImpactLocation.Indirect, value: ImpactLocation.Indirect },
  { label: ImpactLocation.NeckWhiplash, value: ImpactLocation.NeckWhiplash }
];

export const injuryMechanismOptions = [
  { label: InjuryMechanism.Fall, value: InjuryMechanism.Fall },
  { label: InjuryMechanism.MVC, value: InjuryMechanism.MVC },
  {
    label: 'Car v. Pedestrian or Cyclist',
    value: InjuryMechanism.PedestrianCyclistMVC
  },
  { label: InjuryMechanism.Assault, value: InjuryMechanism.Assault },
  { label: InjuryMechanism.Sports, value: InjuryMechanism.Sports },
  { label: InjuryMechanism.Other, value: InjuryMechanism.Other }
];

export const locDurationOptions = [
  { label: LocDuration.LessThan1min, value: LocDuration.LessThan1min },
  { label: LocDuration.LessThan5mins, value: LocDuration.LessThan5mins },
  { label: LocDuration.LessThan30mins, value: LocDuration.LessThan30mins },
  { label: LocDuration.LessThan1hour, value: LocDuration.LessThan1hour },
  { label: LocDuration.LessThan24hours, value: LocDuration.LessThan24hours },
  { label: LocDuration.MoreThan24hours, value: LocDuration.MoreThan24hours }
];

export const immediateSymptomOptions = [
  {
    label: ImmediateSymptom.DazedStunned,
    value: ImmediateSymptom.DazedStunned
  },
  { label: ImmediateSymptom.Confusion, value: ImmediateSymptom.Confusion },
  {
    label: ImmediateSymptom.SlowToAnswerSpeak,
    value: ImmediateSymptom.SlowToAnswerSpeak
  },
  {
    label: ImmediateSymptom.RepeatingQuestions,
    value: ImmediateSymptom.RepeatingQuestions
  },
  { label: ImmediateSymptom.Forgetful, value: ImmediateSymptom.Forgetful }
];
