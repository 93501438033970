import {
  PagedQuestionnaire,
  QUESTIONNAIRE_TITLE_24_HR
} from 'features/patient/assessment/paged-questionnaire';
import { formatPcs } from './PatientPcs';
import {
  initialState,
  PcsSliceReducer,
  submit,
  updatePcsItem,
  validate
} from './pcsSlice';

export function PaginationCombined(props: { storageKey: string }) {
  return (
    <PagedQuestionnaire
      pageTitle={QUESTIONNAIRE_TITLE_24_HR}
      actions={{
        formatData: formatPcs,
        validate,
        updateItem: updatePcsItem,
        submit
      }}
      getData={state => state.pcs}
      initialState={initialState}
      storageKey={props.storageKey}
      reducer={PcsSliceReducer}
    />
  );
}
