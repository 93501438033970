/**
 * Add opacity to a hex string
 * @param hexString The original colour with no opacity value
 * @param opacity The opacity to set. Expects a number between 0 and 1
 * @returns The new hex colour with opacity
 */
export const addHexOpacity = (hexString: string, opacity: number) =>
  `${hexString}${Math.floor(opacity * 255)
    .toString(16)
    .padStart(2, '0')}`;
