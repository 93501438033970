import { PrivateRouteProps } from 'components/routes/private-route';
import { Role } from 'features/auth/types';
import {
  PatientAndClinicianRoutes,
  PatientOnboardingRoutes,
  PatientOnlyRoutes,
  PatientTestRoutes
} from './patient-routes-list';

export const IS_ONBOARDING = 0b10;
export const IS_IN_OR_NEEDS_ASSESSMENT = 0b01;

const stateMap: Record<number, PrivateRouteProps[]> = {
  [IS_ONBOARDING]: PatientOnboardingRoutes,
  [IS_ONBOARDING | IS_IN_OR_NEEDS_ASSESSMENT]: PatientOnboardingRoutes,
  [IS_IN_OR_NEEDS_ASSESSMENT]: PatientTestRoutes,
  [0b00]: [...PatientAndClinicianRoutes, ...PatientOnlyRoutes]
};

/**
 * User states
 * - The user is a clinician or clinic admin (0b000)
 * - The user is a patient that needs to complete their onboarding (0b110)
 * - The user is a patient that has completed their onboarding and needs to do an assessment (0b101)
 * - THe user is a patient that has completed their onboarding and has started an assessment (0b101)
 * - The user is a patient that has completed their onboarding and does not need to do an assessment (0b100)
 */
export function filterPatientRoutes(
  role: Role,
  options:
    | {
        useBitmask: false;
        userIsBeingOnboarded: boolean;
        userIsDoingAnAssessment: boolean;
      }
    | { useBitmask: true; bitmask: number }
) {
  if ([Role.Clinician, Role.ClinicAdmin].includes(role))
    return PatientAndClinicianRoutes;
  let mask = 0;

  if (!options.useBitmask) {
    if (options.userIsBeingOnboarded) mask |= IS_ONBOARDING;
    if (options.userIsDoingAnAssessment) mask |= IS_IN_OR_NEEDS_ASSESSMENT;
  }

  /**
   * Validate the mask
   */
  if (!(mask in stateMap)) {
    throw new Error(
      `[filterPatientRoutes] Invalid mask. Mask '${mask}' is not mapped in '${Object.keys(
        stateMap
      )}'`
    );
  }

  return stateMap[mask];
}
