import React from 'react';

import historyLogo from 'img/demographics-history-logo.svg';
import injuryLogo from 'img/demographics-injury-logo.svg';
import profileLogo from 'img/demographics-profile-logo.svg';
import { Routes } from 'routes';

import { Page, TileMenu, TileProps } from 'components';

const tiles: TileProps[] = [
  {
    title: 'About Me',
    logo: profileLogo,
    link: Routes.PatientDemographicsProfile
  },
  {
    title: 'My Inquiry',
    logo: injuryLogo,
    link: Routes.PatientDemographicsInjury
  },
  {
    title: 'Medical History',
    logo: historyLogo,
    link: Routes.PatientDemographicsPersonalHistory
  }
];

export const PatientDemographics: React.FC = () => (
  <Page title="My Info" hideBack>
    <TileMenu tiles={tiles} />
  </Page>
);
