import { push } from 'connected-react-router';
import { auth, postSetPassword } from 'api/firebase/firebase-api';
import { AppThunk } from 'app/store';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { ConnectRouterState } from 'app/rootReducer';
import { AuthSliceActions } from './authSlice';

export const setUserPassword =
  (code: string, password: string): AppThunk =>
  async dispatch => {
    dispatch(AuthSliceActions.startLoadingAction());
    try {
      await auth.confirmPasswordReset(code, password);
      await postSetPassword();
      dispatch(
        push<ConnectRouterState>({
          pathname: '/',
          state: {
            lastAction: 'resetPassword'
          }
        })
      );
    } catch (error) {
      console.error('[setUserPassword]', error);
      dispatch(AuthSliceActions.setError(resolveError(error).message));
    } finally {
      dispatch(AuthSliceActions.stopLoadingAction());
    }
  };
