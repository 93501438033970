import './RouteSwitch.scss';

import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { Layout, Skeleton } from 'antd';
import { ClinicianRoutePrefix, PatientRoutePrefix, Routes } from 'routes';

import { ConnectRouterState, useAppSelector } from 'app/rootReducer';

import { ConnectedRxProgressBar, Footer, PrivateRoute } from 'components';

const { Content } = Layout;

import {
  applyFilter,
  routesWithWhiteBg,
  siteProgressBarRoutes
} from 'constants/filters';
import { ClinicianRouteSwitch } from './route-switch-clinician';
import { PatientRouteSwitch } from './route-switch-patient/route-switch-patient';

import { SignInV2, VerifyEmail } from 'features/sign-in';
import { PrivacyPolicy, TermsOfService } from 'features/terms-of-service';

import { PublicRoute } from 'components/routes/public-route';
import { AcceptInvite } from 'features/accept/invite/accept-invite';
import { Role } from 'features/auth/types';
import { ForgotPassword } from 'features/sign-in/v2/forgot-password';
import { CreateAccount } from 'features/sign-in/v2/page-create-account';
import { CreateAccountConfirmation } from 'features/sign-in/v2/page-create-account-confirmation';

export function RouteSwitch() {
  const backgroundColor = useAppSelector(state => state.uiPref.backgroundColor);
  const authState = useAppSelector(state => state.auth);
  const loc = useLocation<ConnectRouterState | undefined>();
  const location = loc.pathname;

  function renderHeaderProgressBar() {
    if (
      siteProgressBarRoutes.findIndex(fn =>
        typeof fn === 'function' ? fn(location) : location === fn
      ) !== -1
    ) {
      return (
        <ConnectedRxProgressBar
          className="no-top-margin flat-ends"
          strokeLinecap="round"
        />
      );
    }
    return null;
  }

  function getBackgroundColor() {
    if (
      routesWithWhiteBg.findIndex(match =>
        typeof match === 'function' ? match(location) : match === location
      ) > -1
    ) {
      return 'white';
    }

    return backgroundColor;
  }

  if (authState.isUserDataLoading) {
    return <Skeleton active />;
  }

  return (
    // TODO dynamic background color assignment through reducer
    <Layout
      className="site-layout"
      style={{ backgroundColor: getBackgroundColor() }}
    >
      {renderHeaderProgressBar()}

      <Content>
        <Switch>
          <PublicRoute
            path={'/accept/invite'}
            component={AcceptInvite}
            redirectPath={'/'}
          />
          <PublicRoute
            path={Routes.SignInSuffix}
            component={SignInV2}
            publicOnly
            redirectPath={'/'}
          />
          <PrivateRoute
            roles={[Role.ClinicAdmin, Role.Clinician, Role.Patient]}
            path={Routes.CreateAccountSuffix}
            component={CreateAccount}
            redirectPath={'/'}
          />
          <Route path={Routes.ForgotPassword}>
            <ForgotPassword />
          </Route>
          <Route path={Routes.TermsOfService}>
            <TermsOfService />
          </Route>
          <Route path={Routes.PrivacyPolicy}>
            <PrivacyPolicy />
          </Route>
          <Route path={Routes.VerifyEmail}>
            <VerifyEmail />
          </Route>

          <PrivateRoute
            roles={[Role.Clinician, Role.ClinicAdmin]}
            path={ClinicianRoutePrefix}
            component={ClinicianRouteSwitch}
            redirectPath={'/'}
            passwordCheck
          />
          <PrivateRoute
            roles="*"
            path={PatientRoutePrefix}
            component={PatientRouteSwitch}
            redirectPath={'/'}
            passwordCheck
          />
          <Route
            // exact
            path={'/'}
            render={() => {
              // If there is no user
              if (!authState.user) {
                // Redirect to the patient sign in
                return <Redirect to={Routes.SignInSuffix} />;
              }

              if (loc.state?.lastAction === 'resetPassword') {
                return <CreateAccountConfirmation />;
              }

              if (authState.user.needsPassword) {
                return <Redirect to={Routes.CreateAccountSuffix} />;
              }

              // Otherwise the user is signed in. Redirect the user to their home page
              return (
                <Redirect
                  to={
                    [Role.Clinician, Role.ClinicAdmin].includes(
                      authState.user.role
                    )
                      ? ClinicianRoutePrefix
                      : PatientRoutePrefix
                  }
                />
              );
            }}
          />
        </Switch>
      </Content>
      {!applyFilter(location, routesWithWhiteBg) && <Footer />}
    </Layout>
  );
}
