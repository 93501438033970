import { createSlice } from '@reduxjs/toolkit';
// @ts-ignore
import palette from 'styles/palette.scss';

export interface UiPrefState {
  menuCollapsed: boolean;
  backgroundColor: string;
}

const initialState: UiPrefState = {
  menuCollapsed: false,
  backgroundColor: palette.backgroundColor
  // "#f0f2f5" // references palette background color sass variable need a way to map these two together or something
};

const uiPrefSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleMenuCollapse(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },
    collapseMenu(state) {
      state.menuCollapsed = true;
    },
    changeBackgroundColor(state, { payload }) {
      // console.log('changebackgroundCOlor uiPrefSlice', payload);
      state.backgroundColor = payload;
    }
  }
});

export const { toggleMenuCollapse, collapseMenu, changeBackgroundColor } =
  uiPrefSlice.actions;

export default uiPrefSlice.reducer;

/* GLOBAL REDUCERS USED IN ROOT STATE COMBINED SO COMPONENTS CAN ACCESS THEM THROUGH ROOT REDUCER */

export interface FAQModalState {
  // description instructions min max
  description?: string;
  instructions?: string;
  minRating: number;
  maxRating: number;
}

const faqInitialState: FAQModalState = {
  description: 'Recommended',
  instructions: 'Instructions',
  minRating: 1,
  maxRating: 5
};

const FAQModalSlice = createSlice({
  name: 'FAQModalSlice',
  initialState: faqInitialState,
  reducers: {
    setDescription(state, { payload }) {
      state.description = payload;
    },
    setInstructions(state, { payload }) {
      state.instructions = payload;
    },
    setMinRating(state, { payload }) {
      state.minRating = payload;
    },
    setMaxRating(state, { payload }) {
      state.maxRating = payload;
    },
    setFaqObject(state, { payload }) {
      // console.log('test setFaqObject: ', payload, payload.instructions);
      const { description, instructions, min, max } = payload;
      // console.log("test: ", description, instructions, min, max);
      state.description = description;
      state.instructions = instructions;
      state.minRating = min;
      state.maxRating = max;
    }
  }
});

export const {
  setDescription,
  setInstructions,
  setMinRating,
  setMaxRating,
  setFaqObject
} = FAQModalSlice.actions;
export const FAQModalReducer = FAQModalSlice.reducer;
