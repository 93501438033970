import './Rating.scss';

import React, { useState } from 'react';

import { Col, InputNumber, Radio, Row, Slider } from 'antd';
import { isNil } from 'lodash';

import UnhappyFace from 'img/unhappyface.svg';
import HappyFace from 'img/happyface.svg';
import { RadioButtonListItem } from 'features/patient/outcome-measures/cgs/radio-button-list';
import { Text } from 'components/mvp-typography';
export interface RatingProps {
  value?: number;
  buttonsCount?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
  manualInput?: boolean;
}

export function Rating(props: RatingProps) {
  const {
    value,
    buttonsCount = 5,
    min = 1,
    max = 99,
    disabled,
    onChange,
    manualInput = true
  } = props;

  function handleInputChange(value?: number | string | null) {
    if (!onChange || isNil(value)) {
      return;
    }

    let numericValue =
      typeof value === 'number' ? (value as number) : Number.parseInt(value);

    if (isNaN(numericValue)) {
      numericValue = 0;
    }

    onChange(numericValue);
  }

  const buttons = [];
  for (let i = min; i <= max; i++) {
    buttons.push(
      <Radio.Button key={i} value={i}>
        {i}
      </Radio.Button>
    );
  }

  return (
    <Row className="crx-rating" justify="space-between">
      <Col flex={2}>
        <Radio.Group
          value={
            !isNil(value) && min + buttonsCount > value ? value : undefined
          }
          disabled={disabled}
          onChange={e => onChange && onChange(e.target.value)}
        >
          {buttons}
        </Radio.Group>
      </Col>
      {manualInput && (
        <Col>
          <InputNumber
            className="crx-rating__input"
            value={value}
            disabled={disabled}
            min={min}
            max={max}
            onChange={handleInputChange}
          />
        </Col>
      )}
    </Row>
  );
}

interface CircularRatingProps extends RatingProps {
  beforeIcon?: React.ReactElement;
  afterIcon?: React.ReactElement;
  beforeLabel: string;
  afterLabel: string;
  colorsForward?: boolean;
  iconsForward?: boolean;
  itemHighlight?: Record<any, any>;
}

export function CircularRating({
  value,
  // buttonsCount = 5,
  min = 1,
  max = 99,
  disabled,
  onChange,
  // manualInput = true,
  beforeIcon = <img src={UnhappyFace} />,
  afterIcon = <img src={HappyFace} />,
  beforeLabel = 'Unable',
  afterLabel = 'Able',
  colorsForward = true,
  iconsForward = true
}: CircularRatingProps) {
  // const handleOnChange = (e) => {
  //   // setValue(e.target.value);
  //   onChange(e.target.value)
  // };
  const classRow = disabled
    ? 'crx-circular-rating-disabled'
    : 'crx-circular-rating-active';

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} className={classRow}>
      <Radio.Group
        size="large"
        value={
          value
          //!isNil(value) && min + buttonsCount > value ? value : undefined
        }
        disabled={disabled}
        onChange={e => onChange && onChange(e.target.value)}
      >
        <Row align="middle" justify="center">
          {iconsForward ? beforeIcon && beforeIcon : afterIcon && afterIcon}

          {beforeLabel && (
            <Text style={{ marginLeft: 20, marginRight: 20 }}>
              {beforeLabel}
            </Text>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '30px',
              alignItems: 'center'
            }}
          >
            <MinMaxRadioButtons
              disabled={!!disabled}
              min={min}
              max={max}
              colorsForward={colorsForward}
            />
          </div>
          {afterLabel && (
            <Text style={{ marginLeft: 20, marginRight: 20 }}>
              {afterLabel}
            </Text>
          )}
          {iconsForward ? afterIcon && afterIcon : beforeIcon && beforeIcon}
        </Row>
      </Radio.Group>
    </div>
  );
}

interface SliderRatingProps {
  min: number;
  max: number;
  beforeIcon?: React.ReactElement;
  beforeLabel?: string;
  afterIcon?: React.ReactElement;
  afterLabel?: string;
  onChange: (val: number) => void | any;
  disabled?: boolean;
  value?: number;
  item?: RadioButtonListItem;
}

export function SliderRating({
  min = 1,
  max = 5,
  beforeLabel = 'No pain',
  afterLabel = 'Unimaginable pain',
  beforeIcon = <img src={HappyFace} />,
  afterIcon = <img src={UnhappyFace} />,
  onChange,
  disabled,
  value
}: SliderRatingProps) {
  const [cacheValue, setCacheValue] = useState(value);
  // value of marks newed to be preprocessed and postprocessed before handling it off to be used...
  function generateMarks(min: number, max: number): Record<number, number> {
    const ret: Record<number, number> = {};

    for (let i = min; i <= max; i++) {
      ret[i] = i * 1;
    }
    // console.log("ret: ", ret, min, max);
    return ret;
  }

  const onLocalValueChange = (e: React.SetStateAction<number | undefined>) =>
    setCacheValue(e);
  const onAfterChangeComplete = (e: number) => onChange(e);

  return (
    <Row>
      <Col>
        {/*<Radio.Group
              size="large"
              value={!isNil(value) && (max-min) > value ? value : undefined}
              disabled={disabled}
              onChange={e => onChange && onChange(e.target.value)}
            >*/}
        <div style={{ marginTop: '72px' }}>
          <Row align="middle">
            {beforeIcon && beforeIcon}
            {beforeLabel && (
              <span className="crx-circular-beforeLabel">{beforeLabel}</span>
            )}
            <div
              style={{
                position: 'relative',
                minWidth: '300px',
                fontFamily: 'Comfortaa',
                marginLeft: '46px',
                marginRight: '46px'
              }}
            >
              <Slider
                // dotStyle={{
                //   backgroundColor: 'rgba(118, 118, 121, 1)',
                //   height: '12px',
                //   width: '12px',
                //   top: '-4px'
                // }}
                railStyle={{ backgroundColor: 'rgba(228, 230, 235, 1)' }}
                trackStyle={{ backgroundColor: 'rgba(228, 230, 235, 1)' }}
                max={max}
                min={min}
                marks={generateMarks(min, max)}
                defaultValue={min}
                // value={value}
                value={cacheValue}
                // onChange={onChange}
                onChange={onLocalValueChange}
                // 4.11+ antdesign
                onAfterChange={e => {
                  onAfterChangeComplete(e);
                }}
                disabled={disabled}
                step={1}
              />
            </div>
            {afterLabel && (
              <span className="crx-circular-afterLabel">{afterLabel}</span>
            )}
            {afterIcon && afterIcon}
          </Row>
          <span> </span>
        </div>
        {/*</Radio.Group> */}
      </Col>
    </Row>
  );
}

/**
 * Show a list of radio buttons to select a number between `min` and `max`
 */
function MinMaxRadioButtons(
  props: Required<
    Pick<CircularRatingProps, 'disabled' | 'max' | 'min' | 'colorsForward'>
  >
) {
  const { max, min, colorsForward, disabled } = props;
  // maybe pull out the color "classes" into it's own class or helper file
  let buttonClasses = [
    'crx-warning',
    'crx-orange',
    'crx-orange-light',
    'crx-yellow',
    'crx-green'
  ];
  const buttonLength = max - min + 1;

  const buttons = [];
  let j = 0;

  if (buttonLength === 7) {
    buttonClasses = [
      'crx-warning',
      'crx-orange',
      'crx-orange',
      'crx-orange-light',
      'crx-orange-light',
      'crx-yellow',
      'crx-green'
    ];
  } else if (buttonLength === 5) {
    // do nothing our default buttonClasses is fine
  } else if (buttonLength === 4)
    buttonClasses = ['crx-warning', 'crx-yellow', 'crx-blue', 'crx-green'];
  else if (buttonLength === 3) {
    buttonClasses = ['crx-warning', 'crx-orange-light', 'crx-green'];
  } else if (buttonLength === 2) {
    buttonClasses = ['crx-warning', 'crx-green'];
  } else {
    console.warn('Unexpected color case here at length: ', max - min);
  }

  if (!colorsForward) buttonClasses = buttonClasses.reverse();

  /**
   * Generate the list of radio buttons to render
   */
  for (let i = min; i <= max; i++) {
    const buttonClass = buttonClasses[j++];

    // Convert the number to a string else the number `0` is not rendered
    const formattedIndex = i.toString();
    buttons.push(
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        {/* Invisible element to push the radio button to the center */}
        <Text style={{ fontFamily: 'Comfortaa', opacity: 0 }}>
          {formattedIndex}
        </Text>
        <Radio
          key={i}
          disabled={disabled}
          value={i}
          className={'custom-radio ' + `${buttonClass}`}
        />
        <Text style={{ fontFamily: 'Comfortaa' }}>{formattedIndex}</Text>
      </div>
    );
  }

  return <>{buttons}</>;
}
