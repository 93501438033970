import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';

export function doesUserHaveAssessments(
  patientInfo: Pick<PatientInfoState, 'dhi' | 'impact' | 'promis'>
) {
  return [
    patientInfo.dhi,
    /* patientInfo.pcs, patientInfo.cgs, */ patientInfo.promis,
    patientInfo.impact
  ].every(arr => arr.length !== 0);
}
