import './RecoveryTracker.scss';

import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import {
  ExpirationPeriod,
  PatientSymptom,
  // QualityOfLife,
  Symptom
} from 'documents';
import { getSymptomExpiredSources } from 'utils/business';

import { RootState } from 'app/rootReducer';
// import { QualityOfLifePanel } from 'components/quality-of-life';
import { Role } from 'features/auth/types';

import { Symptoms } from './symptoms';

export interface RecoveryTrackerProps {
  symptoms: PatientSymptom[];
  // qualityOfLifes: QualityOfLife[];
  links: Record<string, string>;
  inventoryExpirations?: ExpirationPeriod;
}

const symptomValues = Object.values(Symptom);

export const RecoveryTracker: FC<RecoveryTrackerProps> = ({
  symptoms,
  // qualityOfLifes,
  links,
  inventoryExpirations
}) => {
  const isClinician = useSelector((state: RootState) =>
    [Role.Clinician, Role.ClinicAdmin].includes(state.auth.user?.role as any)
  );

  return (
    <>
      {/*      <h2>Quality of life</h2>
      <qualityoflifepanel values={qualityoflifes} />
*/}
      <div className="symptoms-trend-header">
        <h2>Symptoms Trend</h2>
        {isClinician && isAnySymptomExpired(symptoms, inventoryExpirations) && (
          <div className="symptoms-trend-header__info-message">
            Some inventories are not updated
          </div>
        )}
      </div>
      <Symptoms docs={symptoms} links={links} />
    </>
  );
};

function isAnySymptomExpired(
  docs: PatientSymptom[],
  inventoryExpirations?: ExpirationPeriod
) {
  const expirations = symptomValues.map(symptom => {
    const symptomDoc = docs.find(x => x[symptom]?.value !== undefined);
    return {
      symptom,
      expired:
        !!symptomDoc &&
        getSymptomExpiredSources(symptom, symptomDoc, inventoryExpirations)
          .length
    };
  });

  return expirations.find(x => x.expired);
}
