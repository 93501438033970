import { useEffect, useRef } from 'react';

import { Symptom } from 'documents';
import { buildUrl, PatientRoutes } from 'routes';

import { useReducer } from 'hooks/useReducer';

import { PrintableRootPage } from 'components/page/page-2';

import { useParams } from 'react-router-dom';
import { ConcussionRxPrintContext } from 'utils/print';
import { pluralize } from 'utils/string/normalise';
import { PatientInfoContext } from '../../../components/patient-info/hook-context-patientinfo';
import patientDashboardSlice, {
  patientInfoInitialLoad,
  initialState
} from '../../../components/patient-info/patientInfoSlice';
import { PatientSummaryDashboard } from './patient-summary-dashboard';
import { ExportAsPDFButton } from 'components/export-as-pdf-button';

/**
 * A page that allows the patient to quickly view their symptoms and concussion complexity
 */
export function PatientSummary() {
  const params = useParams<{ patientId: string }>();

  const [state, dispatch] = useReducer(patientDashboardSlice, {
    ...initialState,
    isLoading: false
  });

  useEffect(() => {
    // Load patient data
    dispatch(patientInfoInitialLoad(params.patientId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.patientId]);

  const printableDomElementRef = useRef<Element | null>(null);

  return (
    <ConcussionRxPrintContext content={() => printableDomElementRef.current}>
      <PrintableRootPage
        title={`${pluralize(state.patient.displayName)} ConcussionRx Summary`}
        hasError={state.hasError}
        isLoading={state.isLoading}
        extra={[<ExportAsPDFButton key="4" text="Print Report" />]}
        ref={printableDomElementRef}
      >
        {/* Allow sub-components to access patient info */}
        <PatientInfoContext.Provider value={{ patientInfo: state, dispatch }}>
          <PatientSummaryDashboard getLink={getLink} />
        </PatientInfoContext.Provider>
      </PrintableRootPage>
    </ConcussionRxPrintContext>
  );
}

const getLink = (symptom: Symptom) =>
  buildUrl(PatientRoutes.PatientSymptom, {
    symptom
  });
