import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Menu, MenuItemProps } from 'antd';
import { SignOutButton } from 'components/sign-out-button';
import { User } from 'features/auth';

const styles = {
  sideMenu: { fontFamily: 'Comfortaa', borderRadius: '0px 0px 20px 0px' },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '32px',
    width: '32px',
    background: 'transparent'
  },
  avatar: { color: 'rgba(118, 118, 121, 1)' },
  avatarLabel: {
    borderBottom: '1px solid rgba(163, 102, 252, 0.25)',
    color: 'rgba(36, 36, 37, 1)'
  },

  collapseContainer: {
    height: '32px',
    width: '32px',
    borderRadius: '16px',
    background: 'rgba(228, 230, 235, 1)'
  },
  collapseIcon: {
    display: 'flex',
    justifyContent: 'center',
    height: '32px',
    width: '32px'
  }
};

interface SideMenuFooterProps {
  collapsed: boolean;
  onClick: MenuItemProps['onClick'];
  user: User;
}
export function SideMenuFooter({
  collapsed,
  onClick,
  user
}: SideMenuFooterProps) {
  // console.log("user: ", user.photoURL, photoURL);
  const label = collapsed ? '' : 'Collapse';

  /**
   * Don't render the menu if the user data is not loaded
   */
  if (!user) return null;

  return (
    <Menu className={'nohighlight'} style={styles.sideMenu}>
      <Menu.Item className={'nohighlight'}>
        <SignOutButton rounded />
      </Menu.Item>

      <Menu.Item
        className={'nohighlight'}
        onClick={onClick}
        icon={
          collapsed ? (
            <div style={styles.collapseContainer}>
              <RightOutlined style={styles.collapseIcon} />
            </div>
          ) : (
            <div style={styles.collapseContainer}>
              <LeftOutlined style={styles.collapseIcon} />
            </div>
          )
        }
      >
        {label}
      </Menu.Item>
    </Menu>
  );
}
