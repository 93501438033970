import { Col, DatePicker, Form, Row, Select } from 'antd';
import { LanguageSelect } from 'components';
import { DarkButton } from 'components/dark-button';
import { dateFormat } from 'constants/date-time';
import {
  PatientInjuryKey,
  PatientProfile2,
  PatientProfileKey
} from 'documents';
import { omit } from 'lodash';
import {
  handleFormChange,
  oneThirdCol,
  ROW_GUTTER,
  selectProps
} from 'utils/antd';
import { clear } from 'utils/object';
import { injuryMechanismOptions } from '../InjuryForm/select-options';
import { ImpactLocationInput } from './impact-location-input';
import { keyCondition, keyDependency } from './key-dependency';
import { ProfileFormProps } from './ProfileForm';
import {
  concussionDiagnosisOptions,
  englishFluencyOptions,
  genderOptions
} from './select-options';

export function ProfileForm2(
  props: ProfileFormProps & { value: PatientProfile2; submitLoading?: boolean }
) {
  const { value: formValue, onChange, onDone } = props;
  const questions: { component: () => JSX.Element }[] = [
    {
      component: () => (
        <Form.Item
          hasFeedback
          className="form_item"
          name={PatientProfileKey.birthDate}
          rules={[
            {
              required: true,
              message: 'Date of Birth is required'
            }
          ]}
        >
          <DatePicker format={dateFormat} placeholder="Date of Birth" />
        </Form.Item>
      )
    },
    {
      component: () => (
        <Form.Item
          hasFeedback
          validateFirst
          className="form_item"
          name={PatientProfileKey.sex}
          rules={[
            {
              required: true,
              message: 'Sex at birth is required'
            }
          ]}
        >
          <Select
            labelInValue
            options={genderOptions}
            {...selectProps}
            placeholder="Sex at Birth"
          />
        </Form.Item>
      )
    },
    {
      component: () => (
        <Form.Item
          hasFeedback
          className="form_item"
          name={PatientProfileKey.primaryLanguage}
          rules={[
            {
              required: true,
              message: 'Primary Language is required'
            }
          ]}
        >
          <LanguageSelect {...selectProps} placeholder="Primary Language" />
        </Form.Item>
      )
    },
    {
      component: () => (
        <Form.Item
          hasFeedback
          className="form_item"
          name={PatientProfileKey.englishFluency}
          rules={[
            {
              required: true,
              message: 'English fluency is required'
            }
          ]}
        >
          <Select
            options={englishFluencyOptions}
            {...selectProps}
            placeholder="English Fluency"
          />
        </Form.Item>
      )
    },
    {
      component: () => (
        <Form.Item
          hasFeedback
          className="form_item"
          name={PatientInjuryKey.injuryDate}
          rules={[
            {
              required: true,
              message: 'Date of injury is required'
            }
          ]}
        >
          <DatePicker format={dateFormat} placeholder="Date of Injury" />
        </Form.Item>
      )
    },
    {
      component: () => (
        <Form.Item
          hasFeedback
          className="form_item"
          name={PatientProfileKey.concussionDiagnosis}
          rules={[
            {
              required: true,
              message: 'An answer is required'
            }
          ]}
        >
          <Select
            options={concussionDiagnosisOptions}
            {...selectProps}
            placeholder="Concussion Diagnosis"
          />
        </Form.Item>
      )
    },
    {
      component: () => (
        <Form.Item
          hasFeedback
          className="form_item"
          name={PatientInjuryKey.injuryMechanism}
          rules={[
            {
              required: true,
              message: 'A mechanism of injury is required'
            }
          ]}
        >
          <Select
            options={injuryMechanismOptions}
            {...selectProps}
            placeholder="Mechanism of Injury"
          />
        </Form.Item>
      )
    }
    // {
    //   component: () => <>{/* <Form.Item name={'image'}>

    //   <Upload
    //         maxCount={1}
    //         beforeUpload={onBeforeUpload}
    //         // fileList={fileList}
    //         listType="picture"
    //         onChange={onProfileImageChange}
    //         customRequest={customRequest}
    //       >
    //         <Button icon={<UserFilled />} style={{ border: 'none' }}>
    //           Upload image
    //         </Button>
    //       </Upload>
    //     </Form.Item> */}</>
    // }
  ];

  return (
    <Form
      className="profile-form"
      name="patient_profile"
      layout="vertical"
      onValuesChange={(...args) =>
        handleValuesChange(...args, formValue, onChange)
      }
      initialValues={{ ...formValue }}
      onFinish={onDone}
    >
      <Row gutter={ROW_GUTTER}>
        {questions.map((val, index) => (
          <Col {...oneThirdCol} key={index}>
            <val.component />
          </Col>
        ))}

        {/* Impact location */}
        <ImpactLocationInput value={formValue} />
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col {...oneThirdCol} />
        <Col {...oneThirdCol} />
        <Col
          {...oneThirdCol}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem'
          }}
        >
          {onDone && (
            <DarkButton
              style={{ marginTop: '40px' }}
              type="primary"
              htmlType="submit"
              loading={props.submitLoading}
            >
              Done
            </DarkButton>
          )}
        </Col>
      </Row>
    </Form>
  );
}

/**
 * Handle form change events
 * @param changedValue A single key-value pair
 * @param changedValues All the key-value pairs in the form
 * @returns
 */
function handleValuesChange(
  changedValue: Record<string, any>,
  changedValues: Record<string, any>,
  formValue: PatientProfile2 | undefined,
  onChange: (val: PatientProfile2) => void | any
) {
  let newValue: Partial<PatientProfile2> | undefined;

  // Get the name of the changed value
  const key = Object.keys(changedValue)[0];
  if (key === 'image') return;

  // The birthdate is changed
  if (key === PatientProfileKey.birthDate) {
    // WARN: If changedValue is in changedValues then is this always true?
    if (changedValues[key]) {
      const birthDate = changedValues[key].startOf('day').toDate();
      newValue = { ...formValue, [key]: birthDate };
    } else {
      clear(changedValues, [key]);
      newValue = omit(formValue, [key]);
    }
  }

  // The injury date is changed
  // TODO: Refactor this to keep birthDate and injuryDate logic DRY
  if (key === PatientInjuryKey.injuryDate) {
    if (changedValues[key]) {
      const injuryDate = changedValues[key].startOf('day').toDate();
      newValue = { ...formValue, [key]: injuryDate };
    } else {
      clear(changedValues, [key]);
      newValue = omit(formValue, [key]);
    }
  }

  // WARN: Unreachable because of an earlier exit when key === "image"
  if (key === 'image') {
    /**
     * check if firebase storage already has profile image for user
     * overwrite it if it exists
     * we upload to firebase storage
     * update auth user profileUrl to point at firebase storage
     * we don't have to save it into collections.
     */
    return;
  }
  onChange(
    newValue
      ? newValue
      : handleFormChange(
          changedValue,
          changedValues,
          formValue,
          keyDependency,
          keyCondition
        )
  );
}
