/**
 * Calculate the needle angle of the gauge to represent a value
 *
 * @param value
 * @param lValue
 * @param rValue
 * @param minAngle
 * @param maxAngle
 * @returns
 */
export function calculateValueAngle(
  value: number,
  lValue: number,
  rValue: number,
  minAngle: number,
  maxAngle: number
) {
  const spanAngle = maxAngle - minAngle;
  const cappedValue = value;
  const percentage = (cappedValue - lValue) / (rValue - lValue);

  const valueAngle = minAngle + spanAngle * percentage;

  return valueAngle;
}
