import { ImPACTSlice } from './impactAPISlice';
import { getImpactTestURL } from 'api/firebase';
import { TestLaunchURLParameters } from 'api/impact/getTestLaunch';

export async function prepareImPACTTest(props: {
  impactInfo: Pick<
    ImPACTSlice,
    | 'firstName'
    | 'lastName'
    | 'nextTestToComplete'
    | 'birthdate'
    | 'verified'
    | 'passportId'
  >;
  existingPassportId?: string | null | -1;
}) {
  const action: TestLaunchURLParameters = {
    firstName: props.impactInfo.firstName ?? '',
    lastName: props.impactInfo.lastName ?? '',
    testType: props.impactInfo.nextTestToComplete,
    ...(props.impactInfo.birthdate && {
      dateOfBirth: props.impactInfo.birthdate
    })
  };

  /**
   * Set the passport ID
   */
  if (props.impactInfo.verified) {
    // The DB has a passportId saved for this user
    action.passportID = props.impactInfo.passportId ?? '';
  }
  if (props.existingPassportId !== -1 && props.existingPassportId !== null) {
    // The user is importing a passport Id
    action.passportID = props.existingPassportId;
  }

  return await getImpactTestURL(action).then(_ => _.data);
}
