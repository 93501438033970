import { useEffect } from 'react';

import { Layout } from 'antd';
import { Form } from 'components';

import { useReducer } from 'hooks/useReducer';

import { useDispatch, useSelector } from 'react-redux';
import pcsInventories, {
  initialLoad,
  initialState,
  NOT_SELECTED,
  PcsInventory,
  pcsOrder,
  submit
} from './pcsSlice';

import { RootState } from 'app/rootReducer';

import { RadioButtonListItem } from 'features/patient/outcome-measures/cgs/radio-button-list';

import { OutcomeMeasuresHeader } from 'components/header';
import { Inventory } from 'documents';

import { FormattedSection } from '../paginationSlice';

import { setFaqObject } from 'features/navigation/navigationSlice';
import { splitChunks } from '../../../../utils/splitChunks';
import { PaginationCombined } from './pcs-pagination-combined';
import { cleanPagedQuestionnaireStorage } from 'features/patient/assessment/paged-questionnaire';
function getItems(pcs?: Inventory): RadioButtonListItem[] {
  if (!pcs) return [];
  return pcsOrder.map(x => ({ title: x, value: pcs.data[x] }));
}

export function PatientPcs() {
  const user = useSelector((state: RootState) => state.auth.user);
  const faqDispatch = useDispatch();
  // const progressBarDispatch = useDispatch();
  const [{ pcs, lastUpdated }, dispatch] = useReducer(
    pcsInventories,
    initialState
  );

  // create pcsrepo
  useEffect(() => {
    dispatch(initialLoad(user!.uid));
    faqDispatch(
      setFaqObject({
        min: pcs.min,
        max: pcs.max,
        description: pcs.description,
        instructions: pcs.instructions
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChanged = Object.values(pcs.data).some(value => value > NOT_SELECTED);
  const pcsLength = Object.keys(pcs.data).length;

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <OutcomeMeasuresHeader
        label={'Exit'}
        title="3rd Questionnaire"
        onExit={() => {
          cleanPagedQuestionnaireStorage('questionnaire-pcs');
        }}
      />
      <Form
        isChanged={isChanged}
        lastUpdated={lastUpdated}
        onSubmit={() => dispatch(submit())}
        showFooter={false}
      >
        {pcsLength > 0 && (
          <PaginationCombined storageKey={'questionnaire-pcs'} />
        )}
        {/*<FormModal
          visible={showModal}
          onClose={() => dispatch(closeModal())}
          onGoto={() => reduxDispatch(push(Routes.PatientOutcomeMeasures))}
          gotoLabel="Proceed to next step"
        />*/}
      </Form>
    </Layout>
  );
}

/**
 * purpose is to standardize `paginationCombined` data intake;
 * @param pcsData
 * @param pageLimit
 * @returns
 */
export function formatPcs(pcsData: PcsInventory, pageLimit: number) {
  const ret: FormattedSection[] = [];
  const questions: RadioButtonListItem[] = [];

  // Transform the `pcsData.data` question object into an array of questions
  Object.keys(pcsData.data).forEach(key =>
    questions.push({ title: key, value: pcsData.data[key] })
  );

  const obj: FormattedSection = {
    description: pcsData.description,
    instructions: pcsData.instructions,
    min: pcsData.min,
    max: pcsData.max,
    type: 'button',
    questions: getItems(pcsData),
    page: splitChunks(questions, pageLimit),
    beforeLabel: pcsData.beforeLabel,
    afterLabel: pcsData.afterLabel,
    colorsForward: pcsData.colorsForward,
    iconsForward: pcsData.iconsForward,
    pageLength: 0
  };

  obj.pageLength = obj.page.length;
  ret.push(obj);
  return ret;
}
