import { ConcussionComplexity } from './concussion-complexity';

/**
 * Copied from https://github.com/concussionrx/concussiontool/blob/e0ddf744168ec1a1b266e09209dc0f0becda69a6/critical_areas.py
 */
export enum CriticalAreas {
  PAIN = 'Pain',
  MOBILITY = 'Mobility',
  MOOD = 'Mood',
  COGNITION = 'Cognition',
  SLEEP = 'Sleep',
  DIZZINESS = 'Dizziness',
  FATIGUE = 'Fatigue',
  MEMORY = 'Memory',
  VISUAL_MOTOR_SPEED = 'Visual Motor Speed',
  REACTION_TIME = 'Reaction Time'
}

/**
 * Maps a concussion complexity to critical areas the patient should be aware of
 *
 * Copied from https://github.com/concussionrx/concussiontool/blob/e0ddf744168ec1a1b266e09209dc0f0becda69a6/complexities.py
 */
export const complexityToAreaMap: Record<
  ConcussionComplexity,
  { high: CriticalAreas[]; medium: CriticalAreas[] }
> = {
  [ConcussionComplexity.High]: {
    high: [
      CriticalAreas.PAIN,
      CriticalAreas.MOBILITY,
      CriticalAreas.MOOD,
      CriticalAreas.COGNITION,
      CriticalAreas.SLEEP,
      CriticalAreas.DIZZINESS,
      CriticalAreas.FATIGUE
    ],
    medium: [
      CriticalAreas.MEMORY,
      CriticalAreas.VISUAL_MOTOR_SPEED,
      CriticalAreas.REACTION_TIME
    ]
  },
  [ConcussionComplexity.Moderate]: {
    high: [
      CriticalAreas.PAIN,
      CriticalAreas.MOBILITY,
      CriticalAreas.SLEEP,
      CriticalAreas.MEMORY,
      CriticalAreas.FATIGUE,
      CriticalAreas.DIZZINESS
    ],
    medium: [
      CriticalAreas.VISUAL_MOTOR_SPEED,
      CriticalAreas.REACTION_TIME,
      CriticalAreas.COGNITION,
      CriticalAreas.MOOD
    ]
  },
  [ConcussionComplexity.Minimal]: {
    high: [CriticalAreas.PAIN],
    medium: [
      CriticalAreas.MOBILITY,
      CriticalAreas.MOOD,
      CriticalAreas.SLEEP,
      CriticalAreas.FATIGUE
    ]
  },
  [ConcussionComplexity.Mild]: {
    high: [
      CriticalAreas.PAIN,
      CriticalAreas.MOBILITY,
      CriticalAreas.MOOD,
      CriticalAreas.SLEEP,
      CriticalAreas.FATIGUE
    ],
    medium: [CriticalAreas.COGNITION, CriticalAreas.DIZZINESS]
  },
  [ConcussionComplexity.Extreme]: {
    high: [
      CriticalAreas.PAIN,
      CriticalAreas.MOBILITY,
      CriticalAreas.MOOD,
      CriticalAreas.COGNITION,
      CriticalAreas.SLEEP,
      CriticalAreas.MEMORY,
      CriticalAreas.VISUAL_MOTOR_SPEED,
      CriticalAreas.REACTION_TIME,
      CriticalAreas.DIZZINESS,
      CriticalAreas.FATIGUE
    ],
    medium: []
  }
};
