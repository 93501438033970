import React from 'react';

import { Form } from 'antd';
import { PatientPersonalHistoryKey } from 'documents';

import { SubstanceInput } from './SubstanceInput';

const formItems = [
  { label: 'Alcohol', name: PatientPersonalHistoryKey.alcohol },
  { label: 'Smoking/Vaping', name: PatientPersonalHistoryKey.smoking },
  { label: 'Marajuana', name: PatientPersonalHistoryKey.marajuana },
  {
    label: 'Other Substance Use',
    name: PatientPersonalHistoryKey.otherSubstance
  }
];

export const SubstanceForm = () => {
  return (
    <>
      {formItems.map((item, index) => (
        <Form.Item key={index} name={item.name}>
          <SubstanceInput label={item.label} />
        </Form.Item>
      ))}
    </>
  );
};
