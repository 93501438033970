import { Button, ButtonProps, ConfigProvider, Upload, UploadProps } from 'antd';

export function UploadImageButton(
  props: { text: string } & Pick<ButtonProps, 'icon'> &
    Pick<UploadProps, 'beforeUpload' | 'onChange' | 'customRequest'>
) {
  return (
    <ConfigProvider
      prefixCls="crx-upload-button"
      theme={{
        components: {
          Button: {
            colorBgBase: 'white',
            boxShadow: 'none'
          }
        }
      }}
    >
      <Upload
        listType="picture"
        maxCount={1}
        accept="image/png, image/jpeg, .webm"
        beforeUpload={props.beforeUpload ?? (() => false)}
        onChange={props.onChange}
        customRequest={props.customRequest}
      >
        <Button
          icon={props.icon}
          style={{ border: 'none', background: 'none' }}
        >
          {props.text}
        </Button>
      </Upload>
    </ConfigProvider>
  );
}
