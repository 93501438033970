import { Row } from 'antd';
import React from 'react';
import LogoSq from 'img/Logo Square.png';
import RxImg from 'img/Rx.png';
import { PrescriptionPadData } from './types';
import './prescription-pad.scss';

function generateASCIIUnderline(s: string, limit: number) {
  limit = limit || 70;
  const diff = limit - s.length;
  if (diff > 0) {
    return s + new Array(Number(diff)).fill('\xa0').join('');
  }
  return s;
}

interface Props {
  data: PrescriptionPadData;
}

export class PrescriptionPadDocument extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { data } = this.props;

    return (
      <div className="pdf-container page-break">
        <div className="header">
          <div>
            <img src={RxImg} />
          </div>
          <div className="prescription-title">
            <h1>PRESCRIPTION</h1>
          </div>
          <div className="img-container">
            <img src={LogoSq} width={'50px'} />
          </div>
        </div>

        <div className="border-line" />

        <div className="content-container">
          <Row className="name-age-row">
            <div className="name-row">
              <span className="no-underline">
                Patient Name:{' '}
                <span className="underline">
                  {generateASCIIUnderline(data.name, 40)}
                </span>
              </span>
            </div>
            <div className="age-row">
              <span className="no-underline">
                Age:{' '}
                <span className="underline">
                  {generateASCIIUnderline(data.age, 50)}
                </span>
              </span>
            </div>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ fontSize: '1.5rem', lineHeight: '1.5rem' }}>
              {data.treatments.map((treatment, i) => (
                <>
                  <span key={i}>{treatment.category.toUpperCase()}</span>
                  <div style={{ padding: '20px 0px 10px 0px' }}>
                    {' '}
                    Please assess:{' '}
                  </div>
                  <ul>
                    {treatment.items.map((item, itemIndex) => (
                      <li key={'item ' + itemIndex}>{item.name}</li>
                    ))}
                  </ul>
                </>
              ))}

              {
                /**
                 * Render extra content if it exists
                 */
                data.extraContent && <p>{data.extraContent}</p>
              }
            </div>
          </div>
        </div>

        {/* <div className="border-line" style={{ paddingTop: '120px' }}/> */}

        <div className="pdf-footer">
          <Row className="signature-date-row" justify="space-between">
            <span className="doctor-signature">
              Doctor&apos;s signature:&nbsp;
              <span className="underline">
                {generateASCIIUnderline('', 50)}
              </span>
            </span>
            <span className="doctor-sign-date">
              Date:&nbsp;
              <span className="underline">
                {generateASCIIUnderline(data.date, 45)}{' '}
              </span>
            </span>
          </Row>
        </div>
      </div>
    );
  }
}
