import React from 'react';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, Modal, Row } from 'antd';

import { Button } from 'antd';

import FaqModalPic from 'img/faq-modal-rating.svg';
import './Modals.scss';

const getItems = (panelStyle: {
  marginBottom: number;
  background: string;
  fontSize: string;
  '--header-border': string;
}) => [
  {
    key: '1',
    label: 'Why are there so many tests?',
    children: (
      <p>
        These tests were researched and have been standardized to provide
        accurate results . Keep in mind, if you do these tests in person, you’d
        have to visit multiple doctors and schedule each test individually. Our
        goal is to help you save both time and money by allowing you to conduct
        these tests conveniently in the comfort of your own home.
      </p>
    ),
    style: panelStyle
  },
  {
    key: '2',
    label: 'How often do the patients need to take the assessments?',
    children: <p>Every 30 days</p>,
    style: panelStyle
  },
  {
    key: '3',
    label: 'Why do I have to re-take the questionnaires every 30 days?',
    children: (
      <p>
        By retaking the assessment every 30 days, you and your clinician can
        keep track of your recovery and make sure it is progressing as wanted
      </p>
    ),
    style: panelStyle
  }
];

// const getHeader = (panel) => {
//   console.log("getHeader panel: ", panel);
//   return (
//     <div>
//       This is a test
//     </div>
//   )
// }

function CustomExpandIcon(props: { isActive?: boolean }) {
  console.log('customExpandIcon: ', props);
  return props.isActive ? <UpOutlined /> : <DownOutlined />;
}

function FaqContent(props: { onClose: () => void }) {
  // const token = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: 'white',
    fontSize: '20px',
    ['--header-border']: '1px solid black'
  };

  return (
    <div style={{ marginTop: '25px' }}>
      <Row justify="center" style={{ fontFamily: 'Comfortaa' }}>
        <h3>FAQ</h3>
      </Row>
      <Collapse
        accordion
        expandIconPosition={'end'}
        expandIcon={props => <CustomExpandIcon isActive={props.isActive} />}
        bordered={false}
        items={getItems(panelStyle)}
        defaultActiveKey={['1']}
        style={{
          marginTop: '25px',
          maxWidth: '684px',
          fontFamily: 'Comfortaa',
          fontSize: '16px'
        }}
      />

      <Row justify="center">
        <BigDarkButton
          fontSize={16}
          onClick={props.onClose}
          label={'Ok, I understand!'}
        />
      </Row>
    </div>
  );
}

interface FaqModalProps {
  showModal: boolean;
  onClose: () => void;
  content?: React.ReactElement;
}

export const FaqModal = ({
  showModal,
  onClose = () => {
    console.log('asoidjoiasjdfioajweiofjaweiofawioejoiawejfioawej');
  }
}: // content = <FaqContent />
FaqModalProps) => {
  return (
    <Modal
      open={showModal}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
    >
      <FaqContent onClose={onClose} />
    </Modal>
  );
};

interface ModalExitConfirmationProps {
  showModal: boolean;
  onClose: () => void;
  onCancel: () => void;
  // content?: React.ReactElement;
}

export const ModalExitOnConfirmation = (props: ModalExitConfirmationProps) => {
  return (
    <Modal
      style={{
        minWidth: '900px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      open={props.showModal}
      maskClosable={false}
      onCancel={props.onCancel}
      footer={null}
    >
      <div style={{ marginTop: '40px', maxWidth: '600px' }}>
        <Row justify="center" style={{ fontFamily: 'Comfortaa' }}>
          <h1 className="exitOnConfirmation__title">
            Your progress will be lost if you exit now!
          </h1>
        </Row>

        <p className="exitOnConfirmation__description">
          We don’t allow saving progress at any time to maintain medical
          accuracy. However, you’re just a few more questions away from the
          midpoint where you can save current progress. How about answering a
          few more?
        </p>

        <Row justify="center" style={{ gap: '24px', marginBottom: '40px' }}>
          {/*<DarkButton fontSize={20} maxHeight={60} maxWidth={267} onClick={onClose} label={"Discard answers & exit"} />          */}
          {/*<Button primary danger shape="round">Discard answers & exit</Button>*/}
          <CommonButton
            backgroundColor={'#FC615B'}
            label={'Discard answers & exit'}
            onClick={props.onClose}
          />
          <BigDarkButton
            fontSize={20}
            onClick={props.onCancel}
            label={'I can do a few more'}
          />
        </Row>
      </div>
    </Modal>
  );
};

interface ModalSaveAndExitProps {
  showModal: boolean;
  onClose: () => void;
  // timeRemaining?: Moment();
}

import { useAppSelector } from 'app/rootReducer';
import { BigDarkButton } from 'components/big-dark-button';
import ClockLimit from 'img/clock-time-limit.svg';

export const ModalSaveAndExitReminder = ({
  showModal,
  onClose
}: ModalSaveAndExitProps) => {
  // showModal = true;
  // import ClockLimit from 'img/clock-time-limit.svg';
  console.log('modalSaveAndExitReminder: ', showModal);
  return (
    <Modal
      style={{
        minWidth: '900px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      open={showModal}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ marginTop: '40px', maxWidth: '600px' }}>
        <Row justify="center" style={{ fontFamily: 'Comfortaa' }}>
          <h1 className="saveAndExitReminder__title">
            Remember to come back and finish within the time limit!
          </h1>
        </Row>

        <p className="saveAndExitReminder__description">
          Keep in mind that there is a time limit due to Lorem ipsum. If you
          don’t finish before the following deadline, your current answers will
          be erased.
        </p>
        <Row justify="center">
          <img className="saveAndExitReminder__image" src={ClockLimit} />
        </Row>

        <div className="saveAndExitReminder__time">
          <Row className="saveAndExitReminder__remaining" justify="center">
            Time Remaining:
          </Row>
          <Row justify="center">
            <h2 className="saveAndExitReminder__duration">2 Days</h2>
          </Row>
        </div>

        <Row justify="center" style={{ gap: '24px', marginBottom: '40px' }}>
          <BigDarkButton
            fontSize={20}
            onClick={() => onClose}
            label={"Ok, I'll remember!"}
          />
        </Row>
      </div>
    </Modal>
  );
};

interface ModalRatingInstructionAndFaqsProps {
  min: number;
  max: number;
  instructions: string;
  description: string;
  showModal: boolean;
  onClose: () => void;
}
/*
  How are we going to pass content into this modal?
  Through global reducer rootReducer
*/
export function ModalRatingInstructionAndFaqs({
  showModal,
  onClose
}: Pick<ModalRatingInstructionAndFaqsProps, 'showModal' | 'onClose'>) {
  const faqModalPref = useAppSelector(state => state.faqModalPref);
  // console.log("hope you enjoy your stay: ", faqModalPref);
  const { minRating, maxRating, instructions, description } = faqModalPref;
  return (
    <ModalRatingInstructionAndFaqItems
      min={minRating}
      max={maxRating}
      instructions={instructions ?? ''}
      description={description ?? ''}
      showModal={showModal}
      onClose={onClose}
    />
  );
}

export const ModalRatingInstructionAndFaqItems = ({
  min,
  max,
  instructions = 'Empty instructions',
  description = 'Empty description',
  showModal,
  onClose
}: ModalRatingInstructionAndFaqsProps) => {
  return (
    <Modal
      style={{
        minWidth: '900px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      open={showModal}
      maskClosable={false}
      onCancel={onClose}
      // onClose={onClose}
      footer={null}
    >
      <div
        style={{
          marginTop: '40px',
          maxWidth: '684px',
          // paddingHorizontal: '118px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h2 style={{ fontFamily: 'Comfortaa' }}>
          Rate from {min} to {max}
        </h2>
        {instructions != 'Empty instructions' && (
          <p style={{ fontSize: '16px', fontFamily: 'Comfortaa' }}>
            {instructions}
          </p>
        )}
        {description != 'Empty description' && (
          <p style={{ fontSize: '16px', fontFamily: 'Comfortaa' }}>
            {description}
          </p>
        )}
        <img style={{ objectFit: 'contain' }} src={FaqModalPic} />
      </div>
    </Modal>
  );
};

interface ButtonProps {
  backgroundColor?: string;
  onClick: () => void;
  label: string;
  color?: string;
  minHeight?: number;
  minWidth?: number;
  fontSize?: number;
  fontFamily?: string;
}

export const CommonButton = ({
  backgroundColor = undefined,
  onClick,
  label,
  color = 'white',
  minHeight = 60,
  fontSize = 20,
  fontFamily = 'Comfortaa'
}: ButtonProps) => {
  return (
    <Button
      shape="round"
      size="large"
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor,
        color: color,
        fontFamily: fontFamily,
        minHeight: minHeight + 'px',
        fontSize: fontSize
      }}
    >
      {label}
    </Button>
  );
};
