import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push, replace } from 'connected-react-router';
import { PatientRoutes, Routes } from 'routes';

import {
  addClinicianRole,
  addPatientRole,
  auth,
  storage
} from 'api/firebase/firebase-api';

// only used for typehinting
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { updateProfile } from 'firebase/auth';

import { AppThunk } from 'app/store';
import { getPatientLinkUrl } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { Role } from './types';

const storageRef = storage.ref();
const temporaryValues = {
  refreshedTokenAfterMissingClaims: false
};
export interface User {
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  uid: string;
  role: Role;
  photoURL?: string | null;
  needsPassword: boolean;
}

export interface AuthState {
  isUserDataLoading: boolean;
  isAuthActionActive: boolean;
  user: User | null;
  error: string | null;
}

export const initialState: AuthState = {
  isUserDataLoading: true,
  isAuthActionActive: false,
  user: null,
  error: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn(state, action: PayloadAction<User>) {
      state.isUserDataLoading = false;
      state.error = null;
      state.user = action.payload;
    },

    signOut(state) {
      state.isUserDataLoading = false;
      state.user = null;
      state.error = null;
    },

    /**
     * Set a flag that something in the auth flow is loading
     */
    startLoading(state) {
      state.isUserDataLoading = true;
    },

    stopLoading(state) {
      state.isUserDataLoading = false;
    },

    startLoadingAction(state) {
      state.isAuthActionActive = true;
    },
    stopLoadingAction(state) {
      state.isAuthActionActive = false;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      // state.isLoading = false;
    }
  }
});

export const { signIn, setError, startLoading, stopLoading } =
  authSlice.actions;
export const AuthSliceActions = authSlice.actions;
export const AuthSliceReducer = authSlice.reducer;
export default authSlice.reducer;

export const sendPasswordReset =
  (email: string): AppThunk =>
  async dispatch => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch(push('/'));
      })
      .catch(error => {
        console.log(
          'An error occurred while sending a passwordResetEmail',
          error
        );
      });
  };

/**
 * Action to upload a profile image
 *
 * @deprecated
 *
 * @param file
 * @returns
 */
export const uploadProfileImage =
  (file: File): AppThunk =>
  async () => {
    // dispatch, getState
    if (file === null) return; // no file uploaded

    // get current user auth
    const currentUser = auth.currentUser;
    if (currentUser === null) return; // no current user logged in

    // filename scheme shoudl just be profile_image_uid in the profile_images directory and that's it we keep it simple
    const fileNameScheme = `profile_image_${currentUser.uid}.jpg`;
    const uploadPath = storageRef.child('profile_images/' + fileNameScheme);

    uploadPath
      .put(file)
      .then(snapshot => {
        // // update auth object to save fullpath of snapshot
        snapshot.ref.getDownloadURL().then(url => {
          // console.log("url: ", url);
          updateProfile(currentUser, {
            photoURL: url
          })
            .then(() => {
              // need to reload user to get changes in photoURL to propagate after setting
              currentUser.reload();
            })
            .catch(error => {
              console.log('updateProfile errored: ', error);
            });
        });
      })
      .catch(error => {
        console.log('upload error: ', error);
      });
  };

export const restoreAuth =
  (auth: firebase.auth.Auth): AppThunk =>
  async (dispatch, getState) => {
    /**
     * triggers on token id change, signin, signout(?)
     */
    async function onIdTokenChanged(firebaseUser: firebase.User | null) {
      try {
        if (firebaseUser === null) {
          dispatch(authSlice.actions.stopLoading());
          return;
        }

        // Get the ID token
        const token = await firebaseUser.getIdTokenResult();

        /**
         * This can happen if:
         * 1. It is a new user and the initUser API failed or is still executing
         * 2. It is an existing user and the claims are missing or removed
         */
        if (!token.claims.role) {
          console.warn(
            `Token claims for user ${firebaseUser.uid} are missing. This can be caused by a network delay`,
            token.claims
          );

          /**
           * Fetch the latest ID token once
           */
          if (!temporaryValues.refreshedTokenAfterMissingClaims) {
            await auth.currentUser?.getIdToken(true);
            temporaryValues.refreshedTokenAfterMissingClaims = true;
          }

          return;
        } else {
          temporaryValues.refreshedTokenAfterMissingClaims = false;
        }

        const user: User = {
          displayName: firebaseUser.displayName,
          email: firebaseUser.email,
          phoneNumber: firebaseUser.phoneNumber,
          uid: firebaseUser.uid,
          role: token.claims.role,
          photoURL: firebaseUser.photoURL,
          needsPassword: token.claims.needsPassword ?? false
        };

        // Save the user data into the app state
        dispatch(authSlice.actions.signIn(user));

        /**
         * check if email is verified for user
         * timer to refresh user every two seconds to listen for emailVerification
         */
        const emailVerified = firebaseUser.emailVerified;

        // If the user is not verified
        if (emailVerified === false) {
          dispatch(goToVerifyEmail());
          return;
        }
      } catch (error) {
        dispatch(authSlice.actions.setError((error as Error).message));
      }
    }

    auth.onIdTokenChanged(onIdTokenChanged);
    auth.onAuthStateChanged(user => {
      // The user isn't signed in or something failed to load
      if (!user) {
        dispatch(authSlice.actions.stopLoading());
        return;
      }

      // TODO: test where we can take into onAuthStateChanged here;
      const currentPath = getState().router.location.pathname;

      /**
       * Redirect the user if the user has verified their email and if they are on the VerifyEmail page
       * TODO: Redirection logic should happen in the router
       */
      if (user && user.emailVerified && currentPath === Routes.VerifyEmail) {
        dispatch(push('/'));
      }
    });
  };

export const signUpClinician =
  (auth: firebase.auth.Auth): AppThunk =>
  async dispatch => {
    dispatch(authSlice.actions.startLoading());
    await addClinicianRole();
    await auth.currentUser?.getIdToken(true);
  };

// const getPatientLinkUrl = (route: PatientRoutes, patientId: string) =>
//   generatePath(`/patient/:patientId`, { patientId }) + route;

export const signUpPatient =
  (auth: firebase.auth.Auth): AppThunk =>
  async dispatch => {
    dispatch(authSlice.actions.startLoading());

    // I'm not sure about this change, tough now it looks like it changes path without wierd pause on sign-up
    await addPatientRole();
    await auth.currentUser?.getIdToken(true);

    dispatch(
      push(
        getPatientLinkUrl(
          PatientRoutes.PatientOnboardingProfile,
          auth.currentUser?.uid ?? ''
        )
      )
    );
  };

export const goToVerifyEmail = (): AppThunk => async dispatch => {
  dispatch(replace(Routes.VerifyEmail));
};
