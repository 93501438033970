import { Card, CardProps } from 'antd';
import clsx from 'clsx';
import './elevated-card.scss';

export function ElevatedCard({
  style,
  disabled,
  ...props
}: CardProps & { disabled?: boolean }) {
  /**
   * TODO: Pass a `disabled` property to all children.
   * This will solve cases when the card is disabled but children are still interactable
   */
  return (
    <Card
      {...props}
      bordered={false}
      style={{
        boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
        ...style,
        /* Change the opacity when the card is disabled */
        opacity: disabled ? 0.5 : 1
      }}
      className={clsx('elevated-card', props.className)}
    />
  );
}
