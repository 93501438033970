import { Input, Select } from 'antd';
import { FormItemProps } from 'antd/lib';
import { OPTIONS_PROVINCES } from './clinic';

interface FormQuestion {
  component: React.ReactElement | (() => React.ReactElement);
  rules?: FormItemProps['rules'];
  required?: FormItemProps['required'];
  name: FormItemProps['name'];
}
const CONTACT_EMAIL = 'contact@concussionrx.com';
export const questions: FormQuestion[] = [
  {
    name: 'name',
    component: <Input placeholder="Clinic Name" />
  },
  {
    name: 'country',
    component: (
      <Select
        showSearch
        placeholder="Country"
        options={[
          {
            label: 'Canada',
            value: 'canada'
          },
          {
            label: 'United States of America',
            value: 'usa'
          }
        ]}
      />
    ),
    rules: [
      {
        type: 'enum',
        required: true,
        enum: ['canada', 'usa'],
        message: `Please select a country. If your country is not listed please email ${CONTACT_EMAIL}`
      }
    ]
  },
  {
    name: 'provinceOrState',
    component: (
      <Select
        showSearch
        placeholder="Province/State"
        options={OPTIONS_PROVINCES}
      />
    ),
    rules: [
      {
        type: 'enum',
        required: true,
        enum: OPTIONS_PROVINCES.map(_ => _.value),
        message: `Please select your province or state. If your location is not listed please email ${CONTACT_EMAIL}`
      }
    ]
  },
  {
    name: 'city',
    component: <Input placeholder="City" />,
    rules: [
      {
        type: 'string',
        required: true,
        message: `Please enter your city`
      }
    ]
  },
  {
    name: 'address',
    component: <Input placeholder="Address" />,
    rules: [
      {
        type: 'string',
        required: true,
        message: 'Please enter the remainder of your clinic address'
      }
    ]
  },
  {
    name: 'postalOrZipCode',
    component: <Input placeholder={'Postal/Zip Code'} />,
    rules: [
      {
        type: 'string',
        required: true,
        message: 'Please enter your clinics postal code'
      },
      {
        pattern:
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
        message: 'The postal code is invalid'
      },
      {
        transform: (val: string) => val.toUpperCase().replace(/[ -]/, '')
      }
    ]
  },
  {
    name: 'phoneNumber',
    component: <Input placeholder="Phone Number" />,
    rules: [
      {
        type: 'string',
        required: true,
        message: 'Please enter your clinics phone number'
      }
    ]
  },
  {
    name: 'faxNumber',
    component: <Input placeholder="Fax Number" />,
    rules: [
      {
        type: 'string',
        message: 'Please enter your clinics fax number if you have one'
      }
    ]
  }
];
