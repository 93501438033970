import './Navigation.scss';

import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Layout, Skeleton } from 'antd';
import { desktopMinWidth } from 'constants/media-queries';

import { RootState } from 'app/rootReducer';
import { Role } from 'features/auth/types';

import { ClinicianMenu } from './menu-clinician';
import { PatientMenu } from './menu-patient';
import { toggleMenuCollapse } from './navigationSlice';
import { SideMenuFooter } from './menu-side-footer';

const { Sider } = Layout;

import ConcussionLogo from 'img/sidebar-logo.svg';

export function Navigation() {
  const user = useSelector((state: RootState) => state.auth.user);
  const collapsed = useSelector(
    (state: RootState) => state.uiPref.menuCollapsed
  );
  const isLoading = useSelector(
    (state: RootState) => state.auth.isUserDataLoading
  );
  const isDesktop = useMediaQuery(desktopMinWidth);
  const dispatch = useDispatch();
  if (!user || user.needsPassword) return <></>;

  return (
    <Sider
      trigger={null}
      theme="light"
      collapsedWidth={isDesktop ? 80 : 80}
      width={236}
      collapsible
      collapsed={collapsed}
      className="crx-sider"
    >
      <h2 className="logo">
        <img src={ConcussionLogo} />
      </h2>

      <div className="rx-sidemenu">
        <div>
          {user && [Role.Clinician, Role.ClinicAdmin].includes(user.role) && (
            <ClinicianMenu />
          )}
          {user && user.role === Role.Patient && <PatientMenu />}
        </div>
        {isLoading && <Skeleton />}

        <div>
          <SideMenuFooter
            collapsed={collapsed}
            onClick={() => {
              dispatch(toggleMenuCollapse());
            }}
            user={user}
          />
        </div>
      </div>
    </Sider>
  );
}
