import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from 'utils/redux';
import { invokeEnhancedTreatmentRecommendations } from 'api/firebase';
import { PatientInfoState } from '../patient-info-slice-types';
import { patientInfoInitialLoad } from '../patientInfoSlice';
import { resolveError } from 'utils/formatters/error/resolve-error';

export const generateEnhancedTreatmentRecommendations = createAsyncThunk<
  void,
  void,
  ThunkApi<PatientInfoState>
>(
  'patientInfo/generateEnhancedTreatmentRecommendations',
  async (_: void, state) => {
    const s = state.getState();
    if (s.patient.id === undefined) {
      throw new Error(
        `[generateEnhancedTreatmentRecommendations] patientId is undefined`
      );
    }

    // Generate the recommendations
    await invokeEnhancedTreatmentRecommendations(s.patient.id).catch(reason => {
      const error = resolveError(reason);
      error.message =
        `[invokeEnhancedTreatmentRecommendations] Failed to generate treatment recommendations. ` +
        `Error: ${reason.message}`;
      throw error;
    });

    // Fetch the new data
    state.dispatch(patientInfoInitialLoad(s.patient.id));
  }
);
