import { Document } from './document';

// API parameters enums for impactAPI
export enum TestType {
  BASELINE = 'Baseline',
  POST_INJURY_ONE = 'Post-Injury 1',
  POST_INJURY_TWO = 'Post-Injury 2',
  POST_INJURY_THREE = 'Post-Injury 3',
  POST_INJURY_FOUR = 'Post-Injury 4'
}

export enum Locale {
  EN_US = 'en-US'
}

export enum LastTestCompleted {
  NONE = 0,
  POST_INJURY_ONE = 1,
  POST_INJURY_TWO = 2,
  POST_INJURY_THREE = 3,
  POST_INJURY_FOUR = 4
}

export interface PatientImpactProfile extends Document {
  verified: boolean;
  passportId: string;
  testTakerId: string;
  organizationId: number;
  lastTestCompleted: number;
}

// expected parameters from impact
// decoded impactJwt
export interface ImpactJwt {
  adminID: string;
  iat: string | number;
}

export interface BaseRequest {
  locationCode: string;
  apiKey: string;
  timeStamp: string;
  signature: string;
}

export interface GetOrgRequest extends BaseRequest {
  token: string;
}

export interface TestUrlRequest extends BaseRequest {
  testType: TestType;
  adminID: string;
  orgID: string;
  redirectURL: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
}

export interface GetTestTakerProfileRequest extends BaseRequest {
  passportID: string;
  token: string;
  systemWide?: number;
  passportUserRequirementsEnforced?: number;
  passportOrgRequirementsEnforced?: number;
}

export interface GetTestRecordRequest extends BaseRequest {
  token: string;
  orgIDs?: string; // comman delimited list of org ids to get test records for
  testTakerID?: number;
  archived?: number;
  startDate?: string; // ISO 8601 date-time
  endDate?: string; // ISO 8601 date-time
  manageableOrgsOnly?: number;
  product?: string;
  desiredRecordFields?: string;
}
