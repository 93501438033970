import { List } from 'antd';
import { CircularRating, SliderRating } from 'components';
import { Title } from 'components/mvp-typography';
import { RadioButtonListProps } from './RadioButtonList';

export function QuestionnaireFormInput({
  items,
  min = 0,
  max = 6,
  onChange,
  beforeLabel = 'BeforeLabel',
  afterLabel = 'AfterLabel',
  type,
  colorsForward,
  iconsForward
}: RadioButtonListProps & {
  onChange: (data: { title: string; value: number }) => void | any;
}) {
  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {
        /**
         * Unknown property
         */
        (items[0] as any).section
      }
      <List
        className="listcircle-rbl"
        itemLayout="vertical"
        dataSource={items}
        split={true}
        renderItem={(item, i) => {
          return (
            <List.Item
              key={i}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                marginBottom: '30px',
                borderBottom: 'none'
              }}
            >
              <Title level={2} style={{ fontWeight: 'bold' }}>
                {item.visibleTitle ? item.visibleTitle : item.title}
              </Title>

              {type === 'slider' ? (
                <SliderRating
                  value={item.value}
                  onChange={value => onChange({ ...item, value })}
                  min={min}
                  max={max}
                  beforeLabel={beforeLabel}
                  afterLabel={afterLabel}
                />
              ) : (
                <CircularRating
                  value={item.value}
                  onChange={value => onChange({ ...item, value })}
                  min={min}
                  max={max}
                  buttonsCount={max}
                  manualInput={false}
                  beforeLabel={beforeLabel}
                  afterLabel={afterLabel}
                  colorsForward={colorsForward}
                  iconsForward={iconsForward}
                />
              )}
            </List.Item>
          );
        }}
      />
    </div>
  );
}
