export type DynamicOrStaticProperty<OutType, Args> =
  | OutType
  | ((a: Args) => OutType);
export function resolveDynamicOrStaticProperty<OutType, Args>(
  property: DynamicOrStaticProperty<OutType, Args>,
  args: Args
): OutType {
  if (typeof property === 'function') {
    return (property as (a: Args) => OutType)(args);
  }
  return property;
}
