import { Col, Flex, Form, Result, Row, Spin } from 'antd';
import {
  clinicInfoEdit,
  clinicInfoEditRequestImageUploadURL,
  storage
} from 'api/firebase/firebase-api';
import { DarkButton } from 'components/dark-button';
import { Title } from 'components/mvp-typography';
import { UserFilledIcon } from 'components/ProfileForm/user-filled-icon';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { useState } from 'react';
import { oneThirdCol, ROW_GUTTER } from 'utils/antd';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { useClinicianInfo } from '../clinician-home-v2/useClinicianInfo';
import { UploadImageButton } from './button-upload-image';
import { clinicInfoSchema } from './clinic';
import { questions } from './form-questions';
import { useClinicInfo } from './useClinicInfo';

enum State {
  NEUTRAL,
  LOADING,
  SUCCESS
}

/**
 * This component allows ClinicAdmins to edit clinic information
 */
export default function ClinicInfoEdit() {
  const [state, setState] = useState<State>(State.NEUTRAL);
  const [error, setError] = useState<Error | null>(null);
  const clinicianInfo = useClinicianInfo();
  const { clinicInfo, refreshClinicInfo } = useClinicInfo(
    clinicianInfo?.clinics[0]
  );

  async function onFormSubmit(values: Record<string, any>) {
    // Return early if the clinic information is not ready
    if (!clinicInfo) return;
    setState(State.LOADING);
    setError(null);
    try {
      const { logo, ...otherValues } = values;
      const clinic = clinicInfoSchema.parse({
        ...otherValues,
        id: clinicInfo.clinic.id
      });

      // If the logo is specified
      if (logo) {
        await uploadLogo(clinic.id, logo.file);
      } else {
        // The logo is unset
        // Delete the data in the database
        await deleteLogo(clinic.id);
      }

      // Save the data
      await clinicInfoEdit(clinic);
      await refreshClinicInfo();
    } catch (e) {
      const error = resolveError(e);
      setError(error);
    }

    setState(State.SUCCESS);

    async function uploadLogo(clinicId: string, logo: File) {
      if (!logo) throw new Error('The logo was not loaded');
      try {
        const {
          data: { url: uploadURL }
        } = await clinicInfoEditRequestImageUploadURL({
          clinicId: clinicId,
          fileType: 'png'
        });
        const ref = storage.refFromURL(uploadURL);
        await ref.put(logo);
      } catch (e) {
        const error = resolveError(e);
        const newError = new Error(`Failed to upload logo. ${error.message}`);
        newError.cause = error;
        throw newError;
      }
    }

    async function deleteLogo(clinicId: string) {
      const {
        data: { url: uploadURL }
      } = await clinicInfoEditRequestImageUploadURL({
        clinicId: clinicId,
        fileType: 'png'
      });
      const ref = storage.refFromURL(uploadURL);
      try {
        await ref.delete();
      } catch (e) {
        const error = resolveError(e);
        if (error.message.includes('does not exist')) {
          return;
        }
        throw error;
      }
    }
  }
  if (clinicInfo === null) return <Spin spinning />;
  return (
    <Flex
      vertical
      justify={'center'}
      align={'center'}
      style={{
        padding: '0 4rem',
        height: '100%'
      }}
      gap={'large'}
    >
      <Title>Lets Set Up Your Clinic</Title>
      {state === State.SUCCESS && (
        <Result status="success" title="Updated clinic information" />
      )}
      <div>
        <Form
          key={clinicInfo.clinic.id}
          initialValues={{
            ...clinicInfo.clinic
          }}
          onFinish={onFormSubmit}
        >
          <Row gutter={ROW_GUTTER}>
            {questions.map(question => {
              const Prompt = question.component;
              return (
                <Col {...oneThirdCol} key={`clinicinfoedit-${question.name}`}>
                  <Form.Item
                    name={question.name}
                    rules={question.rules}
                    required={question.required}
                  >
                    {Prompt}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col {...oneThirdCol}>
              {clinicInfo.altLink && (
                <img
                  style={{ width: 200, maxHeight: 200 }}
                  src={clinicInfo.altLink}
                  alt={`${clinicInfo.clinic.name}'s logo`}
                />
              )}
              <Form.Item name="logo">
                <UploadImageButton
                  text="Upload Logo"
                  icon={<UserFilledIcon />}
                />
              </Form.Item>
            </Col>
            <Col {...oneThirdCol} />
            <Col
              {...oneThirdCol}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem'
              }}
            >
              <DarkButton
                style={{ marginTop: '40px' }}
                type="primary"
                htmlType="submit"
                disabled={state === State.LOADING}
                loading={state === State.LOADING}
              >
                Save
              </DarkButton>
            </Col>
          </Row>
        </Form>
      </div>

      {error && (
        <ErrorDisplay
          error={error}
          title={'Something went wrong when saving clinic information'}
          description={
            'If you think this is an error please contact contact@concussionrx.com'
          }
        />
      )}
    </Flex>
  );
}
