import { createRepo } from 'api/firebase/create-repo';
import { useAppSelector } from 'app/rootReducer';
import { Clinician } from 'documents/clinician';
import { Collection } from 'documents/document';
import { User } from 'features/auth/authSlice';
import { useEffect, useState } from 'react';

export function useClinicianInfo() {
  const { user, isUserDataLoading } = useAppSelector(s => s.auth);
  const [clinicianInfo, setClinicianInfo] = useState<Clinician | null>(null);
  useEffect(() => {
    async function setup(user: User | null, isUserDataLoading: boolean) {
      if (isUserDataLoading) return;
      if (!user) throw new Error(`You must be logged in`);

      const clinicianRepo = createRepo<Clinician>(Collection.Clinicians);
      const _clinicianInfo = await clinicianRepo.find(user.uid);
      if (!_clinicianInfo)
        throw new Error(`Clinician (id=${user.uid}) not found`);
      setClinicianInfo(_clinicianInfo);
    }
    setup(user, isUserDataLoading);
  }, [user, isUserDataLoading]);
  return clinicianInfo;
}
