export interface IHandleError {
  isLoading: boolean;
  hasError: boolean;
  /**
   * Is falsy if `hasError` is true
   */
  error?: Error | null | undefined;
}

export const handleError = (
  state: IHandleError,
  { error }: { error: any },
  label = 'NO LABEL'
) => {
  state.isLoading = false;
  state.hasError = true;
  state.error = error;

  console.log(`${label}:`, error);
};

export const handlePending = (state: { isLoading: boolean }) => {
  state.isLoading = true;
};
