import './Page.scss';

import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { Alert, Row, Spin } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { TagType } from 'antd/lib/tag';
import clsx from 'clsx';
import { goBack } from 'connected-react-router';

import { BackButton } from 'components/back-button';
// import { ColorLegend } from 'components/gauge';

export interface PageProps {
  title?: string;
  isLoading?: boolean;
  hasError?: boolean;
  extra?: ReactNode;
  tags?: React.ReactElement<TagType> | React.ReactElement<TagType>[];
  hideBack?: boolean;
  pushExtraRight?: boolean;
  className?: string | string[];
  wrapRight?: boolean;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({
  title,
  isLoading,
  hasError,
  extra,
  tags,
  hideBack,
  pushExtraRight,
  children,
  className,
  wrapRight
}) => {
  const dispatch = useDispatch();

  const backIcon = hideBack ? false : <BackButton />;
  const handleBack = () => dispatch(goBack());
  const css = clsx({ 'extra-right': pushExtraRight, 'flex-wrap': wrapRight });

  return (
    <div className={clsx('page', className)}>
      <PageHeader
        title={title}
        backIcon={backIcon}
        onBack={handleBack}
        tags={tags}
        extra={extra}
        className={css}
      />

      {!hasError && isLoading && (
        <Row justify="center" align="middle" className="page__spin">
          <Spin size="large" />
        </Row>
      )}
      {!hasError && !isLoading && children}
      {hasError && (
        <Alert
          message="Error"
          description="OOPS! Something went wrong. Please try again later."
          type="error"
          showIcon
        />
      )}
    </div>
  );
};

export interface SubTypeHeader {
  marginBottom?: number;
}

// TODO how we generate the symptom condition can be passed through props in Page2's reducer or fetched here. The dynamic coloring can be done here?
export const SubTypeHeader = ({ marginBottom }: { marginBottom?: number }) => {
  return (
    <div style={{ marginBottom: marginBottom ? marginBottom + 'px' : '57px' }}>
      <h1>
        Current Subtype: <span style={{ color: 'red' }}>Highly Complex</span>
      </h1>
    </div>
  );
};
