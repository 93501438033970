import { z } from 'zod';

const createOption = (label: string, value?: string | undefined) => ({
  label,
  value: value ?? label.toLowerCase().replaceAll(' ', '-')
});

export const OPTIONS_PROVINCES = [
  createOption('Ontario'),
  createOption('Quebec'),
  createOption('Nova Scotia'),
  createOption('New Brunswick'),
  createOption('Manitoba'),
  createOption('British Columbia'),
  createOption('Prince Edward Island'),
  createOption('Saskatchewan'),
  createOption('Alberta'),
  createOption('Newfoundland and Labrador'),
  createOption('Northwest Territories'),
  createOption('Yukon'),
  createOption('Nunavut')
];

export const clinicInfoSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.enum(['canada', 'usa']),
  // TODO: Fix missing types in provinceOrState
  provinceOrState: z.enum(OPTIONS_PROVINCES.map(_ => _.value) as any),
  city: z.string(),
  address: z.string(),
  postalOrZipCode: z.string(),
  phoneNumber: z.string(),
  faxNumber: z.string().optional()
});

export interface Clinic extends z.infer<typeof clinicInfoSchema> {}
