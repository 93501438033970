import './BackButton.scss';

import React from 'react';

import { Icon } from 'components/icon';

export const BackButton: React.FC = () => (
  <div className="back-button">
    <Icon className="back-button__icon">arrow_back</Icon>
    <span>Back</span>
  </div>
);
