export const COLOR_EXTREME = '#D72721';
export const COLOR_HIGH = '#E05D18';
export const COLOR_MODERATE = '#E67C12';
export const COLOR_MILDLY = '#EFB708';
export const COLOR_MINIMAL = '#F1F124';

/**
 * This might be a duplicate of the intended role of [`getColorLegend`](../../..//constants/value-color.ts)
 */
export const COLORS_ORDER = [
  COLOR_MINIMAL,
  COLOR_MILDLY,
  COLOR_MODERATE,
  COLOR_HIGH,
  COLOR_EXTREME
];
