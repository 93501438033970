import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import { capitaliseFirstLetter } from 'utils/formatters/normalise';
import { addHexOpacity } from 'utils/math/colors';
import { ConcussionComplexity } from '../../../../documents/concussion-complexity';

export const concussionComplexityColors: Record<
  ConcussionComplexity,
  { baseColour: string }
> = {
  minimal: {
    baseColour: '#F1F124'
  },
  mild: {
    baseColour: '#EFB708'
  },
  moderate: {
    baseColour: '#E67C12'
  },
  high: {
    baseColour: '#E05D18'
  },
  extreme: {
    baseColour: '#D72721'
  }
};

/**
 * Displays the complexity of a concussion.
 * The container changes styles based on the complexity type
 *
 * Complexity-to-colour
 * - minimally -> yellow rgba(241, 241, 36, 1)
 * - mildly -> yellow-orange rgba(239, 183, 8, 1)
 * - moderate -> orange rgba(230, 124, 18, 1)
 * - highly -> orange-red rgba(224, 93, 24, 1)
 * - extremely -> rgba(215, 39, 33, 1)
 *
 * @param props
 * @returns
 */
export function StyledConcussionComplexity(props: {
  complexity: ConcussionComplexity;
  style?: React.CSSProperties;
  textLevel?: TitleProps['level'];
}) {
  const { baseColour } = concussionComplexityColors[props.complexity];

  return (
    <div
      style={{
        padding: '1rem',
        background: `${addHexOpacity(baseColour, 0.15)}`,
        border: `4px solid ${baseColour}`,
        borderRadius: 12,
        ...props.style
      }}
    >
      <Typography.Title
        level={props.textLevel ?? 3}
        style={{ marginBottom: 0 }}
      >
        {capitaliseFirstLetter(props.complexity + 'ly')} Complex
      </Typography.Title>
    </div>
  );
}
