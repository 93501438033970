import { useState, useEffect } from 'react';

const messages = [
  'Close your eyes for 15 seconds',
  'For the next 15 seconds, take a few deep breaths',
  'if you need a further break, take a few minutes and come back to complete your ConcussionRx assessment'
];

interface CountdownTimerProps {
  initialSeconds: number;
  onFinish: () => void | any;
  breaks: number;
}
export function CountdownTimer({ onFinish, ...props }: CountdownTimerProps) {
  const [seconds, setSeconds] = useState(props.initialSeconds);
  const restMsgIndex = props.breaks % messages.length;

  useEffect(() => {
    // Set up the timer
    const timer = setInterval(() => {
      setSeconds(prev => prev - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Exit early if countdown is finished
    if (seconds <= 0) {
      if (onFinish) onFinish();
      return;
    }
  }, [seconds, onFinish]);

  const format = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (timeInSeconds % 60).toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <p
        style={{
          color: 'white',
          fontStyle: 'Comfortaa',
          fontSize: '54px',
          lineHeight: '1.5',
          paddingLeft: '60px'
        }}
      >
        {messages[restMsgIndex]}
      </p>
      <p style={{ color: 'white', fontStyle: 'Comfortaa', fontSize: '60px' }}>
        {format(seconds)}
      </p>
      {/* <p style={{color: "white", fontStyle: "Comfortaa", fontSize: "72px"}} onClick={restart}>Restart</p> */}
    </div>
  );
}
