// PatientOutcomeProgress.tsx
import { Col, Progress, Row, Skeleton, Typography } from 'antd';
import { BaseHeader } from 'components/header';
import { useReducer } from 'hooks/useReducer';
import { useEffect } from 'react';

import {
  questionnaireInfoInitialLoad,
  initialState,
  QuestionnaireControlSliceReducer
} from 'features/patient/assessment/questionnaire/questionnaire-control-flow-slice';

const { Title } = Typography;

import { useAppSelector } from 'app/rootReducer';
import { DarkButton } from 'components/dark-button';
import { Link } from 'react-router-dom';
import { Routes } from 'routes';
import './patient-outcome-progress.scss';
import { Collection } from 'documents/document';
import moment from 'moment';

export function PatientOutcomeProgress() {
  const [
    { required, fulfilled, assessmentData, ...questionnaireData },
    dispatch
  ] = useReducer(QuestionnaireControlSliceReducer, initialState);

  const daysLeft = assessmentData?.expirationDate.diff(moment(), 'days'); // TODO this needs to be addressed by most likely a junction collection
  const user = useAppSelector(state => state.auth.user);
  const getPercent = () => Math.min(100, (fulfilled / required) * 100);

  useEffect(() => {
    dispatch(
      questionnaireInfoInitialLoad({
        patientId: user!.uid,
        rootPath: Collection.PendingPatientAssessments
      })
    );
  }, [user]);

  if (questionnaireData.isLoading) {
    return <Skeleton active />;
  }

  return (
    <div className="outcome-progress-container">
      <BaseHeader />
      <div className="outcome-content-container">
        <Row className="title-container">
          <Col className="font-brand" style={{ marginBottom: '35px' }}>
            <Row>
              <h1 className="crx-typography h1" style={{ marginBottom: '8px' }}>
                Good progress!
              </h1>
            </Row>
            <Row>
              <Typography className="crx-typography body-normal">
                You are one step closer to receiving your treatment
                recommendations
              </Typography>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginBottom: '60px' }}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Title level={3} style={{ fontSize: '52px', color: '#242425' }}>
                  {isNaN(getPercent()) ? 0 : getPercent()}%
                  <span className="title-complete font-brand"> completed</span>
                </Title>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Row>
                  <Typography.Title
                    level={3}
                    className="font-brand crx-typography h3"
                  >
                    {required - fulfilled}/{required} measures left
                  </Typography.Title>
                </Row>
              </Col>
            </Row>
            <Row>
              <ConnectedOutcomeMeasuresProgressBar />
            </Row>
          </Col>
        </Row>

        <Row style={{ marginBottom: '50px' }}>
          <Col>
            <Row>
              <Title level={3} className="crx-typography h3">
                Time remaining:
              </Title>
            </Row>
            <Row className="days-left-row">
              <div className="days-left-container">
                <Title level={1}>{daysLeft}</Title>
                <div className="days-left-days-left">
                  <h3
                    className="crx-typography h3"
                    style={{ marginBottom: '0px' }}
                  >
                    Days
                  </h3>
                  <h3 className="crx-typography h3">left</h3>
                </div>
              </div>
              <div className="crx-typography body-normal days-left-text">
                before you have to restart the assessment to maintain medical
                accuracy.
              </div>
            </Row>
          </Col>
        </Row>

        <DarkButton
          className="crx-typography button-big"
          style={{ padding: '30px' }}
        >
          <Link to={Routes.QuestionnaireFlow}>Continue the assessment</Link>
        </DarkButton>
      </div>
    </div>
  );
}

export function ConnectedOutcomeMeasuresProgressBar(
  props: Omit<
    React.ComponentProps<typeof OutcomeMeasuresProgressBar>,
    'fulfilled' | 'required'
  >
) {
  const [{ required, fulfilled }, dispatch] = useReducer(
    QuestionnaireControlSliceReducer,
    initialState
  );

  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(
      questionnaireInfoInitialLoad({
        patientId: user!.uid,
        rootPath: Collection.PendingPatientAssessments
      })
    );
  }, [user]);

  return (
    <OutcomeMeasuresProgressBar
      fulfilled={fulfilled}
      required={required}
      {...props}
    />
  );
}

export function OutcomeMeasuresProgressBar(
  props: {
    fulfilled: number;
    required: number;
    label?: boolean;
  } & Omit<React.ComponentProps<typeof Progress>, 'percent'>
) {
  const { fulfilled, required, label, ...other } = props;
  const getPercent = () => Math.min(100, (fulfilled / required) * 100);
  return (
    <>
      {
        /**
         * Show a completion label
         */
        label && (
          <span style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography.Title style={{ marginRight: '0.5rem' }}>
              {getPercent()}%
            </Typography.Title>
            <Typography>completed</Typography>
          </span>
        )
      }
      <Progress
        percent={getPercent()}
        strokeColor={'rgba(163, 102, 252, 1)'}
        trailColor={'rgba(163, 102, 252, 0.25)'}
        showInfo={false}
        size={['100%', 40]}
        {...other}
      />
    </>
  );
}
