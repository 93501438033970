import {
  DisorderKey,
  PatientPersonalHistoryKey,
  SubstanceKey
} from 'documents';

export const keyDependency = {
  [PatientPersonalHistoryKey.concussionHx]: [
    PatientPersonalHistoryKey.numberPrevConcussion,
    PatientPersonalHistoryKey.longestSxDuration,
    PatientPersonalHistoryKey.lessForceReinjury
  ],
  [PatientPersonalHistoryKey.headacheHx]: [
    PatientPersonalHistoryKey.priorHeadacheTx
  ],
  [PatientPersonalHistoryKey.learningDisability]: [
    PatientPersonalHistoryKey.learningDisabilityDiagnosis
  ],
  [PatientPersonalHistoryKey.addAdhdDx]: [
    PatientPersonalHistoryKey.addAdhdAgeDiagnosed
  ],
  [PatientPersonalHistoryKey.repeatedGrade]: [
    PatientPersonalHistoryKey.gradeRepeatedCount
  ],
  [PatientPersonalHistoryKey.anxiety]: {
    [DisorderKey.presence]: [DisorderKey.treatment]
  },
  [PatientPersonalHistoryKey.depression]: {
    [DisorderKey.presence]: [DisorderKey.treatment]
  },
  [PatientPersonalHistoryKey.sleepDisorder]: {
    [DisorderKey.presence]: [DisorderKey.treatment]
  },
  [PatientPersonalHistoryKey.alcohol]: {
    [SubstanceKey.presence]: [SubstanceKey.frequency, SubstanceKey.count]
  },
  [PatientPersonalHistoryKey.smoking]: {
    [SubstanceKey.presence]: [SubstanceKey.frequency, SubstanceKey.count]
  },
  [PatientPersonalHistoryKey.marajuana]: {
    [SubstanceKey.presence]: [SubstanceKey.frequency, SubstanceKey.count]
  },
  [PatientPersonalHistoryKey.otherSubstance]: {
    [SubstanceKey.presence]: [SubstanceKey.frequency, SubstanceKey.count]
  }
};
