// deal with the pagination state changes in questionnaires using pagination
import { createSlice, current } from '@reduxjs/toolkit';
import { RadioButtonListItem } from 'features/patient/outcome-measures/cgs/radio-button-list';
import { PcsInventory } from './pcs/pcsSlice';

export interface ItemHighlight {
  section: number;
  page: number;
  index: number;
  item: Record<any, any>;
}

export interface FormattedSection extends Omit<PcsInventory, 'data' | 'date'> {
  type: 'button';
  questions: RadioButtonListItem[];
  page: RadioButtonListItem[][];
  pageLength: number;
}

export interface PaginationState {
  /**
   * The current section
   */
  currentSection: number;
  /**
   * The current page in a section
   */
  currentPage: number;
  formattedSections: FormattedSection[];

  itemHighlight: ItemHighlight | null;
  disabled: boolean;
  submitReady: boolean;
}

export const paginationSliceInitialState: PaginationState = {
  currentSection: 0,
  currentPage: 0,
  formattedSections: [],
  itemHighlight: null,
  disabled: true,
  submitReady: false
};

export const paginationSlice = createSlice({
  name: 'paginationBySectionsByPage',
  initialState: paginationSliceInitialState,
  reducers: {
    setSection: (state, { payload }) => {
      state.currentSection = payload;
    },
    setPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setSections: (state, { payload }) => {
      state.formattedSections = payload;
    },
    setHighlightItem: (state, { payload }) => {
      /* find nexts highlight item*/
      if (payload && payload !== undefined) {
        // option for user to set item manually
        state.itemHighlight = payload;
        return;
      } else {
        // set next available highlightItem
        // const itemHighlight = current(state).itemHighlight;
        // if (state.itemHighlight?.item?.value == -1) {
        //   return;
        // }

        if (state.itemHighlight === null) {
          // init
          const hasData = state.formattedSections.length > 0;
          if (hasData) {
            state.itemHighlight = {
              section: 0,
              page: 0,
              index: 0,
              item: state.formattedSections[0]?.page[0][0]
            };
          }
        } else {
          const itemHighlight = current(state).itemHighlight;
          if (itemHighlight === null) {
            return;
          }

          const section = itemHighlight.section,
            page = itemHighlight.page,
            index = itemHighlight.index;
          const formattedSections = current(state).formattedSections;
          const a = formattedSections[section].page[page][index];
          state.itemHighlight.item = a;
        }
      }
    },
    setNextHighlightItem: (
      state,
      { payload }: { payload: RadioButtonListItem }
    ) => {
      const formattedSections = current(state).formattedSections;
      const page = current(state).currentPage;
      const section = current(state).currentSection;

      // find currentIndex from payload being passed in
      const index = formattedSections[section].page[page].reduce(
        (acc, ele, index) => {
          if (ele.title === payload.title) {
            acc = index;
          }
          return acc;
        },
        -1
      );

      // set to next item
      const n = formattedSections[section].page[page].length;
      const m = formattedSections[section].page.length;
      const o = formattedSections.length;
      let nextItem;

      if (index + 1 < n) {
        // if next item index < n length
        nextItem = formattedSections[section].page[page][index + 1];
        state.itemHighlight = {
          section: section,
          page: page,
          index: index + 1,
          item: nextItem
        };
      } // flip pages, reset index to 0
      else if (page + 1 < m) {
        nextItem = formattedSections[section].page[page + 1][0];
        state.itemHighlight = {
          section: section,
          page: page + 1,
          index: 0,
          item: nextItem
        };
      } // flip sections, reset page and index to 0
      else if (section + 1 < o) {
        nextItem = formattedSections[section].page[0][0];
        state.itemHighlight = {
          section: section + 1,
          page: 0,
          index: 0,
          item: nextItem
        };
      } else {
        // enable label of button to be submit
        state.submitReady = true;
      }
    },
    nextPageOrSection: state => {
      /************
       * WARN: This should be moved to `PaginationCombined`
       * when the component is refactored to be standalone
       *************/
      window.scrollTo(0, 0);

      let totalPagesInSection =
        state.formattedSections[state.currentSection]?.page?.length - 1;
      const totalSections = state.formattedSections.length;

      // If there are more pages to read
      if (state.currentPage < totalPagesInSection) {
        state.currentPage = state.currentPage + 1;
        // paginationSlice.reducer.setPage(state, state.currentPage+1);
        totalPagesInSection =
          state.formattedSections[state.currentSection]?.page?.length - 1;
      } else {
        // The state is on the last page of the section
        // Try to read the next section
        if (state.currentSection + 1 < totalSections) {
          // Reset the current page to 0
          state.currentPage = 0;
          state.currentSection = state.currentSection + 1;
        } else {
          // submitted
          // state.submitReady = true;
        }
      }
    },
    isDisabled: state => {
      // state.disabled = false;
      state.disabled = state.formattedSections[state.currentSection]?.page[
        state.currentPage
      ].some(item => item.value === -1);
    },
    jumpTo: (state, { payload }) => {
      state.currentSection = payload.section;
      state.currentPage = payload.page;
    }
  }
});

export const {
  setSection,
  setSections,
  setPage,
  nextPageOrSection,
  setHighlightItem,
  setNextHighlightItem,
  isDisabled,
  jumpTo
} = paginationSlice.actions;
export const PaginationSliceReducer = paginationSlice.reducer;
export default paginationSlice.reducer;
