import { createSlice } from '@reduxjs/toolkit';

export interface RxProgressState {
  /**
   * The value of the progress bar.
   * This ranges between [0, `required`]
   */
  fulfilled: number;
  /**
   * The highest value of the progress bar
   */
  required: number;
  /**
   * Progress bar setting
   */
  strokeColor?: string;
  /**
   * Progress bar setting
   */
  trailColor?: string;
  /**
   * If the progress bar should be pushed up
   */
  pullToTop?: boolean;
}

export const rxInitialState: RxProgressState = {
  fulfilled: 0,
  required: 10,
  pullToTop: true
};

export const RxProgressBarSlice = createSlice({
  name: 'RxProgressBar',
  initialState: rxInitialState,
  reducers: {
    incrementProgress(state) {
      state.fulfilled += 1;
    },
    setFulfilled(state, { payload }) {
      state.fulfilled = payload;
    },
    setRequired(state, { payload }) {
      state.required = payload;
    },
    setStrokeColor(state, { payload }) {
      state.strokeColor = payload;
    },
    setTrailColor(state, { payload }) {
      state.trailColor = payload;
    },
    setPullToTop(state, { payload }) {
      state.pullToTop = payload;
    }
  }
});

export const {
  setFulfilled,
  setRequired,
  setStrokeColor,
  setTrailColor,
  setPullToTop
} = RxProgressBarSlice.actions;
export const RxProgressBarActions = RxProgressBarSlice.actions;
export const RxProgressReducer = RxProgressBarSlice.reducer;
