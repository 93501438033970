import './sign-out-button.scss';

import { useDispatch } from 'react-redux';

import { Button } from 'antd';

import { auth } from 'api/firebase';

import { Icon } from 'components';
import { signOut } from 'features/auth/auth-action-sign-in-out';
import { useAppSelector } from 'app/rootReducer';

interface SignOutButtonProps {
  before?: boolean;
  label?: string;
  rounded?: boolean;
}

export function SignOutButton({
  before = true,
  label = 'Log Out',
  rounded = false
}: SignOutButtonProps) {
  const dispatch = useDispatch();
  const user = useAppSelector(state => state.auth.user);

  const onClick = () => {
    dispatch(signOut(auth, user));
  };

  let dynamicClass = 'sign-out-button';
  if (rounded) {
    dynamicClass += ' sign-out-button__rounded';
  }

  if (before) {
    return (
      <Button type="text" onClick={onClick} className={dynamicClass}>
        <Icon size="sm">logout</Icon>
        <span>{label}</span>
      </Button>
    );
  }

  return (
    <Button type="text" onClick={onClick} className={dynamicClass}>
      <span>{label}</span>
      <Icon size="sm">logout</Icon>
    </Button>
  );
}
