import { ZodError, ZodIssue } from 'zod';

export function formatZodError(error: ZodError) {
  const message = error.issues
    .map(issue => `[Property: ${issue.path}] ${formatZodIssue(issue)}`)
    .join(', ');
  const newError = new Error(`ZodError: Issues ${message}`);
  newError.stack = error.stack;

  return newError;
}

export function formatZodIssue(issue: ZodIssue) {
  switch (issue.code) {
    case 'invalid_type':
      return `Expected: ${issue.expected}. Got ${issue.received}`;
    default:
      return issue.message;
  }
}
