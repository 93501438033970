/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';

import { Document } from 'documents';
import { debounce } from 'lodash';
import { DocumentReference } from '@firebase/firestore-types';

import {
  createQuery,
  db,
  getDataConverter,
  OrderByCondition,
  WhereCondition
} from 'api/firebase';
import { resolveError } from 'utils/formatters/error/resolve-error';

export interface DocQuery {
  path: string;
  id?: string;
  where?: WhereCondition | WhereCondition[];
  orderBy?: OrderByCondition;
  updateWait?: number;
}

export type SetDocAction<T extends Document> = (doc?: T) => T;

/**
 * Hook to fetch and memoize a document from the database
 *
 * @param param0
 * @returns
 */
export const useDocument = <T extends Document>({
  path,
  id,
  where,
  orderBy,
  updateWait = 1000
}: DocQuery) => {
  const [error, setError] = useState<
    { hasError: false } | { hasError: true; error: Error }
  >({ hasError: false });
  const [isLoading, setIsLoading] = useState(true);
  const [doc, setDoc] = useState<T>();
  const docRef = useRef<DocumentReference<T>>();

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const dataConverter = getDataConverter<T>();
        if (id) {
          const doc = db.collection(path).doc(id).withConverter(dataConverter);
          docRef.current = doc;

          const docSnapshot = await doc.get();
          setDoc(docSnapshot.data());
          setIsLoading(false);
          return;
        }

        const snapshot = await createQuery<T>(
          path,
          db,
          dataConverter,
          where,
          orderBy
        )
          .limit(1)
          .get();

        if (snapshot.docs.length > 0) {
          docRef.current = snapshot.docs[0].ref;
          setDoc(snapshot.docs[0].data());
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setError({
          hasError: true,
          error: resolveError(error)
        });
      }
    })();
  }, [path]);

  const createDoc = useCallback(
    debounce(async (doc: T, throwError?: boolean) => {
      try {
        const ref = await db
          .collection(path)
          .withConverter(getDataConverter<T>())
          .add(doc);
        doc.id = ref.id;
        docRef.current = ref;
      } catch (_error) {
        const error = resolveError(_error);
        console.error(error);
        setError({
          hasError: true,
          error: resolveError(error)
        });
        if (throwError) {
          throw error;
        }
      }
    }, updateWait),
    []
  );

  const updateDoc = useCallback(
    debounce(async (doc: T, throwError?: boolean) => {
      try {
        await docRef.current?.set(doc);
      } catch (_error) {
        const error = resolveError(_error);
        console.error(error);
        setError({
          hasError: true,
          error: resolveError(error)
        });
        if (throwError) {
          throw error;
        }
      }
    }, updateWait),
    []
  );

  const update = useCallback(
    async (dataOrFunction: SetDocAction<T> | T, throwError = false) => {
      setDoc(prev => {
        const data: T =
          typeof dataOrFunction === 'function'
            ? dataOrFunction(prev)
            : dataOrFunction;

        if (docRef.current) {
          updateDoc(data, throwError);
        } else {
          createDoc(data, throwError);
        }

        return data;
      });
    },
    []
  );

  const remove = useCallback(async () => {
    if (docRef.current) {
      try {
        await docRef.current.delete();
        setDoc(undefined);
      } catch (error) {
        console.error(error);
        setError({
          hasError: true,
          error: resolveError(error)
        });
      }
    }
  }, []);

  return {
    hasError: error.hasError,
    errorState: error,
    isLoading,
    doc,
    update,
    remove
  };
};
