import { useEffect, useState } from 'react';

import { QuestionnaireIntroProps } from 'features/patient/assessment/questionnaire/questionnaire-intros';
import { useReducer } from 'hooks/useReducer';

import {
  ImPACTSlice,
  ImpactSliceReducer,
  initialImpactSliceLoad,
  initialState,
  passportIdExists
} from './impactAPISlice';

import { Form, Input, Skeleton } from 'antd';
import { useAppSelector } from 'app/rootReducer';
import { Text } from 'components/mvp-typography';
import ImpactLogo from 'img/impact-logo.svg';
import { QuestionnaireIntro } from '../assessment';
import { QuestionnaireDataProps } from '../assessment/questionnaire/types';
import { prepareImPACTTest } from './prepare-impact-test';

export const impactData: QuestionnaireDataProps = {
  imgLogo: ImpactLogo,
  buttonTitle: 'ImPACT Test',
  subtitle: ''.toUpperCase(),
  title: 'Neurocognitive Screening Tool',
  description: [
    'ImPACT, a 3rd-party tool, stands for Post-Concussion Assessment and Cognitive Testing. ',
    'it is a computerized test that measures memory, reaction time, attention span, visual and verbal problem-solving.'
  ].join(''),
  duration: '20-30 minutes'
};

const ActionLink = (props: { text: string; onClick: () => void | any }) => (
  <a
    onClick={e => {
      e.preventDefault();
      props.onClick();
    }}
    style={{
      color: '#230056',
      fontWeight: 'bold',
      textDecoration: 'underline'
    }}
  >
    {props.text}
  </a>
);

export function ImpactQuestionnaire(props: QuestionnaireIntroProps) {
  const user = useAppSelector(state => state.auth.user);
  const [impactInfo, dispatch] = useReducer(ImpactSliceReducer, initialState);

  const [insertImpactPassportId, setInsertImpactPassportId] = useState(false);
  const [passportId, setPassportId] = useState<string | null>(null);
  useEffect(() => {
    if (user && !impactInfo.userLoaded) {
      dispatch(initialImpactSliceLoad(user.uid));
    }
  }, [user, impactInfo.userLoaded]);

  if (!user || impactInfo.isLoading) return <Skeleton active />;

  /**
   * Show a prompt to set the ImPACT passport ID
   */
  if (insertImpactPassportId) {
    return (
      <ImpactQuestionnaireIntro
        impactData={{
          ...impactData,
          title: 'Insert your ImPACT Passport ID',
          description: (
            <>
              <ActionLink
                text={"Don't have a passport ID? Click here"}
                onClick={() => {
                  setInsertImpactPassportId(old => !old);
                }}
              />
              <PassportIdInput setValue={setPassportId} />
            </>
          )
        }}
        impactInfo={impactInfo}
        userId={props.userId}
        existingPassportId={passportId}
      />
    );
  }

  return (
    <>
      <ImpactQuestionnaireIntro
        impactData={{
          ...impactData,
          description: (
            <>
              {impactData.description}
              {!impactInfo.verified && (
                <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                  <Text style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Have you already taken the ImPACT test before?
                  </Text>
                  <Text>
                    <ActionLink
                      text={'Click Here'}
                      onClick={() => {
                        setInsertImpactPassportId(old => !old);
                      }}
                    />
                  </Text>
                </div>
              )}
            </>
          )
        }}
        impactInfo={impactInfo}
        userId={props.userId}
        existingPassportId={-1}
      />
    </>
  );
}

function ImpactQuestionnaireIntro(props: {
  impactData: QuestionnaireDataProps;
  impactInfo: ImPACTSlice;
  userId: string;
  /**
   * If this value is set or not undefined the component will
   * try to use the value when creating an ImPACT test URL
   */
  existingPassportId: string | null | -1;
}) {
  async function startImpactQuestionnaire() {
    const testUrl = await prepareImPACTTest(props);
    console.log('[startImpactQuestionnaire]', testUrl);

    if (testUrl) {
      window.open(testUrl, '_blank', 'noreferrer');
    }
  }

  return (
    <QuestionnaireIntro
      data={props.impactData}
      startQuestionnaire={
        /**
         * Trigger the function if:
         * 1. The user has an existing passport ID
         * 2. The user set an existing passport ID
         * 3. THe user did not set an existing passport ID
         */
        props.impactInfo.verified || props.existingPassportId !== null
          ? startImpactQuestionnaire
          : /**
             * TODO: Show an error message
             */
            undefined
      }
    />
  );
}

function PassportIdInput(props: {
  setValue: (newValue: string | null) => void | any;
}) {
  const [form] = Form.useForm();
  return (
    <div style={{ width: 400 }}>
      <Form form={form} style={{ width: '100%' }}>
        <Form.Item
          hasFeedback
          name={'passportId'}
          validateDebounce={1000}
          rules={[
            {
              required: true,
              message: 'This field is required'
            },
            {
              validator: async (_rule, value) => {
                const result = await passportIdExists(value);
                if (!result) {
                  props.setValue(null);
                  return Promise.reject();
                }

                props.setValue(value);
                return Promise.resolve();
              },
              message: 'ImPACT PassportID is incorrect or does not exist'
            }
          ]}
        >
          <Input placeholder="Insert your ImPACT PassportID here" />
        </Form.Item>
      </Form>
    </div>
  );
}
