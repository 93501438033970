import './patient-summary-dashboard.scss';

import React from 'react';

import { Flex } from 'antd';

import { ElevatedCard } from 'components/card/elevated-card';
import { CriticalAreasCard } from './cards/card-critical-areas';
import { SubtypeCard } from './cards/card-subtype';
import {
  YourConcussionCard,
  YourConcussionCardProps
} from './cards/card-your-concussion';

export const PatientSummaryDashboard: React.FC<
  Pick<YourConcussionCardProps, 'getLink'>
> = () => (
  <section className="patient-dashboard">
    <Flex vertical gap="large" className="patient-dashboard-cardcontainer">
      {/* Complexity subtype */}
      <ElevatedCard>
        <SubtypeCard />
      </ElevatedCard>

      <ElevatedCard>
        <CriticalAreasCard />
      </ElevatedCard>

      {/* Display patient symptoms */}
      <ElevatedCard style={{ pageBreakBefore: 'always' }}>
        <YourConcussionCard />
      </ElevatedCard>
    </Flex>
  </section>
);
