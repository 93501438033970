import React from 'react';
import ConcussionLogo from 'img/concussionrx-logo-new.png';

/**
 * The concussionRX logo with text
 *
 * TODO: Refactor this to a separate module
 */

export function ConcussionRXLogo() {
  return <img style={{ width: '100%' }} src={ConcussionLogo} />;
}
