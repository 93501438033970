import { Collection, PatientSubcollection, Inventory } from 'documents';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import moment, { Moment } from 'moment';
import { getTimestampedDocId } from 'utils/object';
import { handleError, handlePending, PageState, ThunkApi } from 'utils/redux';

import { createRepo, Repo } from 'api/firebase';
import { SliderListItem } from 'components/slider-list';

import {
  DhiCategory,
  DhiCategoryMultipler
} from 'documents/dizziness-handicap-scale';

import DhiJson from 'data/dizziness-handicap-scale.json';
import { PcsInventory } from '../pcs/pcsSlice';
import { savePendingAssessment } from 'features/patient/assessment/questionnaire/questionnaire-control-flow-slice';
const dhiOrder = DhiJson.questions.map(q => q.title);

// eslint-disable-next-line
export interface DhiInventory extends PcsInventory {
  type: string;
  data: Record<DhiCategory, number> & Record<string, number>;
}

export interface DhiState extends PageState {
  dhi: DhiInventory;
  invalidItems: string[];
  isSubmitting: boolean;
  lastUpdated?: Moment;
  showModal: boolean;
  categories: Record<string, string[]>;
}

export const categories = [
  'Total',
  'Physical',
  'Emotional',
  'Functional'
] as const;
const notSelected = -1;

export function createNewDhi(): DhiInventory {
  return {
    date: moment(),
    data: DhiJson.questions.reduce((acc: Record<string, number>, question) => {
      acc[question.title] = notSelected;
      return acc;
    }, {}),
    instructions: DhiJson.instructions,
    min: DhiJson.min,
    max: DhiJson.max,
    description: DhiJson.description,
    id: DhiJson.id,
    type: DhiJson.type,
    beforeLabel: DhiJson.beforeLabel,
    afterLabel: DhiJson.afterLabel,
    colorsForward: DhiJson.colorsForward ?? true,
    iconsForward: DhiJson.iconsForward ?? true
  };
}

function createCategories(): Record<string, string[]> {
  return DhiJson.questions.reduce((acc: Record<string, string[]>, question) => {
    acc[question.title] = question.categories;
    return acc;
  }, {});
}

const initialState: DhiState = {
  hasError: false,
  dhi: createNewDhi(),
  invalidItems: [],
  isLoading: false,
  isSubmitting: false,
  showModal: false,
  categories: createCategories()
};

let dhiRepo: Repo<Inventory>;
let _patientId: string;
const initialLoad = createAsyncThunk(
  'dhi/initialLoad',
  async (patientId: string) => {
    dhiRepo = createRepo<Inventory>(
      `${Collection.Patients}/${patientId}/${PatientSubcollection.InventoriesDhi}`
    );
    _patientId = patientId;

    return await dhiRepo.first({
      orderBy: ['date', 'desc']
    });
  }
);

const submit = createAsyncThunk<boolean, void, ThunkApi<DhiState>>(
  'dhi/submit',
  async (_, { getState, dispatch }) => {
    dispatch(dhiSlice.actions.validate());

    const { invalidItems } = getState();
    if (invalidItems.length) return false;

    // await dispatch(dhiSlice.actions.filterMetaProperties())
    await dispatch(dhiSlice.actions.calculateCategories());
    await dispatch(dhiSlice.actions.calculateCategoryMultipliers());

    dispatch(dhiSlice.actions.setDhiDate());

    const { dhi } = getState();

    await savePendingAssessment(
      _patientId,
      PatientSubcollection.InventoriesDhi,
      dhi
    );
    return true;
  }
);

const dhiSlice = createSlice({
  name: 'dhi',
  initialState,
  reducers: {
    setDhiDate: state => {
      const now = moment();
      state.dhi.id = getTimestampedDocId(now);
      state.dhi.date = now;
    },
    updateDhiItem: (state, { payload }: PayloadAction<SliderListItem>) => {
      const { title, value } = payload;
      state.invalidItems = state.invalidItems.filter(i => i !== title);
      state.dhi.data[title] = value;
    },
    closeModal: state => {
      state.showModal = false;
    },
    validate: state => {
      state.invalidItems = Object.entries(state.dhi.data)
        .filter(([, value]) => value === notSelected)
        .map(([key]) => key);
    },
    calculateCategories: state => {
      for (const category of categories) {
        state.dhi.data[category] = 0;
      }

      Object.entries(state.dhi.data).forEach(([title, value]) => {
        if (state.categories[title]) {
          for (const category of state.categories[title]) {
            state.dhi.data[category] += value;
          }
        }
      });
    },
    calculateCategoryMultipliers: state => {
      for (const category of Object.values(DhiCategory)) {
        state.dhi.data[category] *= DhiCategoryMultipler[category];
      }
    }
    // ,
    // filterMetaProperties: state => {
    //   const neededProperties = ["data", "date"];

    //   for (const category of Object.values(state.dhi)) {
    //     if (neededProperties.indexOf(category) == -1) {
    //       state.dhi
    //     }
    //   }
    // }
  },
  extraReducers: builder => {
    builder.addCase(initialLoad.pending, handlePending);
    builder.addCase(initialLoad.rejected, handleError);
    builder.addCase(initialLoad.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.lastUpdated = payload ? payload.date : undefined;
    });

    builder.addCase(submit.pending, state => {
      state.isSubmitting = true;
    });
    builder.addCase(submit.rejected, handleError);
    builder.addCase(submit.fulfilled, (state, { payload: isSaved }) => {
      state.isSubmitting = false;
      if (isSaved) {
        state.showModal = isSaved;
        state.lastUpdated = moment().utc(true);
        state.dhi = createNewDhi();
      }
    });
  }
});

export {
  initialLoad,
  initialState,
  dhiOrder,
  notSelected,
  submit,
  createCategories
};
export const { updateDhiItem, setDhiDate, closeModal, validate } =
  dhiSlice.actions;
export const DhiSliceReducer = dhiSlice.reducer;
export default dhiSlice.reducer;
