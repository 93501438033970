import { useReducer } from 'hooks/useReducer';
import { createContext, Dispatch, useCallback } from 'react';
import { isChrome } from 'react-device-detect';
import {
  IPrintContextProps,
  IReactToPrintProps,
  useReactToPrint
} from 'react-to-print';
import { DEBUG_PDF_GENERATION, printPageSlow } from './print-actions';
import {
  initialState,
  printSliceActions,
  printSliceReducer
} from './print-slice';

interface ConcussionRxPrintContextProps extends IReactToPrintProps {
  afterPrint?: (objectbUrl: string) => void | any;
}

export const ConcussionRxPrintContextRoot = createContext<
  IPrintContextProps & { isPrinting: boolean; status?: string }
>({
  handlePrint: () => !!0,
  isPrinting: false
});

/**
 * Custom print context for the ConcussionRx app
 * This overrides react-to-print when the user is on a non-Chrome browser
 */
export function ConcussionRxPrintContext(props: ConcussionRxPrintContextProps) {
  const [printState, printStateDispatch] = useReducer(
    printSliceReducer,
    initialState
  );
  const handlePrint = useConcussionRxPrintHandler(props, printStateDispatch);

  return (
    <ConcussionRxPrintContextRoot.Provider
      value={{
        handlePrint,
        isPrinting: printState.isLoading,
        status: printState.statusMessage
      }}
      {...props}
    />
  );
}

export const ConcussionRxPrintContextConsumer =
  ConcussionRxPrintContextRoot.Consumer;

export function useConcussionRxPrintHandler(
  args: ConcussionRxPrintContextProps,
  dispatch: Dispatch<any>
) {
  const handlePrint = useReactToPrint({
    ...args,
    onBeforePrint: () => {
      dispatch(printSliceActions.startLoading());
      args.onBeforePrint?.();
    },
    onAfterPrint: () => {
      dispatch(printSliceActions.setFinished());
      args.onAfterPrint?.();
    }
  });

  const print = useCallback(async () => {
    args.onBeforePrint?.();

    const url = await printPageSlow(args.content(), dispatch);
    if (url === null) return url;

    if (args.afterPrint) args.afterPrint(url);
    return url;
  }, [args, dispatch]);

  /**
   * Use Chrome's PDF export when it is available
   * Otherwise use remote PDF generation
   */
  return isChrome && !DEBUG_PDF_GENERATION ? handlePrint : print;
}
