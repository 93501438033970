import React from 'react';

import { FirebaseSignIn } from 'components/FirebaseSignIn';
import { Role } from 'features/auth/types';

export interface SignInProps {
  signInRole: Role;
}

const SignIn: React.FC<SignInProps> = (props: SignInProps) => {
  return <FirebaseSignIn {...props} />;
};

export default SignIn;
