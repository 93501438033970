import { Document } from 'documents';

import {
  createQuery,
  db,
  getDataConverter,
  OrderByCondition,
  WhereCondition
} from './';

export interface CollectionQuery {
  where?: WhereCondition | WhereCondition[];
  orderBy?: OrderByCondition;
}

export interface DocQuery extends CollectionQuery {
  id?: string;
}

export interface Repo<T extends Document> {
  getList: (query?: CollectionQuery) => Promise<T[]>;
  find: (id: string) => Promise<T | undefined>;
  first: (query?: CollectionQuery) => Promise<T | undefined>;
  add: (item: T) => Promise<string | undefined>;
  /**
   * Sets a docuemnt with id `item.id`
   */
  set: (item: T) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export function createRepo<T extends Document>(
  _path: string | string[]
): Repo<T> {
  // Join the arayy of strings to a single string
  const path = _path instanceof Array ? _path.join('/') : _path;
  const dataConverter = getDataConverter<T>();

  return {
    getList: async (query?: CollectionQuery) => {
      const snapshot = await createQuery<T>(
        path,
        db,
        dataConverter,
        query?.where,
        query?.orderBy
      )
        .get()
        .catch(error => {
          console.log(`[getList - ${path}] Caught error`, error);
          if (!(error instanceof Error)) {
            throw new Error(error);
          }

          throw new RepoError(
            `Failed to run query '${JSON.stringify(query)}' at path '${path}'`,
            {
              cause: error
            }
          );
        });
      const items: T[] = [];
      snapshot.forEach(doc => items.push(doc.data()));

      return items;
    },
    find: async id => {
      const docSnapshot = await db
        .collection(path)
        .doc(id)
        .withConverter(dataConverter)
        .get()
        .catch(error => {
          console.log(`[find - ${path}] Caught error`, error);
          if (!(error instanceof Error)) {
            throw new Error(error);
          }

          throw new RepoError(`Failed to fetch document '${path}/${id}'`, {
            cause: error
          });
        });
      return docSnapshot.data() as T;
    },
    first: async query => {
      let doc: T | undefined;
      const snapshot = await createQuery<T>(
        path,
        db,
        dataConverter,
        query?.where,
        query?.orderBy
      )
        .limit(1)
        .get()
        .catch(error => {
          console.log(`[first - ${path}] Caught error`, error);
          if (!(error instanceof Error)) {
            throw new RepoError(error);
          }

          throw new RepoError(
            `Failed to get first document at path '${path}'`,
            {
              cause: error
            }
          );
        });
      if (snapshot.docs.length > 0) {
        doc = snapshot.docs[0].data();
      }

      return doc;
    },
    add: async (item: T) => {
      const docRef = await db
        .collection(path)
        .withConverter(dataConverter)
        .add(item);
      return docRef?.id;
    },
    set: async (item: T) =>
      db.collection(path).doc(item.id).withConverter(dataConverter).set(item),
    delete: async (id: string) => db.collection(path).doc(id).delete()
  };
}

export class RepoError extends Error {
  constructor(message: string, private context?: { cause?: Error }) {
    super(message);
  }

  toString() {
    return `${this.message}. Context: ${JSON.stringify(this.context)}`;
  }
}
