import React from 'react';

import { Form } from 'antd';
import { PatientPersonalHistoryKey } from 'documents';

import { DisorderInput } from './DisorderInput';

const formItems = [
  {
    labels: ['Anxiety', 'Anxiety Tx', 'Family Hx Anxiety'],
    name: PatientPersonalHistoryKey.anxiety
  },
  {
    labels: ['Depression', 'Depression Tx', 'Family Hx Depression'],
    name: PatientPersonalHistoryKey.depression
  },
  {
    labels: ['Sleep Disorder', 'Sleep Tx', 'Family Hx Sleep'],
    name: PatientPersonalHistoryKey.sleepDisorder
  }
];

export const DisorderForm = () => {
  return (
    <>
      {formItems.map((item, index) => (
        <Form.Item key={index} name={item.name}>
          <DisorderInput labels={item.labels} />
        </Form.Item>
      ))}
    </>
  );
};
