import { PatientRoutePattern } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';

export function useCreatePatientUrl() {
  const { patientId } = useParams<{ patientId: string }>();
  const createPatientUrl = useCallback(
    (path: string) =>
      patientId ? generatePath(PatientRoutePattern, { patientId }) + path : '',
    [patientId]
  );
  return createPatientUrl;
}
