import moment, { Moment } from 'moment';
import {
  Collection,
  PatientSubcollection,
  Inventory,
  TScores
} from 'documents';
import { PageState, ThunkApi, handleError, handlePending } from 'utils/redux';
import { getTimestampedDocId } from 'utils/object';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createRepo, Repo } from 'api/firebase';
import { SliderListItem } from 'components/slider-list';

import { tScoreMapping, tScoreMapCategory } from 'documents/promisTScores';

import promisJson from 'data/promis.json';
import {
  categories,
  PromisBySection,
  PromisJson,
  tScoreCategories
} from './promisTypes';
import { savePendingAssessment } from 'features/patient/assessment/questionnaire/questionnaire-control-flow-slice';
const promisData: PromisJson = promisJson as PromisJson; // need to cast here or typescript throws error on nested reduces

interface PromisInventory extends Inventory {
  data: Record<keyof typeof tScoreMapping, number> &
    Record<ValueOf<typeof tScoreMapping>, number> &
    Record<string, number>;
}

export interface PromisState extends PageState {
  promis: PromisInventory;
  invalidItems: string[];
  lastUpdated?: Moment;
  showModal: boolean;
  isSubmitting: boolean;
  /**
   * A list of all promis section configurations from promis.json
   *
   * **readonly** This value is never modified during runtime
   */
  promisBySection: PromisBySection[];
}

export const NOT_SELECTED = -1;
const PROMIS_SUBSECTIONS: string[] = Object.keys(promisData.subsections);

export function createEmptyPromisInventory(): PromisInventory {
  // gather all questions just for submission and value entry
  const questions: Record<string, number> = {};
  PROMIS_SUBSECTIONS.forEach(section => {
    promisData.subsections[`${section}`]['questions'].reduce((acc, q) => {
      acc[q.title] = NOT_SELECTED;
      return acc;
    }, questions);
  });

  Object.keys(tScoreMapping).forEach(
    calculatedSection => (questions[calculatedSection] = null as any)
  );

  return {
    date: moment(),
    data: questions
  };
}

export const PROMIS_SECTIONS = createPromisBySection();

export function createPromisBySection(): PromisBySection[] {
  // Get each subsection in each section
  return PROMIS_SUBSECTIONS.map(
    section => promisData.subsections[`${section}`]
  );
}

let promisRepo: Repo<Inventory>;
let _patientId: string;
const initialLoad = createAsyncThunk(
  'promis/initialLoad',
  async (patientId: string) => {
    promisRepo = createRepo<Inventory>(
      `${Collection.Patients}/${patientId}/${PatientSubcollection.InventoriesPromis}`
    );
    _patientId = patientId;

    return await promisRepo.first({
      orderBy: ['date', 'desc']
    });
  }
);

const submit = createAsyncThunk<boolean, void, ThunkApi<PromisState>>(
  'promis/submit',
  async (_, { getState, dispatch }) => {
    dispatch(promisSlice.actions.validate());

    const { invalidItems } = getState();
    if (invalidItems.length) {
      console.error(
        'Submission was stopped because there are still invalid items present',
        invalidItems
      );
      return false;
    }

    dispatch(promisSlice.actions.setPromisDate());
    dispatch(promisSlice.actions.calculateCategories());
    dispatch(promisSlice.actions.calculateTScores());

    const { promis } = getState();
    await savePendingAssessment(
      _patientId,
      PatientSubcollection.InventoriesPromis,
      promis
    );
    return true;
  }
);

export const initialState: PromisState = {
  hasError: false,
  promis: createEmptyPromisInventory(), // should this be the current page's questions? or the total amount of questions or should the filter be on the react component?
  invalidItems: [],
  isLoading: false,
  isSubmitting: false,
  showModal: false,
  promisBySection: createPromisBySection()
};

const promisSlice = createSlice({
  name: 'promis',
  initialState,
  reducers: {
    setPromisDate: state => {
      const now = moment();
      state.promis.id = getTimestampedDocId(now);
      state.promis.date = now;
    },
    closeModal: state => {
      state.showModal = false;
    },
    updatePromisItem: (state, { payload }: PayloadAction<SliderListItem>) => {
      const { title, value } = payload;
      state.invalidItems = state.invalidItems.filter(name => name != title);
      state.promis.data[title] = value;
    },
    validate: state => {
      state.invalidItems = Object.entries(state.promis.data)
        .filter(([, value]) => value === NOT_SELECTED)
        .map(([key]) => key);
    },
    /**
     * Calculate the scores for each category from the filled questions
     */
    calculateCategories: state => {
      // we need mapping object from section => actual saved "value"
      // we calculate individual section totals and have another function that calculates the tscore interpretations
      // set category totals on state.promis.data to 0
      for (const category of categories) {
        state.promis.data[category] = 0;
      }

      // this is kind of ugly
      // For each section
      state.promisBySection.forEach(section => {
        // For each question in the section
        section.questions.forEach(question => {
          const value = state.promis.data[question.title];
          // For each category in the question
          question.categories.forEach((category: string) => {
            // Aggregate the question value into promis.data[questionCategory]
            state.promis.data[category] += value;
          });
        });
      });
    },
    calculateTScores: state => {
      // For each t-score category
      for (const category of tScoreCategories) {
        // Get the raw score
        const rawScore = state.promis.data[category];

        // Transform the category key to the t-score key to use when saving
        const mapping = tScoreMapping[category];

        // Get the t-score data transformer to use
        const tCategory = tScoreMapCategory[category];

        // Apply the transformation
        const tScore = TScores.findTScore(rawScore, tCategory);

        // Save the change
        state.promis.data[mapping] = tScore;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(initialLoad.pending, handlePending);
    builder.addCase(initialLoad.rejected, handleError);
    builder.addCase(initialLoad.fulfilled, (state, { payload }) => {
      // The data has finished loading
      state.isLoading = false;
      state.lastUpdated = payload ? payload.date : undefined;
    });

    builder.addCase(submit.pending, state => {
      state.isSubmitting = true;
    });
    builder.addCase(submit.rejected, handleError);
    builder.addCase(submit.fulfilled, (state, { payload: isSaved }) => {
      state.isSubmitting = false;
      if (isSaved) {
        state.showModal = isSaved;
        state.lastUpdated = moment().utc(true);
        state.invalidItems = [];
        state.promis = createEmptyPromisInventory(); // subsection does not need to be reset here because values are never entered into subsection
      }
    });
  }
});

export function createPromisRepo(patientId: string) {
  return createRepo<PromisState['promis']>(
    `${Collection.Patients}/${patientId}/${PatientSubcollection.InventoriesPromis}`
  );
}

export { initialLoad, submit, categories, tScoreCategories };

export const {
  setPromisDate,
  updatePromisItem,
  calculateCategories,
  calculateTScores,
  closeModal,
  validate
} = promisSlice.actions;
export const PromisSliceReducer = promisSlice.reducer;
export default promisSlice.reducer;
