import { Text } from 'components/mvp-typography';

export function ErrorDisplay(props: {
  error: Error;
  title: string;
  description: string;
  style?: React.CSSProperties;
}) {
  return (
    <div
      style={{
        background: 'red',
        borderRadius: 6,
        padding: '1rem',
        color: 'white',
        maxWidth: '50vw',
        ...props.style
      }}
    >
      <Text>{props.title}</Text>
      <Text>{props.description}</Text>
      <code color="white">{props.error.message}</code>
    </div>
  );
}
