import {
  addClinician,
  clinicActionEditClinicianDetails,
  clinicActionResendInvitation,
  listClinicians,
  ListUserAPIOptions
} from 'api/firebase/firebase-api';
import { Clinician } from 'documents/clinician';
import { isNil, omitBy } from 'lodash';
import { ActionState } from './action-tag-component';
import { ActionTagEditDetails } from './action-tag-edit-details';
import { BASE_USER_ADD_QUESTIONS } from './user-add-modal-component';
import UserList from './user-list-component';

export function ClinicianTable(pprops: { clinicId: string }) {
  return (
    <UserList<Clinician>
      typeUnit="Clinician"
      apiAddUser={(user, clinicId) => addClinician({ ...user, clinicId })}
      api={({ clinicId, lastUserId, lastPendingUserId }) =>
        listClinicians(
          omitBy(
            {
              clinicId,
              pendingUsersLimit: 10,
              usersLimit: 10,
              pendingUsersLastDocId: lastPendingUserId,
              usersLastDocId: lastUserId
            },
            isNil
          ) as any as ListUserAPIOptions
        )
      }
      clinicId={pprops.clinicId}
      actions={[
        {
          name: 'Edit details',
          initialState: () => ActionState.NEUTRAL,
          render: props => (
            <ActionTagEditDetails
              {...props}
              userAddQuestions={BASE_USER_ADD_QUESTIONS}
            />
          ),
          onClick: p => {
            if (!p.raw.id) {
              throw new Error('ID is undefined. Please try reloading the page');
            }

            return clinicActionEditClinicianDetails(
              p.raw.id,
              p.firstName,
              p.lastName,
              p.email
            );
          }
        },
        {
          name: 'Resend invitation',
          shouldShow: p => p.invitePending,
          initialState: () => ActionState.NEUTRAL,
          onClick: p => {
            if (!p.raw.id)
              throw new Error('ID is undefined. Please try reloading the page');
            return clinicActionResendInvitation(p.raw.id);
          }
        }
      ]}
      userAddQuestions={BASE_USER_ADD_QUESTIONS}
    />
  );
}
