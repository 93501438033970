import { Flex, Space } from 'antd';
import { ActionButtonLink } from 'components/card/card-action-button-link';
import { Title, Text } from 'components/mvp-typography';

export function ConcussionKnowledge() {
  return (
    <Flex vertical justify={'space-between'} style={{ height: '100%' }}>
      <Space direction="vertical">
        <Title level={2} color="initial">
          Concussion Knowledge <small>(coming soon)</small>
        </Title>
        <Text color="initial">
          Historically, the complexity of concussion was hindered by the
          oversimplification of the injury, with recent efforts focused on
          subtyping according to one system
        </Text>
      </Space>
      <div>
        <ActionButtonLink to={'#'} disabled={true}>
          Learn more
        </ActionButtonLink>
      </div>
    </Flex>
  );
}
