import { Typography } from 'antd';
import { LinkProps } from 'antd/lib/typography/Link';
import { TextProps } from 'antd/lib/typography/Text';
import { TitleProps } from 'antd/lib/typography/Title';
import clsx from 'clsx';
import React from 'react';

type CSSStyleProp = React.CSSProperties;
type ConfigurableStyles = Pick<CSSStyleProp, 'color'>;

interface TypographyConfig extends ConfigurableStyles {
  uppercase?: boolean;
}

type TypographyProps<T> = T &
  (
    | { text?: React.ReactNode; children?: never }
    | { text?: never; children?: React.ReactNode }
  ) &
  TypographyConfig;

function baseStyles(props: TypographyConfig): CSSStyleProp {
  return {
    textTransform: props.uppercase ? 'uppercase' : 'none',
    color: props.color ?? 'inherit'
  };
}

export const Title = (props: TypographyProps<TitleProps>) => (
  <Typography.Title
    level={1}
    {...props}
    // eslint-disable-next-line
    children={props.children ? props.children : props.text}
    className={clsx(`crx-typography h${props.level ?? 1}`, props.className)}
    style={{
      ...baseStyles(props),
      ...props.style
    }}
  />
);

export const Subtitle = (props: TypographyProps<TitleProps>) => (
  <Typography.Title
    level={3}
    {...props}
    // eslint-disable-next-line
    children={props.children ? props.children : props.text}
    className={clsx('crx-typography h3', props.className)}
    style={{
      ...baseStyles(props),
      ...props.style
    }}
  />
);

export const Text = (props: TypographyProps<TextProps>) => (
  <Typography
    {...props}
    // eslint-disable-next-line
    children={props.children ? props.children : props.text}
    className={clsx('crx-typography body-normal', props.className)}
    style={{
      ...baseStyles(props),
      ...props.style
    }}
  />
);

export const Link = (props: TypographyProps<LinkProps>) => (
  <Typography.Link
    {...props}
    // eslint-disable-next-line
    children={props.children ? props.children : props.text}
    className={clsx('crx-typography', props.className)}
    style={{
      ...baseStyles(props),
      textDecoration: 'underline',
      ...props.style
    }}
  />
);
