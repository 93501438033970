import { PrintableRootPage } from 'components/page/page-2';
import { PatientInfoContext } from 'components/patient-info/hook-context-patientinfo';
import patientDashboardSlice, {
  patientInfoInitialLoad,
  initialState
} from 'components/patient-info/patientInfoSlice';
import { useReducer } from 'hooks/useReducer';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PatientHomeDiscovery } from '../dashboard';

export function PatientHomeClinician() {
  const { patientId } = useParams<{ patientId: string }>();

  const [patientInfo, patientInfoDispatch] = useReducer(patientDashboardSlice, {
    ...initialState
  });

  useEffect(() => {
    patientInfoDispatch(patientInfoInitialLoad(patientId));
  }, [patientId]);

  return (
    <PrintableRootPage
      title="Patient Home"
      hideTitle={!!patientInfo.patient.id}
      hasError={patientInfo.hasError}
    >
      <PatientInfoContext.Provider
        value={{
          patientInfo,
          dispatch: patientInfoDispatch
        }}
      >
        <PatientHomeDiscovery />
      </PatientInfoContext.Provider>
    </PrintableRootPage>
  );
}
