import { Skeleton } from 'antd';
import { useAppSelector } from 'app/rootReducer';
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router-dom';

interface PublicRouteProps extends Omit<RouteProps, 'render' | 'children'> {
  /**
   * If `publicOnly` is set to true then only non-authenticated users can see the route.
   * All other users will be redirected
   */
  publicOnly?: boolean;
  redirectPath: RedirectProps['to'];
}

export function PublicRoute({
  publicOnly,
  redirectPath,
  component: Component,
  ...props
}: PublicRouteProps) {
  const user = useAppSelector(state => state.auth.user);
  const isLoading = useAppSelector(state => state.auth.isUserDataLoading);

  if (!Component) return <>Nothing here</>;
  if (!user && isLoading) return <Skeleton active />;

  return (
    <Route
      {...props}
      render={args => {
        if (publicOnly && user) {
          /**
           * publicOnly = true
           *
           * Redirect the user because they are signed in
           */
          return <Redirect to={redirectPath} />;
        }

        /**
         * Render the component if this component is available for everyone
         * Otherwise only render for non-authenticated users
         */
        return <Component {...args} />;
      }}
    />
  );
}
