import './SiteHeader.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Col, Layout, Row } from 'antd';

import { RootState } from 'app/rootReducer';
import { UserInfo } from 'components/user-info';
import { toggleMenuCollapse } from 'features/navigation/navigationSlice';

const { Header } = Layout;

export const SiteHeader: React.FC = () => {
  const dispatch = useDispatch();

  const triggerClick = () => dispatch(toggleMenuCollapse());

  const user = useSelector((state: RootState) => state.auth.user);
  const collapsed = useSelector(
    (state: RootState) => state.uiPref.menuCollapsed
  );

  return (
    <Header className="crx-header">
      <Row justify="space-between">
        <Col>
          {user && (
            <div className="trigger" onClick={triggerClick}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
          )}
        </Col>
        <Col>{user && <UserInfo user={user} />}</Col>
      </Row>
    </Header>
  );
};
