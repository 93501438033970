import React from 'react';
import './TermsOfService.scss';

export const TermsOfService = () => (
  <div className="tos-container">
    <p id="h.eytts2u4g0dq">
      <span>TERMS OF SERVICE</span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>Last updated March 19, 2024</span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.gezoa7o3jvzz">
      <span>AGREEMENT TO OUR LEGAL TERMS</span>
    </h2>
    <p>
      <span>
        We are BrainRx Technologies Ltd. (&#39;Company&#39;, &#39;we&#39;,
        &#39;us&#39;, or &#39;our&#39;), a company
      </span>
    </p>
    <p>
      <span>
        registered in Canada at 1367 West Broadway, Unit 110, Vancouver, British
        Columbia
      </span>
    </p>
    <p>
      <span>V6H 4A7.</span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        We operate the website concussionrx.com (the &#39;Site&#39;), as well as
        any other related
      </span>
    </p>
    <p>
      <span>
        products and services that refer or link to these legal terms (the
        &#39;Legal Terms&#39;)
      </span>
    </p>
    <p>
      <span>(collectively, the &#39;Services&#39;).</span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        You can contact us by email at contact@concussionrx.com or by mail to
        1367 West
      </span>
    </p>
    <p>
      <span>
        Broadway, Unit 110, Vancouver, British Columbia V6H 4A7, Canada.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        These Legal Terms constitute a legally binding agreement made between
        you,
      </span>
    </p>
    <p>
      <span>
        whether personally or on behalf of an entity (&#39;you&#39;), and
        BrainRx Technologies Ltd.,
      </span>
    </p>
    <p>
      <span>
        concerning your access to and use of the Services. You agree that by
        accessing the
      </span>
    </p>
    <p>
      <span>
        Services, you have read, understood, and agreed to be bound by all of
        these Legal
      </span>
    </p>
    <p>
      <span>
        Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU
      </span>
    </p>
    <p>
      <span>ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST</span>
    </p>
    <p>
      <span>DISCONTINUE USE IMMEDIATELY.</span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        Supplemental terms and conditions or documents that may be posted on the
      </span>
    </p>
    <p>
      <span>
        Services from time to time are hereby expressly incorporated herein by
        reference.
      </span>
    </p>
    <p>
      <span>
        We reserve the right, in our sole discretion, to make changes or
        modifications to
      </span>
    </p>
    <p>
      <span>
        these Legal Terms at any time and for any reason. We will alert you
        about any
      </span>
    </p>
    <p>
      <span>
        changes by updating the &#39;Last updated&#39; date of these Legal
        Terms, and you waive any right to receive specific notice of each such
        change. It is your responsibility to periodically review these Legal
        Terms to stay informed of updates. You will be subject to, and will be
        deemed to have been made aware of and to have accepted, the changes in
        any revised Legal Terms by your continued use of the Services after the
        date such revised Legal Terms are posted.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        The Services are intended for users who are at least 18 years old.
        Persons under the age of 18 are not permitted to use or register for the
        Services.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        We recommend that you print a copy of these Legal Terms for your
        records.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.3ws0q6mnvo9a">
      <span>TABLE OF CONTENTS</span>
    </h2>
    <p>
      <span>1. OUR SERVICES</span>
    </p>
    <p>
      <span>2. INTELLECTUAL PROPERTY RIGHTS</span>
    </p>
    <p>
      <span>3. USER REPRESENTATIONS</span>
    </p>
    <p>
      <span>4. USER REGISTRATION</span>
    </p>
    <p>
      <span>5. PROHIBITED ACTIVITIES</span>
    </p>
    <p>
      <span>6. USER GENERATED CONTRIBUTIONS</span>
    </p>
    <p>
      <span>7. CONTRIBUTION</span>
    </p>
    <p>
      <span>LICENCE</span>
    </p>
    <p>
      <span>8. GUIDELINES FOR REVIEWS</span>
    </p>
    <p>
      <span>9. THIRD-PARTY WEBSITES AND CONTENT</span>
    </p>
    <p>
      <span>10. SERVICES MANAGEMENT</span>
    </p>
    <p>
      <span>11. PRIVACY POLICY</span>
    </p>
    <p>
      <span>12. TERM AND TERMINATION</span>
    </p>
    <p>
      <span>13. MODIFICATIONS AND INTERRUPTIONS</span>
    </p>
    <p>
      <span>14. GOVERNING LAW</span>
    </p>
    <p>
      <span>15. DISPUTE RESOLUTION</span>
    </p>
    <p>
      <span>16. CORRECTIONS</span>
    </p>
    <p>
      <span>17. DISCLAIMER</span>
    </p>
    <p>
      <span>18. LIMITATIONS OF LIABILITY</span>
    </p>
    <p>
      <span>19. INDEMNIFICATION</span>
    </p>
    <p>
      <span>20. USER DATA</span>
    </p>
    <p>
      <span>21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span>
    </p>
    <p>
      <span>22. CALIFORNIA USERS AND RESIDENTS</span>
    </p>
    <p>
      <span>23. MISCELLANEOUS</span>
    </p>
    <p>
      <span>24. CONTACT US</span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.e4p948fuof5y">
      <span>1. OUR SERVICES</span>
    </h2>
    <p>
      <span>
        The information provided when using the Services is not intended for
        distribution or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Services from other locations do so on their own
        initiative and are solely responsible for compliance with local laws, if
        and to the extent local laws are applicable.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        The Services are not tailored to comply with industry-specific
        regulations (Health Insurance Portability and Accountability Act
        (HIPAA), Federal Information Security Management Act (FISMA), etc.), so
        if your interactions would be subjected to such laws, you may not use
        the Services. You may not use the Services in a way that would violate
        the Gramm-Leach-Bliley Act (GLBA).
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.s6zfqm35lwgx">
      <span>2. INTELLECTUAL PROPERTY RIGHTS</span>
    </h2>
    <h3 id="h.ddzcpbwcta12">
      <span>Our intellectual property</span>
    </h3>
    <p>
      <span>
        We are the owner or the licensee of all intellectual property rights in
        our Services, including all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        in the Services (collectively, the &#39;Content&#39;), as well as the
        trademarks, service marks, and logos contained therein (the
        &#39;Marks&#39;).
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        Our Content and Marks are protected by copyright and trademark laws (and
        various other intellectual property rights and unfair competition laws)
        and treaties in the United States and around the world.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        The Content and Marks are provided in or through the Services &#39;AS
        IS&#39; for your personal, non-commercial use or internal business
        purpose only.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h3 id="h.a70xvuie49dn">
      <span>Your use of our Services</span>
    </h3>
    <p>
      <span>
        Subject to your compliance with these Legal Terms, including the
        &#39;PROHIBITED ACTIVITIES&#39; section below, we grant you a
        non-exclusive, non-transferable, revocable licence to:
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <ul>
      <li>
        <span>access the Services; and</span>
      </li>
      <li>
        <span>
          download or print a copy of any portion of the Content to which you
          have properly gained access.
        </span>
      </li>
    </ul>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        solely for your personal, non-commercial use or internal business
        purpose.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        Except as set out in this section or elsewhere in our Legal Terms, no
        part of the Services and no Content or Marks may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise
        exploited for any commercial purpose whatsoever, without our express
        prior written permission.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        If you wish to make any use of the Services, Content, or Marks other
        than as set out in this section or elsewhere in our Legal Terms, please
        address your request to:
      </span>
    </p>
    <p>
      <span>
        contact@concussionrx.com. If we ever grant you the permission to post,
        reproduce, or publicly display any part of our Services or Content, you
        must identify us as the owners or licensors of the Services, Content, or
        Marks and ensure that any copyright or proprietary notice appears or is
        visible on posting, reproducing, or displaying our Content.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        We reserve all rights not expressly granted to you in and to the
        Services, Content, and Marks.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        Any breach of these Intellectual Property Rights will constitute a
        material breach of our Legal Terms and your right to use our Services
        will terminate immediately.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h3 id="h.1frrxf7iopmg">
      <span>Your submissions</span>
    </h3>
    <p>
      <span>
        Please review this section and the &#39;PROHIBITED ACTIVITIES&#39;
        section carefully prior to using our Services to understand the (a)
        rights you give us and (b) obligations you have when you post or upload
        any content through the Services.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>Submissions: </span>
      <span>
        By directly sending us any question, comment, suggestion, idea,
        feedback, or other information about the Services
        (&#39;Submissions&#39;), you agree to assign to us all intellectual
        property rights in such Submission. You agree that we all own this
        Submission and be entitled to its unrestricted use and dissemination for
        any lawful purpose, commercial or otherwise, without acknowledgment or
        compensation to you.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>You are responsible for what you post or upload: </span>
      <span>By sending us Submissions</span>
    </p>
    <p>
      <span>through any part of the Services you:</span>
    </p>
    <ul>
      <li>
        <span>
          confirm that you have read and agree with our &#39;PROHIBITED
          ACTIVITIES&#39; and will not post, send, publish, upload, or transmit
          through the Services any Submission that is illegal, harassing,
          hateful, harmful, defamatory, obscene, bullying, abusive,
          discriminatory, threatening to any person or group, sexually explicit,
          false, inaccurate, deceitful, or misleading;
        </span>
      </li>
    </ul>
    <ul>
      <li>
        <span>
          to the extent permissible by applicable law, waive any and all moral
          rights to any such Submission;
        </span>
      </li>
      <li>
        <span>
          warrant that any such Submission are original to you or that you have
          the necessary rights and licences to submit such Submissions and that
          you have full authority to grant us the above-mentioned rights in
          relation to your Submissions; and
        </span>
      </li>
      <li>
        <span>
          warrant and represent that your Submissions do not constitute
          confidential information.
        </span>
      </li>
    </ul>
    <p>
      <span>
        You are solely responsible for your Submissions and you expressly agree
        to reimburse us for any and all losses that we may suffer because of
        your breach of (a) this section, (b) any third party&rsquo;s
        intellectual property rights, or (c) applicable law.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.y1k1ok40dsl">
      <span>3. USER REPRESENTATIONS</span>
    </h2>
    <p>
      <span>
        By using the Services, you represent and warrant that: (1) all
        registration information you submit will be true, accurate, current, and
        complete; (2) you will maintain the accuracy of such information and
        promptly update such registration information as necessary; (3) you have
        the legal capacity and you agree to comply with these LegalTerms; (4)
        you are not a minor in the jurisdiction in which you reside; (5) you
        will not access the Services through automated or non-human means,
        whether through a bot, script or otherwise; (6) you will not use the
        Services for any illegal or unauthorized purpose; and (7) your use of
        the Services will not violate any applicable law or regulation.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Services (or any
        portion thereof).
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.62nh8v1swnze">
      <span>4. USER REGISTRATION</span>
    </h2>
    <p>
      <span>
        You may be required to register to use the Services. You agree to keep
        your password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.t9jyh9pnm23z">
      <span>5. PROHIBITED ACTIVITIES</span>
    </h2>
    <p>
      <span>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial
      </span>
    </p>
    <p>
      <span>
        endeavours except those that are specifically endorsed or approved by
        us.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>As a user of the Services, you agree not to:</span>
    </p>
    <ul>
      <li>
        <span>
          Systematically retrieve data or other content from the Services to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </span>
      </li>
      <li>
        <span>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </span>
      </li>
      <li>
        <span>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Services, including features that prevent or restrict
          the use or copying of any Content or enforce limitations on the use of
          the Services and/or the Content contained therein.
        </span>
      </li>
      <li>
        <span>
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Services.
        </span>
      </li>
      <li>
        <span>
          Use any information obtained from the Services in order to harass,
          abuse, or harm another person.
        </span>
      </li>
      <li>
        <span>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </span>
      </li>
      <li>
        <span>
          Use the Services in a manner inconsistent with any applicable laws or
          regulations.
        </span>
      </li>
      <li>
        <span>
          Engage in unauthorized framing of or linking to the Services.
        </span>
      </li>
      <li>
        <span>
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming(continuous posting of repetitive text), that
          interferes with any party&rsquo;s uninterrupted use and enjoyment of
          the Services or modifies, impairs, disrupts, alters, or interferes
          with the use, features, functions, operation, or maintenance of the
          Services.
        </span>
      </li>
      <li>
        <span>
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </span>
      </li>
      <li>
        <span>
          Delete the copyright or other proprietary rights notice from any
          Content.
        </span>
      </li>
      <li>
        <span>
          Attempt to impersonate another user or person or use the username of
          another user.
        </span>
      </li>
      <li>
        <span>
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (&#39;gifs&#39;), 1&times;1pixels, web bugs,
          cookies, or other similar devices (sometimes referred to as
          &#39;spyware&#39; or &#39;passive collection mechanisms&#39; or
          &#39;pcms&#39;).
        </span>
      </li>
      <li>
        <span>
          Interfere with, disrupt, or create an undue burden on the Services or
          the networks or services connected to the Services.
        </span>
      </li>
      <li>
        <span>
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Services to you.
        </span>
      </li>
      <li>
        <span>
          Attempt to bypass any measures of the Services designed to prevent or
          restrict access to the Services, or any portion of the Services.
        </span>
      </li>
      <li>
        <span>
          Copy or adapt the Services&#39; software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
        </span>
      </li>
      <li>
        <span>
          Except as permitted by applicable law, decipher, decompile,
          disassemble, or reverse engineer any of the software comprising or in
          any way making up a part of the Services.
        </span>
      </li>
      <li>
        <span>
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Services, or use
          or launch any unauthorized script or other software.
        </span>
      </li>
      <li>
        <span>
          Use a buying agent or purchasing agent to make purchases on the
          Services.
        </span>
      </li>
      <li>
        <span>
          Make any unauthorized use of the Services, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretences.
        </span>
      </li>
      <li>
        <span>
          Use the Services as part of any effort to compete with us or otherwise
          use the Services and/or the Content for any revenue-generating
          endeavour or commercial enterprise.
        </span>
      </li>
    </ul>
    <p>
      <span></span>
    </p>
    <h2 id="h.jxbhcyvx4h3p">
      <span>6. USER GENERATED CONTRIBUTIONS</span>
    </h2>
    <p>
      <span>
        The Services does not offer users to submit or post content. We may
        provide you with the opportunity to create, submit, post, display,
        transmit, perform, publish, distribute, or broadcast content and
        materials to us or on the Services, including but not limited to text,
        writings, video, audio, photographs, graphics, comments, suggestions, or
        personal information or other material (collectively,
        &#39;Contributions&#39;). Contributions may be viewable by other users
        of the Services and through third-party websites. As such, any
        Contributions you transmit may be treated in accordance with the
        Service&#39;s Privacy Policy. When you create or make available any
        Contributions, you thereby represent and warrant that:
      </span>
    </p>
    <ul>
      <li>
        <span>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </span>
      </li>
      <li>
        <span>
          You are the creator and owner of or have the necessary licences,
          rights, consents, releases, and permissions to use and to authorize
          us, the Services, and other users of the Services to use your
          Contributions in any manner contemplated by the Services and these
          Legal Terms.
        </span>
      </li>
      <li>
        <span>
          You have the written consent, release, and/or permission of each and
          every identifiable individual person in your Contributions to use the
          name or likeness of each and every such identifiable individual person
          to enable inclusion and use of your Contributions in any manner
          contemplated by the Services and these Legal Terms.
        </span>
      </li>
      <li>
        <span>
          Your Contributions are not false, inaccurate, or misleading.
        </span>
      </li>
      <li>
        <span>
          Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </span>
      </li>
      <li>
        <span>
          Your Contributions are not obscene, lewd, lascivious, filthy, violent,
          harassing, libellous, slanderous, or otherwise objectionable (as
          determined by us).
        </span>
      </li>
      <li>
        <span>
          Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </span>
      </li>
      <li>
        <span>
          Your Contributions are not used to harass or threaten (in the legal
          sense of those terms) any other person or to promote violence against
          a specific person or class of people.
        </span>
      </li>
      <li>
        <span>
          Your Contributions do not violate any applicable law, regulation, or
          rule.
        </span>
      </li>
      <li>
        <span>
          Your Contributions do not violate the privacy or publicity rights of
          any third party.
        </span>
      </li>
      <li>
        <span>
          Your Contributions do not violate any applicable law concerning child
          pornography, or otherwise intended to protect the health or well-being
          of minors.
        </span>
      </li>
      <li>
        <span>
          Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </span>
      </li>
      <li>
        <span>
          Your Contributions do not otherwise violate, or link to material that
          violates, any provision of these Legal Terms, or any applicable law or
          regulation.
        </span>
      </li>
      <li>
        <span>
          Any use of the Services in violation of the foregoing violates these
          Legal Terms and may result in, among other things, termination or
          suspension of your rights to use the Services.
        </span>
      </li>
    </ul>
    <p>
      <span></span>
    </p>
    <h2 id="h.198b9waloker">
      <span>7. CONTRIBUTION LICENCE</span>
    </h2>
    <p>
      <span>
        You and Services agree that we may access, store, process, and use any
        information and personal data that you provide following the terms of
        the Privacy Policy and your choices (including settings).
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        By submitting suggestions or other feedback regarding the Services, you
        agree that we can use and share such feedback for any purpose without
        compensation to you.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Services. You are
        solely responsible for your Contributions to the Services and you
        expressly agree to exonerate us from any and all responsibility and to
        refrain from any legal action against us regarding your Contributions.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.yz4av5k9rknu">
      <span>8. GUIDELINES FOR REVIEWS</span>
    </h2>
    <p>
      <span>
        We may provide you with areas on the Services to leave reviews or
        ratings. When posting a review, you must comply with the following
        criteria: (1) you should have firsthand experience with the
        person/entity being reviewed; (2) your reviews should not contain
        offensive profanity or abusive, racist, offensive, or hateful language;
        (3) your reviews should not contain discriminatory references based on
        religion, race, gender, national origin, age, marital status, sexual
        orientation, or disability; (4) your reviews should not contain
        references to illegal activity; (5) you should not be affiliated with
        competitors if posting negative reviews; (6) you should not make any
        conclusions as to the legality of conduct; (7) you may not post any
        false or misleading statements; and (8) you may not organize a campaign
        encouraging others to post reviews, whether positive or negative.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        We may accept, reject, or remove reviews in our sole discretion. We have
        absolutely no obligation to screen reviews or to delete reviews, even if
        anyone considers reviews objectionable or inaccurate. Reviews are not
        endorsed by us and do not necessarily represent our opinions or the
        views of any of our affiliates or partners. We do not assume liability
        for any review or for any claims, liabilities, or losses resulting from
        any review. By posting a review, you hereby grant to us a perpetual,
        non-exclusive, worldwide, royalty-free, fully paid, assignable, and
        sublicensable right and licence to reproduce, modify, translate,
        transmit by any means, display, perform, and/or distribute all content
        relating to the review.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.3c5tce7elhy2">
      <span>9. THIRD-PARTY WEBSITES AND CONTENT</span>
    </h2>
    <p>
      <span>
        The Services may contain (or you may be sent via the Site) links to
        other websites (&#39;Third-Party Websites&#39;) as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software, and other content or items
        belonging to or originating from third parties (&#39;Third-Party
        Content&#39;). Such Third-Party Websites and Third-Party Content are not
        investigated, monitored, or checked for accuracy, appropriateness, or
        completeness by us, and we are not responsible for any Third-Party
        Websites accessed through the Services or any Third-Party Content posted
        on, available through, or installed from the Services, including the
        content, accuracy, offensiveness, opinions, reliability, privacy
        practices, or other policies of or contained in the Third-Party
      </span>
    </p>
    <p>
      <span>
        Websites or the Third-Party Content. Inclusion of, linking to, or
        permitting the use or installation of any Third-Party Websites or any
        Third-Party Content does not imply approval or endorsement thereof by
        us. If you decide to leave the Services and access the Third-Party
      </span>
    </p>
    <p>
      <span>
        Websites or to use or install any Third-Party Content, you do so at your
        own risk, and you should be aware these Legal Terms no longer govern.
        You should review the applicable terms and policies, including privacy
        and data gathering practices, of any website to which you navigate from
        the Services or relating to any applications you use or install from the
        Services. Any purchases you make through Third-Party Websites will be
        through other websites and from other companies, and we take no
        responsibility whatsoever in relation to such purchases which are
        exclusively between you and the applicable third party. You agree and
        acknowledge that we do not endorse the products or services offered on
        Third-Party
      </span>
    </p>
    <p>
      <span>
        Websites and you shall hold us blameless from any harm caused by your
        purchase of such products or services. Additionally, you shall hold us
        blameless from any losses sustained by you or harm caused to you
        relating to or resulting in any way from any Third-Party Content or any
        contact with Third-Party Websites.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.l4akbsdzg4jp">
      <span>10. SERVICES MANAGEMENT</span>
    </h2>
    <p>
      <span>
        We reserve the right, but not the obligation, to: (1) monitor the
        Services for violations of these Legal Terms; (2) take appropriate legal
        action against anyone who, in our sole discretion, violates the law or
        these Legal Terms, including without limitation, reporting such user to
        law enforcement authorities; (3) in our sole discretion and without
        limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your
        Contributions or any portion thereof; (4) in our sole discretion and
        without limitation, notice, or liability, to remove from the Services or
        otherwise disable all files and content that are excessive in size or
        are in any way burdensome to our systems; and (5) otherwise manage the
        Services in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Services.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.ewhr1rgvlgnm">
      <span>11. PRIVACY POLICY</span>
    </h2>
    <p>
      <span>
        We care about data privacy and security. Please review our Privacy
        Policy:{' '}
      </span>
      <span>app.concussionrx.com/policy</span>
      <span>
        . By using the Services, you agree to be bound by our Privacy Policy,
        which is incorporated into these Legal Terms. Please be advised the
        Services are hosted in Canada. If you access the Services from any other
        region of the world with laws or other requirements governing personal
        data collection, use, or disclosure that differ from applicable laws in
        Canada, then through your continued use of the Services, you are
        transferring your data to Canada, and you expressly consent to have your
        data transferred to and processed in Canada.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.f6nsm8mffgvt">
      <span>12. TERM AND TERMINATION</span>
    </h2>
    <p>
      <span>
        These Legal Terms shall remain in full force and effect while you use
        the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE
        LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANYREPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE LEGALTERMS OR OF ANY APPLICABLE LAW OR
        REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES
        OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT
        ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party. In addition to terminating or
        suspending your account, we reserve the right to take appropriate legal
        action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.92xjs1qkwj6a">
      <span>13. MODIFICATIONS AND INTERRUPTIONS</span>
    </h2>
    <p>
      <span>
        We reserve the right to change, modify, or remove the contents of the
        Services at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our
        Services.
      </span>
    </p>
    <p>
      <span>
        We will not be liable to you or any third party for any modification,
        price change, suspension, or discontinuance of the Services.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span>
        We cannot guarantee the Services will be available at all times. We may
        experience hardware, software, or other problems or need to perform
        maintenance related to the services, resulting in interruptions, delays,
        or errors. We reserve the right to change, revise, update, suspend,
        discontinue, or otherwise modify the Services at any time or for any
        reason without notice to you. You agree that we have no liability
        whatsoever for any loss, damage, or inconvenience caused by your
        inability to access or use the Services during any downtime or
        discontinuance of the Services. Nothing in these Legal Terms will be
        construed to obligate us to maintain and support the Services or to
        supply any corrections, updates, or releases in connection therewith.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.t39zmfjfl1tx">
      <span>14. GOVERNING LAW</span>
    </h2>
    <p>
      <span>
        These Legal Terms shall be governed by and defined following the laws of
        Canada.
      </span>
    </p>
    <p>
      <span>
        BrainRx Technologies Ltd. and yourself irrevocably consent that the
        courts of Canada
      </span>
    </p>
    <p>
      <span>
        shall have exclusive jurisdiction to resolve any dispute which may arise
        in connection with these Legal Terms.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.a0hcj12o80zc">
      <span>15. DISPUTE RESOLUTION</span>
    </h2>
    <h3 id="h.71oyc1t3rbtc">
      <span>Informal Negotiations</span>
    </h3>
    <p>
      <span>
        To expedite resolution and control the cost of any dispute, controversy,
        or claim related to these Legal Terms (each a &#39;Dispute&#39; and
        collectively, the &#39;Disputes&#39;) brought by either you or us
        (individually, a &#39;Party&#39; and collectively, the
        &#39;Parties&#39;), the Parties agree to first attempt to negotiate any
        Dispute (except those Disputes expressly provided below) informally for
        at least thirty (30) days before initiating arbitration. Such informal
        negotiations commence upon written notice from one Party to the other
        Party.
      </span>
    </p>
    <h3 id="h.xeyd0ggpvzoz">
      <span>Binding Arbitration</span>
    </h3>
    <p>
      <span>
        Any dispute arising out of or in connection with these Legal Terms,
        including any question regarding its existence, validity, or
        termination, shall be referred to and finally resolved by the
        International Commercial Arbitration Court under the EuropeanArbitration
        Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules
        of this ICAC, which, as a result of referring to it, is considered as
        the part of this clause. The number of arbitrators shall be one (1). The
        seat, or legal place, or arbitration shall be Vancouver, Canada. The
        language of the proceedings shall be English. The governing law of these
        Legal Terms shall be substantive law of Canada.
      </span>
    </p>
    <h3 id="h.6iixb2hki8x">
      <span>Restrictions</span>
    </h3>
    <p>
      <span>
        The Parties agree that any arbitration shall be limited to the Dispute
        between the Parties individually. To the full extent permitted by law,
        (a) no arbitration shall be joined with any other proceeding; (b) there
        is no right or authority for any Dispute to be arbitrated on a
        class-action basis or to utilize class-action procedures; and (c)there
        is no right or authority for any Dispute to be brought in a purported
        representative capacity on behalf of the general public or any other
        persons.
      </span>
    </p>
    <h3 id="h.45z9fvughho1">
      <span>Exceptions to Informal Negotiations and Arbitration</span>
    </h3>
    <p>
      <span>
        The Parties agree that the following Disputes are not subject to the
        above provisions concerning informal negotiations binding arbitration:
        (a) any Disputes seeking to enforce or protect, or concerning the
        validity of, any of the intellectual property rights of a Party; (b) any
        Dispute related to, or arising from, allegations of theft, piracy,
        invasion of privacy, or unauthorized use; and (c) any claim for
        injunctive relief. If this provision is found to be illegal or
        unenforceable, then neither Party will elect to arbitrate any Dispute
        falling within that portion of this provision found to be illegal or
        unenforceable and such Dispute shall be decided by a court of competent
        jurisdiction within the courts listed for jurisdiction above, and the
        Parties agree to submit to the personal jurisdiction of that court.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.qwasos10rzu7">
      <span>16. CORRECTIONS</span>
    </h2>
    <p>
      <span>
        There may be information on the Services that contains typographical
        errors, inaccuracies, or omissions, including descriptions, pricing,
        availability, and various other information. We reserve the right to
        correct any errors, inaccuracies, or omissions and to change or update
        the information on the Services at any time, without prior notice.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.qw88u78mvbvj">
      <span>17. DISCLAIMER</span>
    </h2>
    <p>
      <span>
        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
        THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
        EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
        IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
        LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&#39;
        CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
        THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
        (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
        FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
      </span>
    </p>
    <p>
      <span>
        UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4)
        ANY INTERRUPTION ORCESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
        (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
        TRANSMITTED TOOR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY
        ERRORSOR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS ORDAMAGE
        OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
        TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OROFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
        HYPERLINKEDWEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
        BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
        BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
        THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A
        PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
        USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.ypjhev1cqtuq">
      <span>18. LIMITATIONS OF LIABILITY</span>
    </h2>
    <p>
      <span>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OROTHER DAMAGES ARISING FROM YOUR
        USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
        SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
        OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
        OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
        BY YOU TO US. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
        LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
        CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
        DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
        ADDITIONAL RIGHTS.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.xb39pelv9e6">
      <span>19. INDEMNIFICATION</span>
    </h2>
    <p>
      <span>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys&rsquo; fees and
        expenses, made by any third party due to or arising out of: (1) use of
        the Services; (2) breach of these Legal Terms; (3) any breach of your
        representations and warranties set forth in these Legal Terms; (4) your
        violation of the rights of a third party, including but not limited to
        intellectual property rights; or (5) any overt harmful act toward any
        other user of the Services with whom you connected via the Services.
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defence and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defence
      </span>
    </p>
    <p>
      <span>
        of such claims. We will use reasonable efforts to notify you of any such
        claim, action, or proceeding which is subject to this indemnification
        upon becoming aware of it.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.jwdjzm6kjkda">
      <span>20. USER DATA</span>
    </h2>
    <p>
      <span>
        We will maintain certain data that you transmit to the Services for the
        purpose of managing the performance of the Services, as well as data
        relating to your use of the Services. Although we perform regular
        routine backups of data, you are solely responsible for all data that
        you transmit or that relates to any activity you have undertaken using
        the Services. You agree that we shall have no liability to you for any
        loss or corruption of any such data, and you hereby waive any right of
        action against us arising from any such loss or corruption of such data.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.lf20leg9t09">
      <span>21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span>
    </h2>
    <p>
      <span>
        Visiting the Services, sending us emails, and completing online forms
        constitute electronic communications. You consent to receive electronic
        communications, and you agree that all agreements, notices, disclosures,
        and other communications we provide to you electronically, via email and
        on the Services, satisfy any legal requirement that such communication
        be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
        CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
        US OR VIA THE SERVICES. You hereby waive any rights or requirements
        under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.vsyytg999qqg">
      <span>22. CALIFORNIA USERS AND RESIDENTS</span>
    </h2>
    <p>
      <span>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.49mft2sh2rac">
      <span>23. MISCELLANEOUS</span>
    </h2>
    <p>
      <span>
        These Legal Terms and any policies or operating rules posted by us on
        the Services or in respect to the Services constitute the entire
        agreement and understanding between you and us. Our failure to exercise
        or enforce any right or provision of these Legal Terms shall not operate
        as a waiver of such right or provision. These LegalTerms operate to the
        fullest extent permissible by law. We may assign any or all of our
        rights and obligations to others at any time. We shall not be
        responsible or liable for any loss, damage, delay, or failure to act
        caused by any cause beyond our reasonable control. If any provision or
        part of a provision of these Legal Terms is determined to be unlawful,
        void, or unenforceable, that provision or part of the provision is
        deemed severable from these Legal Terms and does not affect the validity
        and enforceability of any remaining provisions. There is no joint
        venture, partnership, employment or agency relationship created between
        you and us as a result of these Legal Terms or use of the Services. You
        agree that these Legal Terms will not be construed against us by virtue
        of having drafted them. You hereby waive any and all defences you may
        have based on the electronic form of these Legal Terms and the lack of
        signing by the parties hereto to execute these Legal Terms.
      </span>
    </p>
    <p>
      <span></span>
    </p>
    <h2 id="h.i3v7jd89t6t1">
      <span>24. CONTACT US</span>
    </h2>
    <p>
      <span>
        In order to resolve a complaint regarding the Services or to receive
        further information regarding use of the Services, please contact us at:
      </span>
    </p>
    <p>
      <span>BrainRx Technologies Ltd.</span>
    </p>
    <p>
      <span>1367 West Broadway</span>
    </p>
    <p>
      <span>Unit 110</span>
    </p>
    <p>
      <span>Vancouver,</span>
    </p>
    <p>
      <span>British Columbia</span>
    </p>
    <p>
      <span>V6H 4A7</span>
    </p>
    <p>
      <span>Canada</span>
    </p>
    <p>
      <span>contact@concussionrx.com</span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
    <p>
      <span></span>
    </p>
  </div>
);
