import { ButtonProps } from 'antd';
import { GrayOutlinedButton } from 'components/grayoutlined-button';
import { ConcussionRxPrintContextConsumer } from 'utils/print';
import { Text } from './mvp-typography';

export function ExportAsPDFButton({
  text = 'Print Rx',
  ...props
}: Omit<ButtonProps, 'onClick' | 'disabled' | 'loading' | 'title'> & {
  text?: string;
}) {
  return (
    <ConcussionRxPrintContextConsumer>
      {args => (
        <div>
          <GrayOutlinedButton
            {...props}
            style={{ ...props.style }}
            onClick={() => {
              if (args.isPrinting) return;
              args.handlePrint();
            }}
            loading={args.isPrinting}
            disabled={args.isPrinting}
          >
            {text}
          </GrayOutlinedButton>
          {args.status && (
            <Text style={{ fontSize: '0.5rem' }}>{args.status}</Text>
          )}
        </div>
      )}
    </ConcussionRxPrintContextConsumer>
  );
}
