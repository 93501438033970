import { Skeleton } from 'antd';
import { useAppSelector } from 'app/rootReducer';
import {
  initialState,
  patientInfoInitialLoad,
  PatientInfoSliceActions,
  PatientInfoSliceReducer
} from 'components/patient-info/patientInfoSlice';
import { Role } from 'features/auth/types';
import { useReducer } from 'hooks/useReducer';
import { useEffect } from 'react';
import { PatientHomeClinician } from './patient-home-clinician';
import { PatientHomeAssessmentBlocked } from './patient-home-patient-assessment-blocked';
import { PatientHomeAssessmentComplete } from './patient-home-patient-assessment-complete';

export function PatientHomeV2() {
  const { user, isUserDataLoading } = useAppSelector(state => state.auth);
  const [patientInfo, patientInfoDispatch] = useReducer(
    PatientInfoSliceReducer,
    initialState
  );

  useEffect(() => {
    if (!user) return;
    if ([Role.Clinician, Role.ClinicAdmin].includes(user.role)) {
      patientInfoDispatch(PatientInfoSliceActions.stopLoading());
      return;
    }
    patientInfoDispatch(patientInfoInitialLoad(user.uid));
  }, [user]);

  if (!user || isUserDataLoading || patientInfo.isLoading)
    return <Skeleton active />;

  if ([Role.Clinician, Role.ClinicAdmin].includes(user.role))
    return <PatientHomeClinician />;

  if (!patientInfo.patient.canStartAssessments) {
    return <PatientHomeAssessmentBlocked patientInfo={patientInfo} />;
  }

  return <PatientHomeAssessmentComplete patientInfo={patientInfo} />;
}
