import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import { HomeOutlined } from '@ant-design/icons';

import { Menu } from 'antd';
import { desktopMinWidth } from 'constants/media-queries';
import { getPatientRouteUrl, PatientRoutes, Routes } from 'routes';

import { RootState, useAppSelector } from 'app/rootReducer';

import { collapseMenu } from './navigationSlice';

import { ReactComponent as RxSidemenuAssessmentLogo } from 'img/rx-sidemenu-assessment.svg';
import { ReactComponent as RxSidemenuKnowledgeCentre } from 'img/rx-sidemenu-knowledge-centre.svg';
// import { ReactComponent as RxSidemenuSummaryLogo } from 'img/rx-sidemenu-summary.svg';
// import { ReactComponent as RxSidemenuTreatmentLogo } from 'img/rx-sidemenu-treatment.svg';

import { getPatientLinkUrl } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { menuIconStyle } from './menu-icon-style';

export function PatientMenu() {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(desktopMinWidth);
  const user = useAppSelector(state => state.auth.user);

  const handleMenuClick = () => {
    if (!isDesktop) {
      dispatch(collapseMenu());
    }
  };
  const location = useSelector((state: RootState) => state.router.location);

  const menuHighlightedRoute =
    location.pathname.search(PatientRoutes.PatientDemographics) === -1
      ? location.pathname
      : getPatientRouteUrl(PatientRoutes.PatientDemographics);
  if (!user) return null;

  return (
    <Menu
      style={{ fontFamily: 'Comfortaa' }}
      theme="light"
      mode="inline"
      defaultSelectedKeys={[menuHighlightedRoute]}
      onClick={handleMenuClick}
      inlineIndent={14}
    >
      <Menu.Item key={PatientRoutes.PatientHome} icon={<HomeOutlined />}>
        <Link to={getPatientLinkUrl(PatientRoutes.PatientHome, user?.uid)}>
          Home
        </Link>
      </Menu.Item>

      <Menu.Item
        key={Routes.QuestionnaireFlow}
        icon={<RxSidemenuAssessmentLogo style={menuIconStyle} />}
        disabled
      >
        {/* <Link to={createPatientMenuUrl(Routes.QuestionnaireFlow)}> */}
        Assessment Test
        {/* </Link> */}
      </Menu.Item>
      <Menu.Item
        icon={<RxSidemenuKnowledgeCentre style={menuIconStyle} />}
        disabled
      >
        Knowledge Centre
      </Menu.Item>
    </Menu>
  );
}
