import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';

interface DarkButtonProps extends ButtonProps {
  label: string;
  minHeight?: number;
  minWidth?: number;
  fontSize?: number;
  borderRadius?: number;
  disabled?: boolean;
}

/**
 * @deprecated Use `DarkButton` instead
 * @param param0
 * @returns
 */
export const BigDarkButton = ({
  label,
  onClick,
  minHeight = 60,
  minWidth = 220,
  borderRadius = 110,
  fontSize = 22,
  htmlType,
  disabled,
  ...otherProps
}: DarkButtonProps) => {
  return (
    <Button
      {...otherProps}
      disabled={disabled}
      htmlType={htmlType as ButtonProps['htmlType']}
      className={clsx(
        'dark-button',
        disabled ? '__disabled' : '',
        'crx-typography button-big'
      )}
      onClick={onClick}
      style={{
        minHeight: minHeight,
        minWidth: minWidth,
        fontSize: fontSize / 16 + 'rem',
        borderRadius: borderRadius + 'px'
      }}
    >
      {label}
    </Button>
  );
};
