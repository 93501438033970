import { Reducer } from '@reduxjs/toolkit';
import { Flex, Typography } from 'antd';
import { BigDarkButton } from 'components/big-dark-button';
import { push } from 'connected-react-router';
import { Inventory } from 'documents/inventory';
import { useQuestionnaireProgression } from 'hooks/use-questionnaire-progression';
import { useReducer } from 'hooks/useReducer';
import lodash from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Routes } from 'routes';
import { z } from 'zod';
import { RadioButtonListItem } from '../outcome-measures/cgs/radio-button-list';
import { QuestionnaireFormInput } from '../outcome-measures/cgs/radio-button-list/radiobutton-list-v2';
import {
  FormattedSection,
  isDisabled,
  nextPageOrSection,
  paginationSliceInitialState,
  PaginationSliceReducer,
  PaginationState,
  setHighlightItem,
  setNextHighlightItem,
  setSections
} from '../outcome-measures/paginationSlice';

export const QUESTIONNAIRE_TITLE_24_HR = 'In the last day';

export interface QuestionnairePaginationCombinedGeneralProps<
  T extends Inventory
> {
  data: T;
  paginationInitialState: PaginationState;
  invalidItems: string[];
  formatData: (data: T, pageLimit: number) => FormattedSection[];
  validate: () => void | any;
  updateItem: (a: { title: string; value: number }) => void | any;
  submit: () => void | any;
  onSubmitExtraNavigationProps?: { questionnaireName: string };
  onPageChange?: (page: number) => void | any;
  onSectionChange?: (page: number) => void | any;
  onStateChange: (paginationState: PaginationState) => void | any;
  /**
   * The top-most text of the page
   */
  pageTitle: string | ((currentSection: number, currentPage: number) => string);
}

const restoredState = z.object({
  expiration: z.number(),
  data: z.record(z.string(), z.any())
});
export function tryRestoreStateFromStorage<T extends Record<string, any>>(
  key: string,
  initialState: T
): T {
  try {
    const serialisedState = localStorage.getItem(key);
    if (serialisedState === null) return initialState;
    const saveData = JSON.parse(serialisedState);

    restoredState.parse(saveData);

    if (
      moment()
        .startOf('day')
        .subtract(2, 'day')
        .isAfter(moment.unix(saveData.expiration))
    ) {
      throw new Error(`The state has expired. ${JSON.stringify(saveData)}`);
    }
    return saveData.data as T;
  } catch (error) {
    localStorage.removeItem(key);
    console.log({
      error: error,
      status: 'Restoring initial state'
    });
    return initialState;
  }
}

export function saveStateToStorage(key: string, data: any) {
  try {
    const saveData = {
      expiration: moment().unix(),
      data
    };

    localStorage.setItem(key, JSON.stringify(saveData));
  } catch (error) {
    console.log(`[saveStateToStorage] Error when persisting state ${error}`);
  }
}

export function PagedQuestionnaire<
  A extends { invalidItems: string[] },
  T extends Inventory
>(
  props: {
    storageKey: string;
    reducer: Reducer<A, any>;
    initialState: A;
    getData: (state: A) => T;
    actions: Pick<
      QuestionnairePaginationCombinedGeneralProps<T>,
      'formatData' | 'updateItem' | 'submit' | 'validate'
    >;
  } & Pick<
    QuestionnairePaginationCombinedGeneralProps<T>,
    | 'pageTitle'
    | 'onPageChange'
    | 'onSectionChange'
    | 'onSubmitExtraNavigationProps'
  >
) {
  const [state, dataDispatch] = useReducer(
    props.reducer,
    tryRestoreStateFromStorage(props.storageKey, props.initialState)
  );

  const paginationInitialState = useMemo(
    () =>
      tryRestoreStateFromStorage(
        `${props.storageKey}-pagination`,
        paginationSliceInitialState
      ),
    // Only run this once
    // eslint-disable-next-line
    []
  );

  const { getData } = props;
  const data = useMemo(() => getData(state), [getData, state]);

  return (
    <PagedQuestionnaireGeneral
      invalidItems={state.invalidItems}
      paginationInitialState={paginationInitialState}
      data={data}
      formatData={props.actions.formatData}
      submit={() => {
        dataDispatch(props.actions.submit());
        cleanPagedQuestionnaireStorage(props.storageKey);
      }}
      updateItem={item => dataDispatch(props.actions.updateItem(item))}
      validate={() => dataDispatch(props.actions.validate())}
      pageTitle={props.pageTitle}
      onPageChange={props.onPageChange}
      onSectionChange={props.onSectionChange}
      onStateChange={paginationState => {
        saveStateToStorage(props.storageKey, state);
        saveStateToStorage(`${props.storageKey}-pagination`, paginationState);
      }}
      onSubmitExtraNavigationProps={props.onSubmitExtraNavigationProps}
    />
  );
}

export function cleanPagedQuestionnaireStorage(storageKey: string) {
  localStorage.removeItem(storageKey);
  localStorage.removeItem(`${storageKey}-pagination`);
}

export function PagedQuestionnaireGeneral<T extends Inventory>(
  props: QuestionnairePaginationCombinedGeneralProps<T>
) {
  const [paginationState, paginationDispatch] = useReducer(
    PaginationSliceReducer,
    props.paginationInitialState
  );

  const {
    currentSection: currentSectionIndex,
    currentPage,
    formattedSections,
    itemHighlight,
    disabled,
    submitReady
  } = paginationState;
  const curProgression =
    currentSectionIndex +
    (currentPage * 1) /
      (formattedSections[currentSectionIndex]?.pageLength ?? 1);
  const maxProgression = formattedSections.length;
  useQuestionnaireProgression(curProgression, maxProgression);

  const reduxDispatch = useDispatch();

  useEffect(
    () => {
      props.onStateChange(paginationState);
    },
    // eslint-disable-next-line
    [currentPage, currentSectionIndex]
  );

  useEffect(
    () => {
      props.onPageChange?.(currentPage);
    },
    // eslint-disable-next-line
    [currentPage]
  );

  useEffect(
    () => {
      props.onSectionChange?.(currentSectionIndex);
    },
    // eslint-disable-next-line
    [currentSectionIndex]
  );

  useEffect(() => {
    /**
     * Unset the currently highlighted item
     */
    paginationDispatch(setHighlightItem(undefined));
  });

  /**
   * Parse the Inventory data to `FormattedSections`
   */
  useEffect(() => {
    const _data = lodash.cloneDeep(props.data);
    const ret = props.formatData(_data, 4);

    paginationDispatch(setSections(ret));
    paginationDispatch(isDisabled());
    props.validate();
    // eslint is disabled so that props doesn't need to be destructured
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  function handleOnValueChange(item: RadioButtonListItem) {
    props.updateItem(item);
    // dispatch(setHighlightItem());
    paginationDispatch(setNextHighlightItem(item));
    paginationDispatch(isDisabled());
    props.validate();
  }

  function handleNextOrSubmit() {
    if (submitReady) {
      props.submit();
      reduxDispatch(
        push(Routes.QuestionnaireFlow, {
          fromQuestionnaire: true,
          ...props.onSubmitExtraNavigationProps
        })
      );
      return;
    }

    paginationDispatch(nextPageOrSection());
    paginationDispatch(setHighlightItem(undefined));
    paginationDispatch(isDisabled());
  }

  const currentSection = formattedSections[currentSectionIndex] ?? {};
  const length = formattedSections?.length;
  const { min, max, type, colorsForward, iconsForward } = currentSection;

  const Unable = formattedSections[currentSectionIndex]?.beforeLabel
    ? formattedSections[currentSectionIndex].beforeLabel
    : 'Unable to do';
  const Able = formattedSections[currentSectionIndex]?.afterLabel
    ? formattedSections[currentSectionIndex].afterLabel
    : 'Without any difficulty';

  const actionButtonLabel = submitReady ? 'Submit' : 'Next';

  return (
    <div>
      <Flex justify="center" style={{ width: '100%' }}>
        <Typography.Title style={{ fontWeight: 'bolder' }}>
          {typeof props.pageTitle === 'function'
            ? props.pageTitle(currentSectionIndex, currentPage)
            : props.pageTitle}
        </Typography.Title>
      </Flex>
      {length > 0 && (
        <QuestionnaireFormInput
          items={formattedSections[currentSectionIndex].page[currentPage]}
          min={min}
          max={max}
          invalidItems={props.invalidItems}
          onChange={handleOnValueChange}
          beforeLabel={Unable}
          afterLabel={Able}
          itemHighlight={itemHighlight}
          type={type}
          colorsForward={colorsForward}
          iconsForward={iconsForward}
        />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'flexEnd',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <BigDarkButton
            minWidth={128}
            minHeight={60}
            disabled={disabled}
            onClick={handleNextOrSubmit}
            label={actionButtonLabel}
          />
        </div>
      </div>
    </div>
  );
}
