import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import lodash from 'lodash';
import moment from 'moment';

export function userNeedsTest(patientInfo: Pick<PatientInfoState, 'impact'>) {
  const sortedData = lodash
    .cloneDeep(patientInfo.impact)
    .sort((a, b) => moment(b.date).diff(moment(a.date)));

  return (
    sortedData.length === 0 ||
    moment(sortedData[0].date).isBefore(moment().subtract(7, 'days'))
  );
}
