import { Flex, Space } from 'antd';
import { ActionButtonLink } from 'components/card/card-action-button-link';
import { Title, Text } from 'components/mvp-typography';
import { usePatientInfoContext } from 'components/patient-info/hook-context-patientinfo';
import { useCreatePatientUrl } from 'hooks/use-create-patient-url';
import RXClipboardImage from 'img/rx-clipboard.png';
import { PatientRoutes } from 'routes';
import { getFirstName, pluralize } from 'utils/string/normalise';

export function RecommendTreatment() {
  const createPatientUrl = useCreatePatientUrl();
  const { patientInfo } = usePatientInfoContext();
  const firstName = getFirstName(patientInfo.patient.displayName ?? '');

  return (
    <Space direction="vertical">
      <Title level={2} color="initial">
        Recommended Treatment
      </Title>
      <Text color="initial">
        Use ConcussionRx interdisciplinary insights and patient data to
        recommend best-practice next steps in managing{' '}
        {`${pluralize(firstName)} `}
        concussion
      </Text>
      <Flex>
        <div>
          <img style={{ maxWidth: 132 }} src={RXClipboardImage} />
        </div>
      </Flex>

      <ActionButtonLink
        to={createPatientUrl(PatientRoutes.PatientTreatmentRecommendations)}
      >
        View details
      </ActionButtonLink>
    </Space>
  );
}
