import { useState } from 'react';
import { Layout, Row } from 'antd';
import { SaveAndExitHeader } from 'components/header';
import { useDispatch } from 'react-redux';

import { push } from 'connected-react-router';
import { Routes } from 'routes';

import './questionnaire-completion.scss';

import PeoplePaddle from 'img/people-paddleboard.svg';
import { GenericButton } from 'components/generic-button';
import { TakeABreakFilter } from './components/take-a-break-filter';
import { DarkButton } from 'components/dark-button';
import { Link } from 'react-router-dom';
import { ConnectedOutcomeMeasuresProgressBar } from 'features/patient/outcome-measures/patient-outcome-progress';

export interface CompletionProps {
  /* title?: string; */
  description?: string;
  img?: string;
  /**
   * Take break callback
   */
  takeBreakCb?: () => void;
  /**
   * Continue callback
   */
  continueCb?: () => void;
}

/**
 * This component renders after the Promis questionnaire is complete
 */
export function QuestionnaireCompletionPromis({
  /* title = 'Questionnaire completed', */
  description = `You're on your way! If you feel like you need a break, please do so. You have enough time to come back and finish later.`,
  img = PeoplePaddle
}: CompletionProps) {
  const dispatch = useDispatch();
  // const location = useLocation();
  // console.log(location.state);
  const [showBreak, setBreak] = useState(false);

  function handleOnContinue() {
    dispatch(push(Routes.QuestionnaireFlow));
  }
  function takeBreak() {
    setBreak(prev => !prev);
  }
  return (
    <>
      {showBreak && <TakeABreakFilter onContinue={handleOnContinue} />}

      <Layout className="layout-container">
        <SaveAndExitHeader
          title={'Function Questionnaire: Completed'}
          label={'Save & Exit'}
          onExit={() => dispatch(push('/'))}
        />

        <Row>
          <div
            style={{
              display: 'flex',
              flex: '1',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              marginLeft: '120px'
            }}
          >
            <ConnectedOutcomeMeasuresProgressBar label size={['100%', 18]} />
            <div style={{ maxWidth: '600px', marginBottom: '60px' }}>
              <p className="completion-title">Great job!</p>
              <p className="completion-description">{description}</p>
            </div>
            <div>
              <div style={{ paddingBottom: '24px' }}>
                <GenericButton
                  minHeight={60}
                  minWidth={150}
                  label={'I need a break'}
                  rounded={true}
                  baseFont={true}
                  iconBefore={false}
                  icon={null}
                  onClick={takeBreak}
                />
              </div>
              <DarkButton style={{ fontSize: 20 }}>
                <Link to={Routes.QuestionnaireFlow}>
                  Continue to next questionnaire
                </Link>
              </DarkButton>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flex: '1',
              flexDirection: 'column',
              maxHeight: '100dvh'
            }}
          >
            <img src={img} className="completion-img" />
          </div>
        </Row>
      </Layout>
    </>
  );
}
