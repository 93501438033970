import RxCounselCardImg from 'img/rxcounsel-card-img.svg';
import RxNeuroCardImg from 'img/rxneuro-card-img.svg';
import RxPhysioCardImg from 'img/rxphysio-card-img.svg';
import RxTherapyCardImg from 'img/rxtherapy-card-img.svg';
import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import { Content, severityMap } from './treatment-recommendations-v2';

export function formatTreatmentRecommendations(
  _recommendation: PatientInfoState['concussionTreatmentRecommendations'][0]
) {
  const recommendation = _recommendation.data;
  const physioContent: Content[] = [
    ...(
      [
        { title: 'Cervical/Manual', key: 'cervicalManual' },
        { title: 'Oculomotor', key: 'oculomotor' },
        { title: 'Vestibular', key: 'vestibular' }
      ] as const
    ).map(conf => ({
      name: `${conf.title} - ${
        severityMap[recommendation['physiotherapy'][conf.key]]
      } intensity`,
      link: ''
    })),
    {
      name: `Exertion/Autonomic Nervous System (ANS) - ${recommendation['physiotherapy']['autonomic']}`,
      link: ''
    }
  ];

  const neuroContent: Content[] = [
    {
      name: `Cognitive Assessment - ${recommendation['neuropsychology'].cognitive}`,
      link: ''
    }
  ];

  const counselContent: Content[] = [
    {
      name: `Mood/Coping - ${recommendation['counseling']['moodCoping']}`,
      link: ''
    }
  ];

  const occupationalTherapyContent = [
    {
      name: [
        `Cognitive, ADL (Activities of Daily Living), and school or work interventions`,
        `${recommendation['occupationalTherapy']['cognitiveADL']}`
      ].join(' - ')
    }
  ];

  return [
    {
      content: physioContent,
      title: 'Physiotherapy',
      img: RxPhysioCardImg
    },
    {
      content: neuroContent,
      title: 'Neuropsychology',
      img: RxNeuroCardImg
    },
    {
      content: counselContent,
      title: 'Counseling',
      img: RxCounselCardImg
    },
    {
      content: occupationalTherapyContent,
      title: 'Occupational Therapy',
      img: RxTherapyCardImg
    }
  ];
}
