import './SymptomBar.scss';

import React from 'react';
import { useDispatch } from 'react-redux';

// import { Bar } from '@ant-design/charts';
import { Bar } from '@ant-design/plots';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import { SymptomStatus } from 'documents';

import { BarData } from '../symptoms/Symptoms';

const colors = {
  [SymptomStatus.Normal]: '#60bb46',
  [SymptomStatus.Alert]: '#ffdc5f',
  [SymptomStatus.Warning]: '#ffa500',
  [SymptomStatus.Critical]: '#fb4337'
};

export interface SymptomBarProps {
  data: BarData;
  status: SymptomStatus;
}

export function SymptomBar(props: SymptomBarProps) {
  const dispatch = useDispatch();
  const { data, status } = props;

  const config = {
    forceFit: true,
    padding: -1,
    data: [data],
    xField: 'value',
    yField: 'symptom',
    color: colors[status],
    barSize: 12,
    label: {
      visible: false
    },
    xAxis: {
      title: {
        visible: false
      },
      label: {
        visible: false
      },
      max: 10
    },
    yAxis: {
      label: {
        visible: false
      }
    },
    tooltip: {
      visible: true
    },
    events: {
      onBarClick: (e: { data: BarData }) => {
        if (e.data.link) {
          dispatch(push(e.data.link));
        }
      }
    }
  };

  return (
    <Bar
      {...config}
      className={clsx('symptom-bar', {
        'symptom-bar_noclick': !data.link
      })}
    />
  );
}
