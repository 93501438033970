import { Rating } from './types';

export const labels = {
  [Rating.None]: 'None',
  [Rating.Normal]: 'Normal',
  [Rating.Mild]: 'Mild',
  [Rating.Moderate]: 'Moderate',
  [Rating.Severe]: 'Severe'
};
export const handleWidth = 88;
export const padding = 16; //15px padding + 1px border
export const lineCount = 5;
export const lineMargin = 2;
export const transition = '0.5s';
