import { useEffect, useState } from 'react';
import { Button, Input, Space, Typography } from 'antd';
import { setExtraRecommendations } from 'components/patient-info/patientInfoSlice';
import { PatientInfoState } from 'components/patient-info/patient-info-slice-types';
import { TreatmentCard } from './treatment-card';

export function ClinicianRecommendationsCard(props: {
  patientInfo: PatientInfoState;
  patientInfoDispatch: React.Dispatch<any>;
}) {
  const [text, setText] = useState(
    props.patientInfo.concussion.extraRecommendations
  );

  useEffect(() => {
    setText(props.patientInfo.concussion.extraRecommendations);
  }, [props.patientInfo.concussion.extraRecommendations]);

  return (
    <TreatmentCard title={'Clinician Recommendations'}>
      <Space direction="vertical">
        <Typography style={{ fontWeight: 'bold' }}>
          It will be automatically added to the other prescriptions when you
          print them.
        </Typography>
        <Input.TextArea
          placeholder={`Enter any other recommendations you have for ${props.patientInfo.patient.displayName} here!`}
          autoSize={{ minRows: 2 }}
          value={text}
          onChange={e => setText(e.target.value)}
        />
        <Button
          onClick={() => {
            if (!text) return;
            props.patientInfoDispatch(setExtraRecommendations(text));
          }}
        >
          Save
        </Button>
      </Space>
    </TreatmentCard>
  );
}
