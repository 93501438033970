import { Gauge2Props } from './gauge-2';
/**
 * Calculate the category index where `fixedValue` fits relative to `scale`
 * @param scale
 * @param fixedValue
 * @returns
 */
export function calculateSegmentIndex(
  scale: Gauge2Props['scale'],
  fixedValue: number
) {
  const scaleIsAscending = Math.sign(scale[1] - scale[0]) === 1;
  const rangeIndex = scale.findIndex((_, index, arr) => {
    // The value is larger than the max
    if (index + 1 === arr.length) {
      return true;
    }
    if (scaleIsAscending) {
      return _ <= fixedValue && fixedValue <= arr[index + 1];
    }

    return _ >= fixedValue && fixedValue >= arr[index + 1];
  });

  /**
   * If the index is the last element then use the last segment
   */
  if (rangeIndex + 1 === scale.length) return rangeIndex - 1;
  return rangeIndex;
}
