import './PatientHomeDiscovery.scss';

import { Col, Flex, Row, Skeleton, Typography } from 'antd';

import { ElevatedCard } from 'components/card/elevated-card';

import { usePatientInfoContext } from 'components/patient-info/hook-context-patientinfo';
import { halfCol } from 'utils/antd';
import { ConcussionKnowledge } from './components/cards/card-concussion-knowledge';
import { ConcussionSummary } from './components/cards/card-concussion-summary';
import { RecommendTreatment } from './components/cards/card-recommend-treatment';

export function PatientHomeDiscovery() {
  const {
    patientInfo: { patient }
  } = usePatientInfoContext();
  /**
   * Safety check
   * TODO: This case should be caught during routing
   */
  if (!patient.id) {
    return <Skeleton active />;
  }

  const notifications = [
    {
      component: <RecommendTreatment key={'recommended-treatment'} />
    },
    // { component: <NextTest key={'next-test'} /> },
    {
      component: <ConcussionKnowledge key={'concussion-knowledge'} />,
      disabled: true
    }
  ];

  return (
    <div>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Typography.Title
            style={{ color: '#230056' }}
          >{`Let's get ${patient.displayName} better!`}</Typography.Title>
        </Col>
      </Row>
      <Flex vertical gap={16}>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <ElevatedCard>
              <ConcussionSummary />
            </ElevatedCard>
          </Col>
        </Row>
        <Row gutter={[16, 24]}>
          {
            /**
             * Dynamically populate the notification list
             */
            notifications.map((notification, index) => (
              <Col
                {...halfCol}
                key={`patientdashboard-leftcolumn-notification-card-${index}`}
              >
                <ElevatedCard
                  className="full-height"
                  disabled={notification.disabled}
                  style={{ height: '100%' }}
                >
                  {notification.component}
                </ElevatedCard>
              </Col>
            ))
          }
        </Row>
      </Flex>
    </div>
  );
}
