import { Skeleton } from 'antd';
import { Text, Title } from 'components/mvp-typography';
import {
  initialState,
  patientInfoInitialLoad,
  PatientInfoSliceReducer
} from 'components/patient-info/patientInfoSlice';
import { PrivateRoute } from 'components/routes/private-route';
import { Collection } from 'documents/document';
import { User } from 'features/auth';
import { Role } from 'features/auth/types';
import {
  initialState as questionnaireInitialState,
  QuestionnaireControlSliceReducer,
  questionnaireInfoInitialLoad
} from 'features/patient/assessment/questionnaire/questionnaire-control-flow-slice';
import { useReducer } from 'hooks/useReducer';
import { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { PatientRoutes } from 'routes';
import { doesUserHaveAssessments } from '../../../utils/user-has-assessments';
import { filterPatientRoutes } from './patient-routes-filter';
import {
  getPatientLinkUrl,
  PatientRoutePattern
} from './patient-route-helpers';
import { userNeedsTest } from './user-needs-test';

/**
 * Patient routes where the `patientId` parameter is set.
 *
 * `patientId` can be any string.
 *
 * @param props
 * @returns
 */
export function PatientRouteSwitchPatientId(props: { user: User }) {
  const { patientId } = useParams<{ patientId: string }>();
  const [patientInfo, patientInfoDispatch] = useReducer(
    PatientInfoSliceReducer,
    initialState
  );
  const [questionnaireInfo, questionnaireInfoDispatch] = useReducer(
    QuestionnaireControlSliceReducer,
    questionnaireInitialState
  );

  /**
   * Load data
   */
  useEffect(
    () => {
      // Try to load the patient data
      patientInfoDispatch(patientInfoInitialLoad(patientId));

      // Load questionnaire data if the current user is a patient
      if (props.user.role === Role.Patient) {
        questionnaireInfoDispatch(
          questionnaireInfoInitialLoad({
            patientId,
            rootPath: Collection.PendingPatientAssessments
          })
        );
      }
    },
    // eslint-disable-next-line
    [patientId, props.user.role]
  );

  const patientIsDoingAnAssessment = questionnaireInfo.assessmentData !== null;

  const routes = useMemo(() => {
    if (patientInfo.isLoading) return [];
    return filterPatientRoutes(props.user.role, {
      useBitmask: false,
      userIsBeingOnboarded: patientInfo.isBeingOnboarded,
      userIsDoingAnAssessment:
        !!patientInfo.patient.canStartAssessments &&
        (userNeedsTest(patientInfo) || patientIsDoingAnAssessment)
    });
  }, [props.user.role, patientInfo, patientIsDoingAnAssessment]);

  /**
   * Show nothing if the patient info is loading
   */
  if (patientInfo.isLoading) {
    return (
      <div>
        <p>Loading patient information</p>
        <Skeleton active />
      </div>
    );
  }
  if (patientInfo.hasError) {
    return (
      <div>
        <Title>Safely caught error</Title>
        <Text>
          An error occured when fetching data for patient {`'${patientId}'`}.
          Please confirm the ID is correct and should exist.
        </Text>
        <Text>
          If you think this is an error please contact us and include this URL:
          {` ${window.location.href}`}.
        </Text>
      </div>
    );
  }

  // If the user is a patient
  if (props.user.role === Role.Patient) {
    //  Show nothing if the questionnaire info is loading
    if (questionnaireInfo.isLoading)
      return <p>Loading questionnaire information</p>;

    // Redirect the user to the correct page if the URL patient ID doesn't match their ID
    if (patientId !== props.user.uid) {
      return (
        <Redirect
          to={getPatientLinkUrl(PatientRoutes.PatientHome, props.user.uid)}
        />
      );
    }
  } else if ([Role.Clinician, Role.ClinicAdmin].includes(props.user.role)) {
    if (!doesUserHaveAssessments(patientInfo)) {
      return (
        <div>
          <Title>Safely caught error</Title>
          <Text>
            Patient {`'${patientId}'`} has no patient data. This can happen if
            the user has not completed their first assessment.
          </Text>
          <Text>
            If you think this is an error please contact us and include this
            URL:
            {` ${window.location.href}`}.
          </Text>
        </div>
      );
    }
  }

  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          exact
          {...route}
          path={PatientRoutePattern + route.path}
          key={index + JSON.stringify(route.path)}
          redirectPath={'../'}
        />
      ))}

      <Route
        // Handler when the browser redirects the second base patient route
        path={['/patient/:patientId']}
        render={() => {
          if ([Role.ClinicAdmin, Role.Clinician].includes(props.user.role)) {
            return (
              <Redirect
                to={getPatientLinkUrl(PatientRoutes.PatientHome, patientId)}
              />
            );
          }

          if (patientInfo.isBeingOnboarded) {
            return (
              <Redirect
                to={getPatientLinkUrl(
                  PatientRoutes.PatientOnboardingProfile,
                  patientId
                )}
              />
            );
          }

          // The user is a patient
          return (
            <Redirect
              to={
                !!patientInfo.patient.canStartAssessments &&
                (userNeedsTest(patientInfo) || patientIsDoingAnAssessment)
                  ? getPatientLinkUrl(
                      questionnaireInfo.fulfilled === 0
                        ? PatientRoutes.PatientOutcomeMeasures
                        : PatientRoutes.PatientOutcomeProgress,
                      props.user.uid
                    )
                  : getPatientLinkUrl(PatientRoutes.PatientHome, props.user.uid)
              }
            />
          );
        }}
      />
    </Switch>
  );
}
