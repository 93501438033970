import { darkButtonStyles } from 'components/dark-button';
import './Signin.scss';

export const styles = {
  container: {
    position: 'relative',
    width: '350px',

    // Fonts are not applied to text as expected due to AntD CSS selectors with higher priority
    FontFace: 'Comfortaa',
    fontFamily: 'Comfortaa'
  },
  form: {
    maxWidth: '300px',
    margin: 'auto'
  },
  titleContainer: {
    paddingBottom: '3rem'
  },
  title: {
    lineHeight: '20px',
    letterSpacing: '0.01em',
    fontWeight: '700',
    textAlign: 'center'
  },
  titleCenter: {
    textAlign: 'center'
  },
  checkbox: {
    // hack to align checkbox at top
    alignSelf: 'self-start',
    marginTop: '4px',
    backgroundColor: 'blue'
  },

  verifyContainer: {
    textAlign: 'center'
  },
  button: darkButtonStyles
} as const;
