import React from 'react';
import { Button } from 'antd';
import { Icon } from 'components';

/*
  Generic button you can build similiar rounded buttons off of
  iconBefore: determines where the icon comes before or after the button text
  label: label of the button
  rounded: rounded button
  onClick: callback of clicking on button
*/
interface GenericButtonProps {
  iconBefore?: boolean;
  icon?: React.ReactNode;
  label?: string;
  rounded?: boolean;
  onClick?: () => void;
  baseFont?: boolean;
  minWidth?: number;
  minHeight?: number;
  bigPadding?: boolean;
}

export const GenericButton = ({
  iconBefore = true,
  icon,
  label = 'Sign Out',
  rounded = false,
  onClick = () => {
    console.log('GenericButton onClick');
  },
  baseFont = false,
  minWidth,
  minHeight,
  bigPadding = false
}: GenericButtonProps) => {
  // icon="logout",
  let dynamicClass = 'sign-out-button';
  if (rounded) {
    dynamicClass += ' sign-out-button__rounded';
  }
  if (baseFont) {
    dynamicClass += ' sign-out-button__basefont';
  }
  if (bigPadding) {
    dynamicClass += ' sign-out-button__bigpadding';
  }

  return (
    <Button
      type="text"
      onClick={onClick}
      className={dynamicClass}
      style={{
        borderRadius: '100px',
        minWidth: minWidth + 'px',
        minHeight: minHeight + 'px'
      }}
    >
      {iconBefore && <Icon size="sm">{icon}</Icon>}
      <span>{label}</span>
      {!iconBefore && <Icon size="sm">{icon}</Icon>}
    </Button>
  );
};
