import { Tag } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { ActionTagRenderProps } from './action-tag-component';
import {
  FormQuestion,
  UserEditableDetailsModal
} from './user-add-modal-component';
import { APIData } from './user-list-component';

export function ActionTagEditDetails<T extends APIData>(
  props: ActionTagRenderProps<T> & { userAddQuestions: FormQuestion[] }
) {
  const [state, setState] = useState({
    modal: {
      isOpen: false
    }
  });

  return (
    <>
      {
        /**
         * Only render the modal when it is open
         */
        state.modal.isOpen && (
          <UserEditableDetailsModal
            onSubmit={data =>
              props.onClick({
                ...props.record,
                ...data
              })
            }
            submitText="Save details"
            actionProcessingDescription="Updating details. Please do not close or reload this window or tab."
            isOpen={state.modal.isOpen}
            setIsOpen={isOpen =>
              setState(s => ({ ...s, modal: { ...s.modal, isOpen } }))
            }
            initialValues={{
              firstName: props.record.firstName,
              lastName: props.record.lastName,
              email: props.record.email
            }}
            questions={props.userAddQuestions}
          />
        )
      }
      <Tag
        {...props.tagStyles}
        className={clsx('clickable', props.disabled && 'disabled')}
        style={{ padding: '0.1rem' }}
        onClick={() => {
          setState(s => ({ ...s, modal: { ...s.modal, isOpen: true } }));
        }}
      >
        {props.name}
      </Tag>
    </>
  );
}
