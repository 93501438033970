import { Layout, Row } from 'antd';
import { SaveAndExitHeader } from 'components/header';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { push } from 'connected-react-router';
import { Routes } from 'routes';

import './questionnaire-completion.scss';

import { DarkButton } from 'components/dark-button';
import { GenericButton } from 'components/generic-button';
import { ConnectedOutcomeMeasuresProgressBar } from 'features/patient/outcome-measures/patient-outcome-progress';
import Flower from 'img/flower.svg';
import { Link } from 'react-router-dom';
import { TakeABreakFilter } from './components/take-a-break-filter';
import { CompletionProps } from './questionnaire-completion-promis';

/**
 * This component renders after a questionnaire is done
 */
export function QuestionnaireCompletionEnd({
  /* title = 'Questionnaire completed', */
  description = `Great work completing all the questionnaires. Next up is the neurocognitive screening, which will take at least 20 minutes. Take a break if you need it. If you're ready click the button to continue. `,
  img = Flower
}: CompletionProps) {
  const dispatch = useDispatch();
  // const location = useLocation();
  // console.log(location.state);
  const [showBreak, setBreak] = useState(false);

  function handleOnContinue() {
    dispatch(push(Routes.QuestionnaireFlow));
  }
  function takeBreak() {
    setBreak(prev => !prev);
  }
  return (
    <>
      {showBreak && <TakeABreakFilter onContinue={handleOnContinue} />}

      <Layout className="layout-container">
        <SaveAndExitHeader
          title={'All Questionnaires Completed'}
          label={'Save & Exit'}
          onExit={() => dispatch(push('/'))}
        />

        <Row>
          <div
            style={{
              display: 'flex',
              flex: '1',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              marginLeft: '120px'
            }}
          >
            <ConnectedOutcomeMeasuresProgressBar label size={['100%', 18]} />
            <div style={{ maxWidth: '600px', marginBottom: '60px' }}>
              <p className="completion-title">Almost there!</p>
              <p className="completion-description">{description}</p>
            </div>
            <div>
              <div style={{ paddingBottom: '24px' }}>
                <GenericButton
                  minHeight={60}
                  minWidth={150}
                  label={'I need a break'}
                  rounded={true}
                  baseFont={true}
                  iconBefore={false}
                  icon={null}
                  onClick={takeBreak}
                />
              </div>
              <DarkButton style={{ fontSize: 20 }}>
                <Link to={Routes.QuestionnaireFlow}>Continue</Link>
              </DarkButton>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flex: '1',
              flexDirection: 'column',
              maxHeight: '100dvh'
            }}
          >
            <img src={img} className="completion-img" />
          </div>
        </Row>
      </Layout>
    </>
  );
}
