import { Button, Flex, Space, Typography } from 'antd';
import { auth } from 'api/firebase';
import { useAppSelector } from 'app/rootReducer';
import { BaseHeader } from 'components/header';
import { push } from 'connected-react-router';
import { sendVerification } from 'features/auth/auth-action-send-email-verification';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConcussionRXLogo } from './concussionRx-logo';
import { styles } from './styles';

export function VerifyEmail() {
  const user = auth.currentUser;
  const email = user ? user.email : 'Unknown';
  const { error: authError } = useAppSelector(state => state.auth);
  const dispatch = useDispatch();
  const secondsLimit = 5;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user === null) {
      dispatch(push('/'));
    }
  }, [user, dispatch]);

  function resendVerification() {
    if (loading === true) return;
    setLoading(true);

    dispatch(sendVerification(user));

    setTimeout(() => {
      setLoading(false);
    }, secondsLimit * 5000);
  }

  return (
    <div>
      <BaseHeader
        headerStyle={{
          background: 'none'
        }}
      />

      <Flex justify={'center'} align={'center'} flex={1}>
        <div style={{ ...styles.container, ...styles.verifyContainer }}>
          <Flex style={styles.titleContainer} justify="center">
            <ConcussionRXLogo />
          </Flex>
          <Typography.Title style={{ marginBottom: '2rem' }}>
            Please verify your email
          </Typography.Title>
          <Space direction="vertical" size={'large'} style={{ width: '80%' }}>
            <Typography.Text>
              {"We've sent a confirmation link to "}
              <b>{email}</b>
            </Typography.Text>
            <Typography.Text>
              Click on the link to complete the verification process.
            </Typography.Text>
            <Typography.Text>
              You might need to check your spam folder.
            </Typography.Text>
          </Space>
          <Flex
            justify="center"
            style={{ marginTop: '2rem' }}
            vertical
            gap={'middle'}
          >
            <Button
              disabled={loading}
              loading={loading}
              onClick={resendVerification}
              type="primary"
              style={styles.button}
            >
              Resend
            </Button>
            {authError && (
              <ErrorDisplay
                error={new Error(authError)}
                title="Something went wrong when sending your email verification"
                description="Please send the error details below to contact@concussionrx.com"
              />
            )}
            <div style={{ paddingTop: '30px', fontWeight: 'bold' }}>
              <p>
                Need help?{' '}
                <a
                  href="mailto:contact@concussionrx.com"
                  style={{ color: '#230056', textDecoration: 'underline' }}
                >
                  Contact Us
                </a>
              </p>
            </div>
          </Flex>
        </div>
      </Flex>
    </div>
  );
}
