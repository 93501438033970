import { Space, Typography } from 'antd';
import { complexityToAreaMap } from 'documents/critical-areas';
import { useContext, useMemo } from 'react';
import { addHexOpacity } from 'utils/math/colors';
import { COLOR_EXTREME, COLOR_MODERATE } from '../colors';
import { PatientInfoContext } from 'components/patient-info/hook-context-patientinfo';
import { Subtitle, Text, Title } from 'components/mvp-typography';

export function CriticalAreasCard(props: { noTitle?: boolean }) {
  const { patientInfo } = useContext(PatientInfoContext);
  const criticalAreas = useMemo(
    () => complexityToAreaMap[patientInfo.concussion.subtype],
    [patientInfo]
  );

  return (
    <>
      <div>
        {!props.noTitle && <Title level={2}>Critical Areas</Title>}
        <Typography.Paragraph>
          This subtype is associated with the following critical areas:
        </Typography.Paragraph>
      </div>
      <div className="spaced">
        <CriticalArea
          label={'High Critical Areas'}
          areas={criticalAreas.high}
          color={COLOR_EXTREME}
        />

        {criticalAreas.medium.length > 0 && (
          <CriticalArea
            label="Medium Critical Areas"
            areas={criticalAreas.medium}
            color={COLOR_MODERATE}
          />
        )}
      </div>
    </>
  );
}

/**
 * A list of critical areas
 */
function CriticalArea(props: {
  label: string;
  areas: string[];
  color: string;
}) {
  return (
    <div>
      <Subtitle style={{ color: '#230056' }}>{props.label}</Subtitle>
      <Space
        wrap
        style={{
          border: `7px solid #${addHexOpacity(
            props.color.replace('#', ''),
            0.9
          )}`,
          padding: '24px 40px 24px 40px',
          borderRadius: 12,
          width: '100%'
        }}
        size={60 / 2.5}
      >
        {props.areas.map((area, i) => (
          <Text
            className="crx-typography body-normal"
            key={`${area}-${i}`}
            color={'initial'}
            style={{ fontWeight: 'bold' }}
          >
            {area}
          </Text>
        ))}
      </Space>
    </div>
  );
}
