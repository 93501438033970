import { Form } from 'components';
import { OutcomeMeasuresHeader } from 'components/header';
import { useEffect } from 'react';

import { RadioButtonListItem } from 'features/patient/outcome-measures/cgs/radio-button-list';

import { useReducer } from 'hooks/useReducer';
import { useDispatch } from 'react-redux';

import { Layout } from 'antd';

import { FormattedSection } from '../paginationSlice';

import dhiInventories, {
  DhiInventory,
  dhiOrder,
  initialLoad,
  initialState,
  notSelected,
  submit
} from './dhiSlice';

import { setFaqObject } from 'features/navigation/navigationSlice';

import { useAppSelector } from 'app/rootReducer';
import { splitChunks } from 'utils/splitChunks';
import { PaginationCombined } from './dhi-pagination-combined';
import { cleanPagedQuestionnaireStorage } from 'features/patient/assessment/paged-questionnaire';

// json will be parsed from slicer file / reducer
// import dhiJSON from 'data/dizziness-handicap-scale.json'
function getItems(dhi?: DhiInventory): RadioButtonListItem[] {
  if (!dhi) return [];
  return dhiOrder.map(x => ({ title: x, value: dhi.data[x] }));
}

export function PatientDhi() {
  const user = useAppSelector(state => state.auth.user);
  const faqDispatch = useDispatch();

  const [{ dhi, lastUpdated }, dispatch] = useReducer(
    dhiInventories,
    initialState
  );

  useEffect(() => {
    dispatch(initialLoad(user!.uid));
    faqDispatch(
      setFaqObject({
        instructions: dhi.instructions,
        description: dhi.description,
        min: dhi.min,
        max: dhi.max
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChanged = Object.values(dhi.data).some(value => value > notSelected);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <OutcomeMeasuresHeader
        label={'Exit'}
        title="4th Questionnaire"
        onExit={() => {
          cleanPagedQuestionnaireStorage('questionnaire-dhi');
        }}
      />
      <Form
        isChanged={isChanged}
        lastUpdated={lastUpdated}
        onSubmit={() => dispatch(submit())}
        showFooter={false}
      >
        {Object.keys(dhi).length && (
          <PaginationCombined storageKey={'questionnaire-dhi'} />
        )}
      </Form>
    </Layout>
  );
}

// purpose is to standardize paginationCombined data intake;
export function formatDhi(data: DhiInventory, pageLimit: number) {
  const ret: FormattedSection[] = [];
  const questions: RadioButtonListItem[] = [];
  Object.keys(data.data).forEach(key =>
    questions.push({ title: key, value: data.data[key] })
  );

  const push: FormattedSection = {
    description: data.description,
    instructions: data.instructions,
    min: data.min,
    max: data.max,
    beforeLabel: data.beforeLabel,
    afterLabel: data.afterLabel,
    colorsForward: data.colorsForward,
    iconsForward: data.iconsForward,
    type: 'button',
    questions: getItems(data),
    page: splitChunks(questions, pageLimit),
    pageLength: 0
  };
  push.pageLength = push.page.length;
  ret.push(push);
  return ret;
}
