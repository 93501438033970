import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createRepo, initAssessment } from 'api/firebase';
import moment from 'moment';

import { Collection, Inventory, PatientSubcollection } from 'documents';
import { handleError } from 'utils/redux';
import {
  PendingAssessmentData,
  QuestionnaireControlFlowState
} from './questionnaire-control-flow-slice-types';

//reducer to fulfill
/*
1. check last 30 days entries of valid entries of
- promis
- dhi
- cgs
- pcs
*/
export const QuestionnaireTestNames = [
  'promis',
  'cgs',
  'pcs',
  'dhi',
  'impact'
] as const;

/**
 * Fetch questionnaire data from the database.
 * This can be used to fetch data outside of asynchronous state management
 */
export async function _loadData(
  patientId: string,
  rootPath: Collection.Patients | Collection.PendingPatientAssessments
) {
  const collectionPaths = [
    `${rootPath}/${patientId}/${PatientSubcollection.InventoriesPromis}`,
    `${rootPath}/${patientId}/${PatientSubcollection.InventoriesCgs}`,
    `${rootPath}/${patientId}/${PatientSubcollection.InventoriesPcs}`,
    `${rootPath}/${patientId}/${PatientSubcollection.InventoriesDhi}`,
    `${rootPath}/${patientId}/${PatientSubcollection.InventoriesImpact}`
  ];

  const pendingAssessmentDataRepo = createRepo<PendingAssessmentData>([
    Collection.PendingPatientAssessments
  ]);
  const pendingAssessmentData =
    (await pendingAssessmentDataRepo.find(patientId)) ?? null;

  // console.log("collectionPaths: ", collectionPaths);
  const repos = collectionPaths.map(path => createRepo<Inventory>(path));
  const promises = repos.map(repo => repo.first({ orderBy: ['date', 'desc'] }));

  return Promise.all(promises).then(results => {
    const returnResults: Record<
      (typeof QuestionnaireTestNames)[number],
      Inventory
    > = {} as any;

    // Transform the results list into an object
    results.forEach((res, index) => {
      const missingData =
        !res ||
        // undefined result means there are no records according ot our createRepo function!
        res === undefined ||
        moment().diff(res?.date, 'days') > 30 ||
        everyDataHasValue(res) === false;

      if (missingData) {
        returnResults[QuestionnaireTestNames[index]] = {
          data: {},
          date: null as any
        };
        return;
      }

      returnResults[QuestionnaireTestNames[index]] = res;
    });

    return { returnResults, pendingAssessmentData };
  });
}

export const questionnaireInfoInitialLoad = createAsyncThunk<
  Awaited<ReturnType<typeof _loadData> | null>,
  {
    patientId: string;
    rootPath: Parameters<typeof _loadData>[1];
  }
>('questionnaireControl/initialLoad', async data => {
  if (data.patientId === null) return null;
  return await _loadData(data.patientId, data.rootPath);
});

export const initAssessmentData = createAsyncThunk(
  'questionnaireControl/init',
  (patientId: string, { dispatch }) => {
    dispatch(questionnaireControlSlice.actions.startLoading());
    return _initAssessmentData(patientId);
  }
);

export async function _initAssessmentData(patientId: string) {
  const result = await initAssessment(patientId);
  return result.data as Promise<PendingAssessmentData>;
}

/**
 * Save an assessment to the pending assessment collection
 * If this is the first new assessment then a new expiration date will be set
 *
 * This function does not modify the `id` property of the `data` object
 * @param patientId
 * @param subcollection
 * @param data
 */
export async function savePendingAssessment(
  patientId: string,
  subcollection: PatientSubcollection,
  data: Inventory
) {
  // await _initAssessmentData(patientId);

  const inventoryRepo = createRepo<Inventory>(
    [Collection.PendingPatientAssessments, patientId, subcollection].join('/')
  );
  await inventoryRepo.set(data);
}

export const initialState: QuestionnaireControlFlowState = {
  required: 0,
  fulfilled: 0,
  data: {},
  order: QuestionnaireTestNames as any,
  done: {},
  allDone: true,
  isLoading: true,
  hasError: false,
  error: null,
  assessmentData: null
};

export const questionnaireControlSlice = createSlice({
  name: 'questionnaireControl',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  },
  extraReducers: builder => {
    // builder.addCase(initialLoad.pending, handlePending);
    builder.addCase(questionnaireInfoInitialLoad.rejected, (...args) =>
      handleError(...args, '[QuestionnaireControlFlowSlice][initialLoad]')
    );
    builder.addCase(
      questionnaireInfoInitialLoad.fulfilled,
      (state, { payload }) => {
        if (payload === null) return;

        state.data = payload.returnResults;
        const { newDone, required, fulfilled } = createDoneMap(
          payload.returnResults
        );
        state.assessmentData = payload.pendingAssessmentData;

        state.done = newDone;
        state.required = required;
        state.fulfilled = fulfilled;
        state.allDone = required > 0 && fulfilled == required;
        state.isLoading = false;
      }
    );

    builder.addCase(initAssessmentData.rejected, (...args) =>
      handleError(...args, '[QuestionnaireControlSlice][initAssessmentData]')
    );
    builder.addCase(initAssessmentData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.assessmentData = payload;
    });
  }
});

/**
 * Second export to start migrating code
 */
export const QuestionnaireControlSliceReducer =
  questionnaireControlSlice.reducer;
export function createDoneMap(payload: Record<string, Inventory>) {
  const newDone: Record<string, boolean> = {};
  const keys = Object.keys(payload);
  let fulfilled = 0;
  const required = keys.length;

  keys.forEach(testName => {
    const done = payload[testName]?.date !== null;
    if (done) {
      fulfilled++;
    }

    newDone[testName] = done;
  });
  return { newDone, required, fulfilled };
}

const NO_VALUE = -1;

function everyDataHasValue(res: Inventory) {
  for (const [, value] of Object.entries(res.data ?? {})) {
    if (value === NO_VALUE) return false;
  }
  return true;
}
