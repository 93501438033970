import './Footer.scss';

import React from 'react';

import { Col, Row } from 'antd';
import { halfCol } from 'utils/antd';
import { Link } from 'react-router-dom';

const clinicName = 'BrainRx Technologies Ltd.';
const year = new Date().getFullYear();
// const href = '/';
// const target = '_blank';

import { Routes } from 'routes';

export const Footer: React.FC = () => (
  <div className="footer">
    <Row className="footer__content">
      <Col className="footer__clinic column" {...halfCol}>
        {clinicName}, &#169; {year}
      </Col>
      <Col {...halfCol} className="column footer__terms">
        <Link
          className="footer__control"
          target="_blank"
          rel="noreferrer"
          to={Routes.TermsOfService}
        >
          Terms of Use{' '}
        </Link>{' '}
        &nbsp;&amp;&nbsp;{' '}
        <Link
          className="footer__control"
          target="_blank"
          rel="noreferrer"
          to={Routes.PrivacyPolicy}
        >
          {' '}
          Privacy Policy
        </Link>
      </Col>
    </Row>
  </div>
);
