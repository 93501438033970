import { Document } from './document';

export enum PcsSection {
  Pcs = 'Pcs'
}

export enum PcsCategory {
  TOTAL = 'Total score',
  RUMINATION = 'Rumination',
  MAGNIFICATION = 'Magnification',
  HELPLESSNESS = 'Helplessness'
}

export interface PcsQuestion {
  id: number;
  title: string;
  value: number | undefined;
  section: PcsSection;
  categories: PcsCategory[];
}

export interface PCS extends Document {
  questions: PcsQuestion[];
}

// total score to percentile values
const tperce = [
  2, 5, 6, 9, 11, 15, 16, 19, 21, 24, 28, 31, 34, 35, 39, 41, 44, 47, 48, 50,
  53, 55, 57, 60, 62, 65, 67, 69, 73, 75, 78, 80, 81, 84, 85, 87, 88, 90, 91,
  92, 93, 94, 95, 96, 97, 98, 99, 99, 100, 100, 100, 100
];
const totals = [
  0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52
];
export const totalToPercentile = totals.reduce(
  (acc: Record<string, number>, val, index) => {
    acc[val.toString()] = tperce[index];
    return acc;
  },
  {}
);

// ruminations to percentile values
const rperce = [
  3, 6, 13, 19, 26, 31, 38, 44, 50, 58, 65, 75, 80, 86, 91, 96, 100
];
const ruminations = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
export const ruminationsToPercentile = ruminations.reduce(
  (acc: Record<string, number>, val, index) => {
    acc[val.toString()] = rperce[index];
    return acc;
  },
  {}
);

// magnifications to percentile values
const mperce = [14, 27, 42, 50, 63, 75, 79, 86, 90, 93, 97, 98, 100];
const magnifications = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const magnificationsToPercentile = magnifications.reduce(
  (acc: Record<string, number>, val, index) => {
    acc[val.toString()] = mperce[index];
    return acc;
  },
  {}
);

// helplessness to percentile values
const hperce = [
  6, 11, 16, 22, 29, 36, 42, 47, 50, 55, 60, 64, 70, 75, 79, 83, 87, 89, 92, 95,
  97, 98, 99, 100, 100
];
const helplessness = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24
];
export const helplessnessToPercentile = helplessness.reduce(
  (acc: Record<string, number>, val, index) => {
    acc[val.toString()] = hperce[index];
    return acc;
  },
  {}
);

const errorCode = Number.MIN_SAFE_INTEGER;
export const pcsPercentile = function (value: number, type: PcsCategory) {
  // PCS Total, Rumination, Magnification, Helplessness
  if (type == PcsCategory.TOTAL) {
    if (value < 0) return errorCode;
    if (value < 2) return 2;
    if (value >= 52) return 100;

    return totalToPercentile[value];
  } else if (type == PcsCategory.RUMINATION) {
    if (value < 0) return errorCode;
    if (value >= 16) return 100;

    return ruminationsToPercentile[value];
  } else if (type == PcsCategory.MAGNIFICATION) {
    if (value < 0) return errorCode;
    if (value >= 12) return 100;

    return magnificationsToPercentile[value];
  } else if (type == PcsCategory.HELPLESSNESS) {
    if (value < 0) return errorCode;
    if (value >= 23) return 100;

    return helplessnessToPercentile[value];
  }

  return errorCode;
};
