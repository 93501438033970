import {
  ExpirationPeriod,
  PatientSymptom,
  Symptom,
  SymptomSource,
  SymptomSources
} from 'documents';
import moment, { Moment } from 'moment';
import { clearTimezone } from 'utils/object';

export function getSymptomExpiredSources(
  symptomName: Symptom,
  symptom: PatientSymptom,
  inventoryExpirations?: ExpirationPeriod
): SymptomSource[] {
  const symptomSources = SymptomSources[symptomName];
  const expiredSources: SymptomSource[] = [];

  // TODO
  // this should be default 30 day expiration for all forms;
  // inventoryExpirations will never be defined from patient.inventoryExpirations;
  // !notExpirable is not current either; should be unique to each individual patient;
  if (!notExpirable.includes(symptomName)) {
    if (symptomSources.includes('Cgs')) {
      const days = inventoryExpirations?.cgs ?? 30;
      if (isExpired(symptom.date, days)) {
        expiredSources.push('Cgs');
      }
    }
    if (symptomSources.includes('Promis')) {
      // why is this labeled otherOutcomeMeasures ?????
      const days = inventoryExpirations?.otherOutcomeMeasures ?? 30;
      if (isExpired(symptom.date, days)) {
        expiredSources.push('Promis');
      }
    }
    if (symptomSources.includes('Dhi')) {
      const days = inventoryExpirations?.otherOutcomeMeasures ?? 30;
      if (isExpired(symptom.date, days)) {
        expiredSources.push('Dhi');
      }
    }
    if (symptomSources.includes('Pcs')) {
      const days = inventoryExpirations?.otherOutcomeMeasures ?? 30;
      if (isExpired(symptom.date, days)) {
        expiredSources.push('Pcs');
      }
    }
    if (symptomSources.includes('TreatmentPlanning')) {
      const days = inventoryExpirations?.treatmentPlanning ?? 30;
      if (isExpired(symptom.date, days)) {
        expiredSources.push('TreatmentPlanning');
      }
    }
  }

  return expiredSources;
}

export const isQolExpired = (date: Moment) =>
  date.clone().add(7, 'day').isBefore(moment());

const isExpired = (date: Moment, days: number) =>
  date.clone().add(days, 'day').isBefore(clearTimezone(moment()));

/*
  this should probably be attached to patient collection; because this is unique to the patient having symptoms that cannot be tested every 30 days. 
*/
export const notExpirable: Readonly<Symptom[]> = [
  Symptom.Neck,
  Symptom.ExertionTolerance
];
