import React from 'react';
import { getColorLegend, Legend } from 'constants/value-color';

import './ColorLegend.scss';

export interface LegendProps {
  data?: Legend[];
  className?: string;
}
const mockData = getColorLegend();

export const ColorLegend: React.FC<LegendProps> = ({ data = mockData }) => {
  return (
    <div className="legend-outer-container">
      <div className="legend-container">
        {data.map((item, index) => (
          <div className="item-container" key={index}>
            <span
              className="item-color"
              style={{ backgroundColor: item.color }}
            >
              color
            </span>
            <span className="item-label">{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
